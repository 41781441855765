.fieldAreaWrap {
    position: relative;

    .row {
        margin-bottom: rem(12);

        &:first-child:last-child {
            margin-bottom: 0;
        }
    }

    .fieldArea {
        position: relative;

        .infoMsg {
            padding-top: rem(8);
            font-size: rem(12);
            color: $c-primary;
            line-height: 1rem;
            z-index: 1;

            &.error {
                padding-top: rem(6);
                color: $c-orange;
                // 2022.03.23 로그인 에러메세지 표출 시 아이디 인증횟수 제한으로 인해 리스폰스메세지값에 개행문자가 추가됨
                white-space: pre-line;

                & + .infoMsg {
                    padding-top: rem(4);
                }
            }
        }

        &.error {
            .agreeCheckArea,
            .btnSelectModal {
                border-color: $c-orange !important;
            }
        }

        input[type='text'] {
            padding-right: 1.875rem;
            font-weight: 600;
        }
        input[type='password'] {
            padding-right: 4.375rem;
            font-weight: 600;
        }
        input {
            &:disabled {
                background: #fff !important;
            }
        }
        //input {
        //    &:disabled {
        //        background: #fff !important;
        //        color: #d3d3d3 !important;
        //        opacity: 1;
        //    }
        //}

        .btnArea {
            position: absolute;
            top: 15px;
            right: 0;

            .btnDelete {
                @include iconDelete;
                @include textHidden;
            }

            .btnHidePw {
                @include iconHidePw;
                @include textHidden;
            }

            .btnShowPw {
                @include iconShowPw;
                @include textHidden;
            }

            button,
            a {
                margin-left: 1.0625rem;
                vertical-align: middle;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .checkList {
            margin-top: 0.5rem;

            li {
                font-size: 0.75rem;
                color: #c4c4c4;
                line-height: 20px;

                .iconCheck {
                    @include iconCheck;
                    margin-top: -0.125rem;
                    vertical-align: middle;
                }

                &.done {
                    color: $c-primary;

                    .iconCheck {
                        @include iconCheck($color: '3906C3');
                    }
                }
            }
        }

        .checkLayer {
            position: absolute;
            top: calc(3.25rem - 1px);
            left: 0;
            width: 100%;
            background: #fff;
            z-index: 10;

            .layerCon {
                max-height: 154px;
                padding: 1.0313rem 1.25rem;
                border: 1px solid #e0e0e0;
                border-radius: 0.5rem;
                overflow-y: auto;

                .mailList {
                    li {
                        margin-top: 17px;
                        line-height: 1.0625rem;

                        &:first-child {
                            margin-top: 0;
                        }

                        button,
                        a {
                            display: block;
                            width: 100%;
                            font-size: 0.875rem;
                            text-align: left;
                            color: $c-gray;
                        }
                    }
                }
            }
        }
    }
}

.textField {
    width: 100%;
    padding: 1rem 0;
    border-bottom: $border-gray;
    font-size: 1rem;

    &:focus {
        border-color: #212121;
    }

    &::placeholder {
        font-weight: 400;
        opacity: 1;
        color: $c-c4;
    }

    &.error {
        border-color: $c-orange !important;
    }

    &.small {
        padding: rem(9) 0;
        font-size: rem(14);
    }
}

.idNumberArea {
    display: flex;
    align-items: center;
    border-bottom: $border-gray;

    &.error {
        border-bottom: 1px solid #fe6215;
    }
    &:focus-within:not(.error) {
        border-bottom: 1px solid #000000;
    }

    input[type='text'] {
        padding-right: 0 !important;
        border: none;
    }

    .textField {
        max-width: 50%;
        border: none;
    }

    .bul {
        flex-shrink: 0;
        width: 6px;
        height: 1px;
        margin: 0 8px;
        background: #212121;
        @include textHidden;
    }

    .num1 {
        width: 1rem;
        text-align: center;

        &::placeholder {
            position: relative;
            top: 1.4px;
            width: 15px;
            height: 15px;
            vertical-align: middle;
            background-image: radial-gradient(circle at center, $c-gray-04 40%, transparent 0);
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            -webkit-appearance: none; /* 네이티브 스타일링 제거 */
            appearance: none;
            border-radius: 100%; /* 모양을 원으로 만들어줌 */
            padding: 0; /* 내부 여백 제거 */
            border: none; /* 테두리 제거 */
            margin: 0; /* 외부 여백 제거 */
            box-sizing: border-box; /* 크기 계산 시 테두리와 내부 여백을 포함하여 계산함 */
        }
    }

    .dotsArea {
        display: flex;

        .dot {
            width: 8px;
            height: 8px;
            margin: 0 0.25rem;
            background: #292929;
            border-radius: 100%;
        }
    }

    @include numInput;
}

.authNumArea {
    position: relative;
    @include numInput;

    .textField {
        padding-right: 5.25rem;
    }

    .time {
        position: absolute;
        right: 5.25rem;
        top: 1.0625rem;
        font-size: rem(14);
        font-weight: 500;
        color: $c-orange;

        &.done {
            color: #d3d3d3;
        }
    }

    .btnSmall {
        position: absolute;
        right: 0;
        top: 0.625rem;
    }
}

// 동의 체크
.agreeCheckArea {
    overflow: hidden;

    .checkAll {
        position: relative;
        padding: 1rem 0;
        font-size: rem(16);
        font-weight: 600;
    }

    .agreeCheckList {
        li {
            position: relative;
            margin-top: 1rem;
            font-size: 0.875rem;
            color: $c-gray;

            &:first-child {
                margin-top: 0;
            }

            span {
                color: $c-primary;
                &.gray {
                    color: $c-gray !important;
                }
            }
            .btn_ar {
                @include textHidden;
                @include iconGoArrow($size: 0.9625rem, $color: 'A0A4AF');
                background-size: 7px;
            }
        }
    }

    .btn_ar {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        svg {
            polyline {
                stroke: $c-0;
            }
        }
    }

    &.expand {
        border-bottom: 1px solid #e0e0e0;

        .checkAll {
            .btn_ar {
                transform: rotate(180deg) translateY(50%);
            }
        }
    }

    &.collapse {
        & + .infoMsg {
            padding-top: 1.125rem;
            margin-bottom: -0.8125rem;
        }
    }
}

.mediumField {
    input {
        padding-top: rem(9) !important;
        padding-bottom: rem(9) !important;
        font-size: rem(14) !important;
        font-weight: 400 !important;

        &::placeholder {
            font-size: rem(14);
        }
    }

    .btnArea {
        top: 8px !important;
    }
}

// 검색 영역
.searchBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 0.625rem;
    background: #f0f1f4;
    border-radius: rem(4);

    .iconInput {
        display: flex;
        width: calc(100% - 3.75rem);

        input {
            width: 100%;
            background: transparent;
            padding-right: rem(10);
            font-size: rem(14);

            &::placeholder {
                color: #c4c4c4;
                font-size: 0.875rem;
            }
        }
    }

    .btnArea {
        display: flex;
        height: 1.063rem;
        margin-right: rem(10);

        .btnDelete {
            &::after {
                @include iconDelete;
            }
        }

        span {
            margin-left: 12px;
        }
    }
}
