// 라운드 예약 골프장 리스트
#bookingList {
    padding-bottom: 0px !important;

    & > .inner {
        min-height: calc(100% - 48px);
        padding: rem(24) $InnerPadding;
    }

    .tabType01 {
        z-index: 100;

        li {
            button {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.rezListWrap {
    .cardList {
        max-width: 450px;
        padding: 1rem 1.25rem;
        margin: 1.125rem auto 0;
        border-radius: 0.5rem;
        background: #fff;
        border: 1px solid #ebebeb;
        overflow: hidden;

        &:first-child {
            margin-top: 0;
        }

        .rezInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0.375rem;
            line-height: 1.125rem;

            .num {
                font-size: 0.75rem;
                color: #a0a0a0;
            }

            .dDay {
                font-size: 1rem;
                font-weight: 700;
                color: #fe6215;
            }
        }

        .roundInfo {
            border-top: 1px solid #ebebeb;
            border-bottom: 1px solid #ebebeb;
            padding: 1.25rem 0;
            text-align: center;

            .date {
                display: block;
                margin-bottom: 0.625rem;
                font-size: 0.875rem;
            }

            .tt {
                font-size: 1.375rem;
                font-weight: 700;
                line-height: 1.625rem;
            }

            .course {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.5rem;
                font-size: 0.875rem;
                color: $c-gray;

                span:not(:first-child) {
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 0.625rem;
                        margin: 0 0.5rem;
                        background: #d3d3d3;
                    }
                }
            }
        }

        .inviteInfo {
            padding: 1.25rem 0;
            border-bottom: 1px solid #ebebeb;

            h3 {
                margin-bottom: 1rem;
                font-size: 1rem;
                font-weight: 700;
            }
        }

        .totalInfo {
            padding: 1.25rem 0;

            dl {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 0.4375rem;
                font-size: 0.875rem;

                &:first-child {
                    margin-top: 0;
                }

                &.totalPrice {
                    dt {
                        font-size: 1rem;

                        .btnTooltip {
                            margin-top: -0.25rem;
                            vertical-align: middle;
                        }
                    }

                    dd {
                        font-size: 1.125rem;
                        font-weight: 700;
                    }
                }

                &.deadline {
                    color: $c-gray;
                }
            }

            .defaultTooltip {
                .tooltipContainer {
                    position: relative;
                    left: 1.25rem;
                }
            }
        }
    }

    .cardList02 {
        position: relative;
        background: #fff;
        padding-top: 10px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 10px;
            background: $c-gray-200;
        }

        &:first-child {
            padding-top: 0;

            &::before {
                display: none;
            }
        }

        .cardAnchor {
            position: absolute;
            top: -7.8125rem;
            height: 0.1px;
            width: 100%;
        }

        .innerSection {
            padding: rem(20) rem(24);
        }

        .ttWrap {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-bottom: rem(26);

            &.external-border {
                border-top: 1px solid #d7d9e2;
            }

            h3 {
                flex-shrink: 0;
                @include font-light02;
                font-weight: 700;
            }

            b {
                &.date {
                    @include font-light02;
                    color: $c-red;
                    text-align: right;
                }
            }

            .btnAr {
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -0.5rem;
                @include iconDropDown($color: '848588');
                @include textHidden;

                &.open {
                    transform: rotate(-180deg);
                }
            }
        }

        .rezInfoWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: rem(10);
            margin-bottom: rem(16);
            border-bottom: 4px solid $c-0;

            .rezInfo {
                display: flex;
                align-items: center;

                .num {
                    display: block;
                    font-size: rem(14);
                    color: $c-gray;
                    line-height: rem(18);
                }

                .statusIcon {
                    margin-right: rem(6);
                }
            }

            .dDay {
                font-size: rem(14);
                font-weight: 700;
                color: $c-orange;
                line-height: rem(18);
            }
        }

        .fieldInfo {
            &.border {
                padding-bottom: rem(16);
                border-bottom: 1px solid #000;
            }

            .linkCon {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }

            .drawerTagList {
                border-top: 1px solid rgba(215, 217, 226, 0.5);
                width: 100%;
                margin-top: rem(16);
                padding-top: rem(16);
                /*height: rem(72);*/

                // background: rgba(235, 230, 249, 0.5);
                // padding: rem(24) rem(0) rem(24) rem(24);
                /* 가로 스크롤 */
                // overflow: auto;
                // white-space: nowrap;
                // padding: rem(0) rem(24) rem(0) rem(24);

                .drawer-tag-title {
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: rem(10);
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                span {
                    margin-right: rem(6);
                    align-items: center;
                    padding: rem(5) rem(10);

                    // background: rgba(176, 155, 231, 0.3);
                    background: rgba(176, 155, 231, 0.1);

                    border-radius: rem(4);

                    font-size: rem(12);

                    color: $c-primary;
                    white-space: nowrap;
                    line-height: rem(30);
                    height: rem(24);
                }
            }

            .txtArea {
                width: calc(100% - 6.25rem);

                .tt {
                    font-size: rem(20);
                    font-weight: 700;
                    line-height: rem(24);
                    cursor: pointer;
                }

                .statusIcon {
                    display: inline-block;
                    margin-left: rem(4);
                    margin-top: rem(3);
                    vertical-align: top;
                }

                .roundInfo {
                    margin-top: rem(13);
                    @include font-light02;
                    cursor: pointer;

                    p {
                        margin-top: rem(3);

                        &:first-child {
                            margin-top: 0;
                        }

                        &.list {
                            display: flex;

                            span {
                                position: relative;
                                display: block;
                                padding-left: rem(9);
                                margin-left: rem(8);

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    width: 1px;
                                    height: rem(10);
                                    transform: translateY(-50%);
                                    background: $c-gray-05;
                                }

                                &:first-child {
                                    padding-left: 0;
                                    margin-left: 0;

                                    &:before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .thumbArea {
                flex-shrink: 0;
                width: rem(92);
                height: rem(74);
                border-radius: rem(8);
                overflow: hidden;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .btnArea {
                margin-top: rem(20);
            }
        }

        .inviteInfo {
            margin-top: rem(20);

            .avatarWrap {
                .listCon {
                    &.fixedWidth {
                        justify-content: flex-start;

                        li {
                            flex: unset;
                            width: calc((100% - 3rem) / 4);
                            margin-left: rem(16);
                        }
                    }

                    li {
                        .Avatar {
                            .name {
                                width: calc(100% + 1rem);
                                margin-left: -0.5rem;
                            }
                        }
                    }
                }
            }
        }

        .rezDetailWrap {
            position: relative;
            margin-top: rem(20);
            border-top: 1px solid $c-0;
            border-bottom: 1px solid $c-0;

            .btnDetail {
                position: relative;
                display: block;
                width: 100%;
                padding: rem(18) 0;
                @include font-light02;
                font-weight: 700;
                text-align: left;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -0.5rem;
                    @include iconDropDown($color: '000');
                    @include textHidden;
                }
            }

            &.collapse {
                .btnDetail {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        .rezDetailCon {
            .innerSection {
                padding-top: 0;
            }

            .rezDetail {
                border-top: $border-gray-opacity;
                padding: rem(12) 0;

                h4 {
                    margin-bottom: rem(14);
                    @include font-light02;
                    font-weight: 600;
                }

                .infoType06 {
                    margin-top: rem(0);

                    .discountTxt {
                        color: #ff4d6f;
                        font-size: 14px;
                        font-weight: 700;
                    }

                    /*margin-bottom: rem(12);*/
                    .greenFee-include {
                        font-weight: 600;
                        font-size: 14px;
                        color: $c-primary;
                    }

                    dl {
                        margin-top: rem(12);
                    }
                }

                .innerCondition {
                    dl {
                        margin-top: rem(0);
                    }
                }
            }
        }

        .cancelPeriodArea {
            padding-top: rem(14);
            border-top: 1px solid rgba(215, 217, 226, 0.5);

            .ttWrap {
                margin-bottom: 0;
                align-items: center;

                h3 {
                    padding-right: rem(10);
                    display: flex;
                    align-items: center;
                }

                &.external-booking {
                    border-bottom: 1px solid #000;
                    padding-bottom: rem(12);

                    b {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
                .cancel-period-date {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    &.c-primary {
                        color: $c-primary;
                    }

                    &.c-red {
                        color: $c-red;
                    }
                }
            }

            .InfoFlexCon {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: rem(14);
                background: #f6f7f8;
                border-radius: rem(8);
                padding: rem(12) rem(14);
                overflow: hidden;

                p {
                    margin-right: rem(16);
                    font-size: rem(12);
                    line-height: rem(16);
                    color: $c-gray;
                    word-break: keep-all;
                }

                button {
                    height: rem(24);
                    flex-shrink: 0;
                    padding: 0 rem(8);
                    border: $border-gray;
                    background: $c-f;
                    font-size: rem(10);
                    line-height: rem(12);
                    border-radius: rem(4);
                }
            }
        }

        .defaultTooltip {
            .tooltipContainer {
                position: relative;
                left: 1.25rem;
                max-width: rem(260);
            }
        }

        .btnArea {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: rem(30);
            gap: rem(8);

            button {
                padding: 0;
                flex-grow: 1;
            }
        }
    }

    .loadingArea {
        height: 70vh;
    }

    .lineSection {
        margin-top: rem(20);
    }

    .external-reservation {
        margin-top: rem(20);
        border-top: 1px solid #000;
        // border-bottom: 1px solid #000;
        // padding: rem(12) 0;
        padding-top: rem(12);

        .list {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            line-height: normal;

            dl {
                font-size: 14px;
                font-style: normal;
                display: flex;
                justify-content: space-between;
                width: 100%;

                dt {
                    font-weight: 700;
                }

                dd {
                    font-weight: 400;
                }
            }
        }

        /* 셀프 체크인 프로모션 적용 -지난 라운드 && 외부예약건 추가*/
        .self-checkIn-promtion-wrap {
            border-top: 1px solid rgba(215, 217, 226, 0.5);
            margin-top: rem(12);
            padding-top: rem(12);
            padding-bottom: rem(12);
            border-bottom: 1px solid $c-0;

            .self-checkIn-promotion-div {
                display: flex;
                justify-content: space-between;
                outline: none;

                h2 {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                }

                .discountTxt {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;

                    color: $c-red;
                }
            }
        }

        .promotion-apply-n {
            border-top: 1px solid #000;
            margin-top: rem(12);
        }
    }
}

// 스마트 라운드 > 라운드 예약 리스트
#smartRoundBookingList {
    padding-bottom: 0px !important;

    .titleArea {
        padding-top: rem(10);
        margin-bottom: 0;
        border: none;
    }

    .rezListWrap {
        width: calc(100% + 3rem);
        margin: 0 -1.5rem;
    }
}

// 금액 상세 툴팁
.pricePopover {
    .tooltipBox {
        .priceInfo {
            li {
                margin-top: 0.25rem;
                line-height: 0.875rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

// 라운드 예정 상세정보
.rezInfoBox {
    background: #f8f8f8;
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;

    .ttArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.625rem;
        margin-bottom: 0.75rem;
        border-bottom: 1px solid #ebebeb;
    }

    .tt {
        display: block;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.1875rem;
    }

    .detailList {
        dl {
            display: flex;
            align-items: center;
            margin-top: 0.25rem;
            font-size: 0.875rem;

            &:first-child {
                margin-top: 0;
            }

            dt {
                position: relative;
                flex-shrink: 0;
                width: 4.25rem;
                padding-left: 0.3125rem;
                color: $c-gray;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 0.125rem;
                    height: 0.125rem;
                    background: $c-gray;
                    border-radius: 100%;
                }
            }
        }
    }
}

.playerList {
    margin-top: 1rem;

    .tt {
        display: block;
        margin-bottom: 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.0625rem;
    }

    .Avatar {
        .name {
            font-size: 0.75rem;
            color: $c-gray;
        }
    }

    .avatarWrap {
        .listCon {
            justify-content: flex-start;

            li {
                flex: unset;
                width: calc((100% - 3rem) / 5);
                max-width: 3.4375rem;
                margin-left: 0.75rem;
            }
        }
    }
}

.heightFull {
    .MuiPaper-root {
        height: 100%;

        .landingContents {
            //height: 100%;
        }
    }
}

// 정산 / 결제
.infoFlexCon {
    * {
        font-size: 0.875rem;
        line-height: 1.0625rem;
        color: $c-gray;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.25rem;

    button {
        border-bottom: 1px solid $c-gray;
    }
}

// 예약 취소 Drawer
.bookingCancelDrawer {
    .drawerTitle {
        .ttBig {
            padding: rem(40) 0 rem(20) !important;
        }
    }

    .infoBox01 {
        margin-top: rem(20);
    }
}

.BookingPrice {
    div {
        .smart-round-booking-price {
            .badge-wrap {
                dt {
                    display: flex;
                    align-items: center;
                }
            }
            dl {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding-top: rem(12);
                padding-bottom: rem(12);

                dt,
                h3 {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                }

                dd {
                    font-size: 14px;
                    font-style: normal;
                    color: $c-primary;

                    &.total-payment {
                        font-weight: 800;
                    }

                    &.date {
                        font-weight: 700;
                    }

                    &.external-dd {
                        font-family: Pretendard;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        color: $c-0;
                    }
                }

                // span {
                //     margin-right: rem(30);
                // }

                .cancel-possible-date {
                    dd {
                        font-weight: 600;
                    }
                    &.true {
                        color: $c-red;
                    }

                    &.false {
                        color: $c-primary;
                    }
                }
            }
            .border-dashed-bottom {
                border-bottom: 1px solid rgba(215, 217, 226, 0.5);
            }
        }
    }
}
