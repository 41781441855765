@import '../../../assets/styles/scss/help';

.mypage-booking-card-info {
    position: relative;
    margin-top: rem(20);
    border-top: 1px solid $c-0;
    border-bottom: 1px solid $c-0;
    &.border-top {
        padding-top: rem(13);
        border-top: 1px solid rgba(215, 217, 226, 0.5);
    }

    .payment-collapse-content {
        border-top: 1px solid rgba(215, 217, 226, 0.5);
        margin-bottom: rem(12);

        /* 셀프 체크인 프로모션 적용 -지난 라운드 추가*/
        .self-checkIn-promtion-wrap {
            padding-top: rem(12);
            padding-bottom: rem(12);
            border-bottom: 1px solid rgba(215, 217, 226, 0.5);

            .self-checkIn-promotion-div {
                display: flex;
                justify-content: space-between;
                outline: none;

                h2 {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                }

                .discountTxt {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;

                    color: $c-red;
                }
            }
        }

        .border-dashed-bottom {
            border-bottom: 1px dashed rgba(215, 217, 226, 0.5);
            padding-bottom: rem(12);
        }
        .sub-title-area {
            display: flex;
            justify-content: space-between;
            margin-bottom: rem(10);
            margin-top: rem(12);
            align-items: center;

            .total-amount {
                font-weight: 700;
                font-size: 14px;

                &.font-600 {
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            h2 {
                font-family: Pretendard;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                &.green {
                    color: $c-primary;
                }

                span {
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: $c-gray;
                }
            }

            button {
                border-radius: 4px;
                border: 1px solid $c-gray-05;
                align-items: center;
                display: flex;
                padding: rem(8) rem(10);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;

                cursor: pointer;

                i {
                    display: inline-flex;
                    font-weight: 600;
                    font-size: rem(12);
                    align-items: center;

                    svg {
                        margin-right: rem(2);
                    }

                    span {
                        font-weight: 400;
                    }
                }
            }
        }

        dl {
            margin-top: rem(8);

            &.dl-sub-text {
                margin-top: 0;
                dd {
                    color: $c-gray;
                    font-size: rem(12);
                    font-style: normal;
                    font-weight: 400;
                    line-height: rem(16);
                    padding-top: rem(4);

                    // padding-left: rem(12);
                }
            }
            &.border-bottom {
                border-bottom: 1px solid rgba(215, 217, 226, 0.5);
            }
        }

        .payment-info {
            margin: rem(12) 0;
        }
    }

    dl {
        display: flex;
        justify-content: space-between;

        outline: none;
        -webkit-tap-highlight-color: transparent;

        &.border-dashed-bottom {
            border-bottom: 1px dashed rgba(215, 217, 226, 0.5);
            padding-bottom: rem(12);
        }

        &.border-bottom {
            padding-bottom: rem(12);
            &:last-child {
                &.black {
                    border-bottom: 1px solid $c-0;
                    margin-bottom: rem(12);
                }
                border-bottom: 1px solid rgba(215, 217, 226, 0.5);
            }
        }

        &.total-payment {
            margin-top: rem(14);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: $c-primary;
            padding-bottom: rem(12);

            &.cursor-pointer {
                cursor: pointer;
            }

            &.black {
                color: $c-0;
            }

            justify-content: space-between;
            display: flex;

            .btnDetail {
                // @include font-light02;
                font-weight: 700;
                cursor: pointer;

                &::after {
                    content: '';
                    position: relative;
                    right: 0;
                    margin-left: rem(8);

                    @include iconDropDown($color: '000');
                    // @include textHidden;
                }

                &.collapse {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        dt {
            color: $c-0;
            font-weight: 500;
            &.payment-weight-700 {
                font-weight: 700;
            }

            &.payment-weight-800 {
                font-weight: 800;
            }
            font-size: 0.875rem;
            line-height: 1.0625rem;
        }

        dd {
            display: inline-flex;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            text-align: right;

            &.c-primary {
                color: $c-primary;
            }

            &.total-payment {
                font-weight: 700;
            }

            &.total-amount {
                color: $c-primary;
            }

            &.payment-weight-700 {
                font-weight: 700;
            }

            &.payment-weight-800 {
                font-weight: 800;
            }

            ul {
                line-height: rem(20);
                li {
                    overflow: hidden; // 을 사용해 영역을 감출 것
                    text-overflow: ellipsis; // 로 ... 을 만들기
                    white-space: nowrap; // 아래줄로 내려가는 것을 막기위해
                    word-break: break-all;
                    width: rem(240);
                    line-height: 20px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
