@import '../../../assets/styles/scss/help';

.pt-20 {
    padding-top: rem(20) !important;
}

.pb-20 {
    padding-bottom: rem(20) !important;
}

#payment-booking-wrapper {
    padding-top: 3.5rem;
    position: relative;
    min-height: calc(var(--vh) * 100);

    .payment-booking-wrapper-inner {
        padding: 0 1.5rem;
    }

    .payment-booking-wrapper-content {
        padding-bottom: 0px !important;

        .payment-booking-info {
            padding-top: rem(20);

            & + .section {
                border-top: none !important;
            }

            .payment-booking-state {
                margin-bottom: rem(5);
            }

            .payment-booking-title-area {
                padding-bottom: rem(14);
                border-bottom: 4px solid $c-0;

                h2 {
                    font-size: rem(18);
                    font-weight: 600;
                    line-height: rem(21);
                }
            }

            .payment-booking-detail-list {
                padding: rem(14) 0;
                border-bottom: 1px solid $c-0;
            }
        }

        .payment-booking-info-inner-condition {
            padding: rem(20) 0;
            border-top: $border-gray-opacity;

            &:first-child {
                border-top: none;
            }

            h2 {
                font-size: rem(14);
                font-weight: 700;
                line-height: rem(16.71);
            }

            .payment-booking-info-inner-condition-list {
                padding-top: 0.5rem;
                list-style-type: none;
                font-weight: 400;
                font-size: rem(14);
                line-height: rem(17);
                color: $c-0;

                li {
                    position: relative;
                    padding-left: rem(10);
                    word-break: keep-all;

                    &::before {
                        content: '-';
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        .payment-booking-info-cancel-notice {
            padding-top: rem(4);
            font-size: rem(12);
            line-height: rem(16);
            font-weight: 400;
            color: rgba(119, 127, 138, 1);
        }

        .payment-booking-detail-inner-people-btn-wrapper {
            padding: rem(20) 0;
            border-bottom: $border-gray-opacity;

            h2 {
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(21);
            }

            .payment-booking-detail-inner-people-btn-list {
                display: flex;
                margin-top: 1rem;
                justify-content: space-between;

                button {
                    /* Auto layout */
                    display: flex;
                    flex: 1 1 auto;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    width: 6.25rem;
                    height: 2.375rem;

                    margin-left: 0.375rem;
                    background: $c-primary;
                    border-radius: rem(4);

                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.063rem;

                    text-align: center;
                    color: $c-gray-000;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                .payment-booking-deatil-inner-people-btn {
                    border: $border-gray;
                    background: #ffffff;
                    color: #000;

                    &.select {
                        background: $c-primary;
                        color: #ffffff;
                        transition: 'background-color 0.3s ease';
                        border: none;
                    }
                }
            }
        }

        /* 결제 정보  */
        .payment-booking-pay-info-wrapper {
            display: flex;
            flex-direction: column;

            .payment-booking-pay-info-container {
                padding: rem(14) 0 rem(14) 0;

                &.dash {
                    border-bottom: $border-gray-dashed;
                }

                &.line {
                    border-bottom: $border-gray-opacity;
                }

                h2 {
                    margin-right: 5px;
                    font-size: rem(14);
                    font-weight: 700;
                    line-height: rem(16.71);
                    padding-bottom: rem(14);
                }

                .payment-booking-pay-info {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    font-size: rem(14);
                    line-height: rem(16.71);
                    margin-bottom: 10px;

                    .payment-booking-pay-info-amount {
                        color: $c-primary;
                    }

                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                }
            }

            .payment-booking-pay-info + .payment-booking-pay-info-sub {
                margin-bottom: rem(8);
            }
            .payment-booking-pay-info + .payment-booking-pay-info {
                margin-bottom: rem(8);
            }
            .payment-booking-pay-info + .payment-booking-pay-notice {
                margin-top: rem(14);
            }

            .payment-booking-pay-info-sub {
                display: flex;
                color: rgba(119, 127, 138, 1);
                font-size: rem(12);
                line-height: rem(16);
                margin-left: rem(13);
                margin-top: rem(-4);
            }

            .payment-booking-pay-notice {
                color: rgba(119, 127, 138, 1);
                font-size: rem(12);
                line-height: rem(16);

                &.text-gray {
                    display: inline-block;
                    margin-top: rem(4);
                    margin-bottom: rem(8);
                }

                &.bg-gray {
                    padding: 14px;
                    border-radius: rem(8);
                    background: #f6f7f8;
                }
            }
        }

        /* 결제 방식 && 결제 수단 */
        .payment-booking-pay-method-info-wrapper {
            border-top: $border-gray-opacity;
            display: flex;
            flex-direction: column;

            .payment-booking-pay-method-info-container {
                padding: rem(20) 0 rem(20) 0;

                &.dash {
                    border-bottom: $border-gray-dashed;
                }

                &.line {
                    border-bottom: $border-gray-opacity;
                }

                h2 {
                    margin-right: 5px;
                    font-size: rem(14);
                    font-weight: 700;
                    line-height: rem(16.71);
                    padding-bottom: rem(14);
                }

                .payment-booking-pay-method-info {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    font-size: rem(14);
                    line-height: rem(16.71);

                    &.label {
                        font-weight: 700;
                    }

                    &.content {
                        font-weight: 400;
                    }
                }
            }
        }

        .payment-booking-user-info-wrapper {
            padding: rem(20) 0;
            border-top: $border-gray-opacity;
            display: flex;
            flex-direction: column;

            h2 {
                margin-right: 5px;
                font-size: rem(14);
                font-weight: 700;
                line-height: rem(16.71);
                align-self: flex-start;
                padding-bottom: rem(14);
            }

            .payment-booking-user-info {
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(16.71);

                &:last-child {
                    padding-top: rem(8);
                }
            }
        }

        .payment-booking-info-wrapper {
            padding: rem(20);
            // border-top: $border-gray-opacity;
            display: flex;
            align-items: center;

            h2 {
                flex: 0 0 auto;
                margin-right: 5px;
            }

            .payment-booking-info-title {
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(16.71);

                &.amount {
                    font-weight: 700;
                    color: $c-primary;
                }
            }

            .payment-booking-info-content {
                font-weight: 400;
                font-size: rem(14);
                line-height: rem(16.71);
                flex: 1 1 auto;
                text-align: right;

                &.amount {
                    font-weight: 800;
                    color: $c-primary;
                }
                &.bold {
                    font-weight: 700;
                }
            }

            .payment-booking-cancel {
                font-weight: 700;
                font-size: rem(14);
                line-height: rem(16.71);
                flex: 1 1 auto;
                text-align: right;

                &.true {
                    color: $c-primary;
                }

                &.false {
                    color: $c-red;
                }
            }
        }

        .payment-booking-cancel-notice {
            font-size: rem(12);
            line-height: rem(16);
            color: $c-red;
        }
    }

    /* 하단 버튼 */
    .payment-booking-btn-area {
        position: sticky;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: rem(30) rem(24) rem(20) rem(24);
        align-items: flex-start;
        background: #fff;
    }

    .payment-booking-legal-notice {
        @include font-light02;
        color: $c-gray;
        background-color: #f2f4f6;
        padding: rem(20);
        margin-top: rem(20);
        h2 {
            font-weight: 600;
        }

        p {
            font-weight: 400;
            margin-top: rem(10);
        }
    }

    .payment-booking-agree-wrapper {
        border-top: $border-gray-opacity;
        font-size: rem(16);
        color: $c-0;

        ul > li {
            margin-top: 1.375rem;
        }

        /* 이용동의 주의사항 */
        .payment-booking-caution-text {
            border-top: $border-gray-opacity;
            margin-top: rem(14);
            font-size: rem(12);
            line-height: rem(20);
            color: rgba(119, 127, 138, 1);
            padding-top: rem(10);
        }
    }

    // 동의 체크
    .agree-check-area {
        overflow: hidden;

        .check-all {
            position: relative;
            padding: 1rem 0;
            font-size: rem(16);
            font-weight: 600;
        }

        .agree-check-list {
            li {
                position: relative;
                margin-top: 1rem;
                font-size: 0.875rem;
                color: $c-gray;

                &:first-child {
                    margin-top: 0;
                }

                span {
                    color: $c-primary;
                    &.gray {
                        color: $c-gray !important;
                    }
                }

                .btn-ar {
                    @include textHidden;
                    @include iconGoArrow($size: 0.9625rem, $color: 'A0A4AF');
                    background-size: 7px;
                }
            }
        }

        .btn-ar {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            svg {
                polyline {
                    stroke: $c-0;
                }
            }
        }

        &.collapse {
            & + .infoMsg {
                padding-top: 1.125rem;
                margin-bottom: -0.8125rem;
            }
        }

        &.agree-check-list-payment-booking {
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(215, 217, 226, 0.5);
        }
    }
}
