@import '../../assets/styles/scss/help';

.membershipIndex {
    position: relative;
    width: 100%;
    height: 100%;
    //background: url("../../assets/images/membership_bg.jpg");
    background-size: cover;
    .inner {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 5rem;
        padding-bottom: 5rem;
        z-index: 100;

        & h1 {
            padding-top: 20%;
            text-align: center;
            color: #fff;
            span {
                display: block;
                font-size: rem(16);
                font-weight: 400;
                line-height: rem(19);
                text-shadow: 2px 2px 4px rgba(33, 33, 33, 0.5);
            }
            .logo {
                display: block;
                font-family: 'Lexend';
                font-size: rem(46);
                font-weight: 700;
                line-height: rem(58);
                text-shadow: 2px 2px 4px rgba(33, 33, 33, 0.5);
            }
        }
        .btnWrap {
            text-align: center;
            .btnArea {
                button,
                a {
                    max-width: 500px;
                    margin: rem(12) auto 0;
                }
                & > button:first-of-type,
                & > a:first-of-type {
                    margin-top: 0;
                }
                .line {
                    display: none;
                    height: 1px;
                    background: #fff;
                    margin: 24px 0;
                    opacity: 0.2;
                }
            }
            &.btnWrapIphone {
                .btnArea {
                    .line {
                        display: block;
                    }
                }
            }
            .textLinkArea {
                margin-top: 12px;
                font-size: 0.875rem;
                color: #fff;
                span {
                    opacity: 0.67;
                }
                a {
                    margin-left: 8px;
                }
            }
        }
    }

    .gateSlider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .swiper,
        .swiper-wrapper {
            height: 100%;
        }
        .imgArea {
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            background-color: #fff;
        }

        .fadeIn {
            .imgArea {
                transform: scale(1.2);
            }
        }
        .fadeOut {
            .imgArea {
                transform: scale(1);
            }
        }
        .slideRight {
            .imgArea {
                width: calc(100% + 60px);
                transform: translateX(-60px);
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            z-index: 1;
        }

        .swiper-slide-active {
            .imgArea {
                animation-duration: 5s;
                animation-timing-function: ease-in;
                animation-fill-mode: forwards;
            }
            &.fadeIn {
                .imgArea {
                    animation-name: scaleFadeIn;
                }
            }
            &.fadeOut {
                .imgArea {
                    animation-name: scaleFadeOut;
                }
            }
            &.slideRight {
                .imgArea {
                    animation-name: slideRight;
                }
            }
        }

        @keyframes scaleFadeIn {
            0% {
                transform: scale(1);
            }

            100% {
                transform: scale(1.2);
            }
        }

        @keyframes scaleFadeOut {
            0% {
                transform: scale(1.2);
            }

            100% {
                transform: scale(1);
            }
        }

        @keyframes slideRight {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-60px);
            }
        }
    }

    .emailUtils {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: rem(20);
        li {
            position: relative;
            margin-left: rem(10);
            padding-left: rem(11);
            line-height: rem(15);
            &:first-child {
                margin-left: 0;
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                display: block;
                width: 1px;
                height: 11px;
                background: $c-f;
            }
            a {
                font-size: rem(13);
                color: $c-f;
            }
        }
    }
}

#membership {
    .fieldAreaWrap {
        padding-bottom: 4.875rem;
    }
}

#login {
    width: 100%;
    max-width: 600px;
    height: 100%;
    margin: 0 auto;
    .logoArea {
        padding: 0.75rem 0 2.5rem;
        text-align: center;
    }
    .loginArea {
        .autoLoginCheck {
            label {
                display: flex;
                &::before {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 0.6875rem;
                    background: #e0e0e0;
                    margin: 0 0.625rem;
                }
                align-items: center;
                width: fit-content;
                font-size: 0.9375rem;
            }
        }
        .btnLogin {
            height: 3.125rem;
            margin-top: rem(72);
        }
        .loginUtil {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: rem(20);
            font-size: 0.8125rem;
            li {
                &:not(:first-child) {
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 0.6875rem;
                        background: #e0e0e0;
                        margin: 0 0.625rem;
                    }
                }
                a {
                    color: $c-gray;
                    &.memberjoin {
                        color: $c-0;
                    }
                }
            }
        }
    }
    .otherLogin {
        margin-top: 2.25rem;

        .bul {
            position: relative;
            margin-bottom: rem(24);
            font-size: rem(14);
            line-height: rem(17);
            font-weight: 500;
            text-align: center;
            //color: #a0a0a0;
            color: $c-0;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: -$InnerPadding;
                width: 100vw;
                height: 1px;
                transform: translateY(-50%);
                background: $c-gray-300;
            }
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: -$InnerPadding;
                width: 100vw;
                height: 1px;
                transform: translateY(-50%);
                background: $c-gray-300;
            }
            span {
                position: relative;
                padding: 0 10px;
                background: #fff;
                z-index: 10;
            }
        }
        .btnWrap {
            button {
                margin-top: 0.75rem;
                &:first-of-type {
                    margin-top: 0;
                }
                //&.lastLogin {
                //    &::after {
                //        content: '최근 로그인';
                //        position: absolute;
                //        top: -0.75rem;
                //        width: 5.875rem;
                //        padding: 0.625rem 0 0.875rem;
                //        margin-left: -0.25rem;
                //        background: url('data:image/svg+xml,%3Csvg width="94" height="39" viewBox="0 0 94 39" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cmask id="path-1-inside-1_49_1571" fill="white"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M17 0C7.61116 0 0 7.61116 0 17C0 25.9614 6.93393 33.3033 15.7293 33.9532L14 39L23.5455 34H77C86.3888 34 94 26.3888 94 17C94 7.61116 86.3888 0 77 0H17Z"/%3E%3C/mask%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M17 0C7.61116 0 0 7.61116 0 17C0 25.9614 6.93393 33.3033 15.7293 33.9532L14 39L23.5455 34H77C86.3888 34 94 26.3888 94 17C94 7.61116 86.3888 0 77 0H17Z" fill="white"/%3E%3Cpath d="M15.7293 33.9532L16.6753 34.2774L17.0954 33.0514L15.803 32.9559L15.7293 33.9532ZM14 39L13.054 38.6758L12.2402 41.0507L14.464 39.8858L14 39ZM23.5455 34V33H23.2994L23.0814 33.1142L23.5455 34ZM1 17C1 8.16344 8.16344 1 17 1V-1C7.05888 -1 -1 7.05887 -1 17H1ZM15.803 32.9559C7.526 32.3443 1 25.4338 1 17H-1C-1 26.489 6.34186 34.2623 15.6556 34.9505L15.803 32.9559ZM14.7833 33.6291L13.054 38.6758L14.946 39.3242L16.6753 34.2774L14.7833 33.6291ZM14.464 39.8858L24.0095 34.8858L23.0814 33.1142L13.536 38.1142L14.464 39.8858ZM77 33H23.5455V35H77V33ZM93 17C93 25.8366 85.8366 33 77 33V35C86.9411 35 95 26.9411 95 17H93ZM77 1C85.8366 1 93 8.16344 93 17H95C95 7.05887 86.9411 -1 77 -1V1ZM17 1H77V-1H17V1Z" fill="%23212121" mask="url(%23path-1-inside-1_49_1571)"/%3E%3C/svg%3E%0A')
                //            no-repeat 0 0;
                //        background-size: 100%;
                //        font-size: 0.8125rem;
                //        font-weight: 400;
                //        color: $c-21;
                //        line-height: 0.9375rem;
                //    }
                //}
            }
        }
        .btnWrap2 {
            display: flex;
            justify-content: center;

            button {
                width: 56px;
                height: 56px;
                margin-left: rem(18);
                border-radius: 100%;

                &:active {
                    background: transparent !important;
                }

                &:first-of-type {
                    margin-left: 0;
                }
                &.lastLogin {
                    &::after {
                        z-index: 100;
                        content: '최근 로그인';
                        position: absolute;
                        width: 66px;
                        height: 31px;
                        top: 75%;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: rem(12) rem(10);
                        background: url("data:image/svg+xml,%3Csvg width='66' height='31' viewBox='0 0 66 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='path-1-inside-1_45_9219' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M38.5 5L33 0L27.5 5H13C5.8203 5 0 10.8203 0 18C0 25.1797 5.8203 31 13 31H53C60.1797 31 66 25.1797 66 18C66 10.8203 60.1797 5 53 5H38.5Z'/%3E%3C/mask%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M38.5 5L33 0L27.5 5H13C5.8203 5 0 10.8203 0 18C0 25.1797 5.8203 31 13 31H53C60.1797 31 66 25.1797 66 18C66 10.8203 60.1797 5 53 5H38.5Z' fill='white'/%3E%3Cpath d='M33 0L33.6727 -0.73994L33 -1.35146L32.3273 -0.73994L33 0ZM38.5 5L37.8273 5.73994L38.1134 6H38.5V5ZM27.5 5V6H27.8866L28.1727 5.73994L27.5 5ZM32.3273 0.73994L37.8273 5.73994L39.1727 4.26006L33.6727 -0.73994L32.3273 0.73994ZM28.1727 5.73994L33.6727 0.73994L32.3273 -0.73994L26.8273 4.26006L28.1727 5.73994ZM13 6H27.5V4H13V6ZM1 18C1 11.3726 6.37258 6 13 6V4C5.26801 4 -1 10.268 -1 18H1ZM13 30C6.37258 30 1 24.6274 1 18H-1C-1 25.732 5.26801 32 13 32V30ZM53 30H13V32H53V30ZM65 18C65 24.6274 59.6274 30 53 30V32C60.732 32 67 25.732 67 18H65ZM53 6C59.6274 6 65 11.3726 65 18H67C67 10.268 60.732 4 53 4V6ZM38.5 6H53V4H38.5V6Z' fill='black' mask='url(%23path-1-inside-1_45_9219)'/%3E%3C/svg%3E%0A")
                            no-repeat 0 0;
                        background-size: 100%;
                        font-weight: 700;
                        font-style: normal;
                        font-size: rem(10);
                        color: $c-21;
                    }
                }
            }
        }
    }

    & > .inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

.resultIdInfoArea {
    margin-top: 1.75rem;

    .resultIdInfo {
        padding-bottom: 0.8125rem;
        border-bottom: 1px solid #ebebeb;
        font-style: normal;
        font-weight: 600;
        color: $c-0;
        font-size: rem(16);
        line-height: rem(23); // 피그마값과 매치하면 아이콘 잘림

        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .date {
        display: block;
        margin-top: rem(8);
        font-size: rem(12);
        line-height: rem(14);
        font-weight: 400;
        color: $c-gray;
    }
}

// SNS 아이콘
@mixin inlineStyleIcon {
    margin-right: 0.5rem;
    margin-top: -2px;
    border-radius: 100%;
    vertical-align: middle;
}

.iconKakao {
    @include iconKakao;
    background-color: #fee500;
    background-size: 70%;
    @include inlineStyleIcon;
}

.iconApple {
    @include iconApple($color: 'fff');
    background-color: $c-21;
    background-position: 42% 46%;
    background-size: 65%;
    margin-right: 0.5rem;
    margin-top: 0;
    border-radius: 100%;
    vertical-align: middle;
    //@include inlineStyleIcon;
}

.iconEmail {
    @include iconMail($color: 'fff');
    background-color: $c-gray;
    background-position: 42% 46%;
    background-size: 65%;
    margin-right: 0.5rem;
    margin-top: 0;
    border-radius: 100%;
    vertical-align: middle;
    //@include inlineStyleIcon;
}

.iconMail {
    @include iconMail($color: 'fff');
    background-color: $c-gray;
    background-position: 42% 46%;
    background-size: 65%;
    margin-top:0;
    @include inlineStyleIcon;
}

.iconPhone {
    @include iconPhone;
    background-color: #ebe6f9;
    background-size: 55%;
    @include inlineStyleIcon;
}
