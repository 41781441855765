@import '../../../assets/styles/scss/help';

// 예약 취소 페이지 풀 모달

.payment-booking-cancel-confirm {
    .layer-wrap {
        display: flex;
        flex-direction: column;
        height: 100%;

        .layer-header {
            &.fixed {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;

                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                flex-grow: 1;

                &.border {
                    border-bottom: 1px solid #ebebeb;
                }

                .layerHeader_title {
                    width: 100%;
                    font-size: rem(18);
                    font-weight: 700;
                    padding: 0 rem(50);
                    margin: 0;
                    opacity: 0;
                    transition: opacity 0.3s;
                    text-align: center;
                    word-break: keep-all;

                    &.visible {
                        opacity: 1;
                        transition: opacity 0.3s;
                    }
                }

                .btn-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 0 20px;
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    width: 50px !important;
                    height: 100% !important;
                    @include textHidden;
                    @include iconClose($size: 1.5rem);
                    background-size: rem(24);
                }
            }

            display: flex;
            justify-content: flex-end;
            height: rem(20);
            margin: rem(16) 0;
            flex-shrink: 0;

            .btn-close {
                width: 50px !important;
                height: 100% !important;
                margin-right: -20px;
                @include textHidden;
                @include iconClose($size: 1.5rem);
                background-size: 1.5rem;
            }
        }

        .inner {
            .payment-info-type {
                margin-top: rem(12);
                dl {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 0.75rem;

                    &:first-child {
                        margin-top: 0;
                    }

                    &.border-bottom {
                        &:last-child {
                            &.black {
                                border-bottom: 1px solid $c-0;
                            }
                            border-bottom: 1px solid rgba(215, 217, 226, 0.5);
                            padding-bottom: rem(12);
                        }
                    }

                    dt {
                        font-style: normal;
                        line-height: normal;
                        color: $c-0;
                        font-size: rem(14);
                        font-weight: 500;
                    }

                    dd {
                        color: $c-0;
                        font-style: normal;
                        font-weight: 400;
                        text-align: right;
                        font-size: rem(14);

                        &.primary {
                            color: $c-primary;
                        }

                        &.total-payment {
                            font-weight: 800;
                            color: $c-primary;
                            font-size: rem(14);
                        }
                    }
                }
            }

            .cancel-possible {
                margin-top: rem(12);
                display: flex;
                justify-content: space-between;

                h3 {
                    padding-right: rem(10);
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 700;
                    color: $c-0;

                    span {
                        margin-left: rem(4);
                    }
                }
                b {
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    &.c-primary {
                        color: $c-primary;
                    }

                    &.c-red {
                        color: $c-red;
                    }
                }
            }

            // Information Box Style
            .cancel-info {
                align-items: center;
                background: #f6f7f8;
                border-radius: 0.5rem;
                display: flex;
                justify-content: space-between;
                margin-top: 0.875rem;
                overflow: hidden;
                padding: 0.75rem 0.875rem;

                & + .InfoFlexCon {
                    margin-top: 1.25rem;
                    background: rgba(240, 241, 244, 0.6);
                }

                p {
                    font-size: rem(12);
                    line-height: rem(16);
                    word-break: keep-all;
                    color: $c-gray;

                    &.textIndent {
                        display: flex;
                        &::before {
                            content: '*';
                            padding-right: 2px;
                        }
                    }
                }

                button {
                    background: $c-f;
                    border: 1px solid $c-gray-05;
                    border-radius: 0.25rem;
                    flex-shrink: 0;
                    font-size: 0.625rem;
                    height: 1.5rem;
                    line-height: 0.75rem;
                    padding: 0 0.5rem;
                }
            }
        }
        // 하단 고정 버튼
        .btn-bottom {
            position: sticky;
            width: 100%;
            left: 0;
            bottom: 0;
            padding: rem(30) 0;
            background: #fff;
            z-index: 10;

            .inner {
                display: flex;

                button {
                    flex: 1 1 auto;
                    margin-left: rem(8);

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            &.btn-full-width {
                .inner {
                    display: block;

                    button {
                        margin-left: 0;
                        margin-top: 0.75rem;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .modalBtnArea {
        margin-top: rem(16);
    }

    .payment-booking-complete-info {
        margin-top: rem(12);
        dl {
            display: flex;

            .payment-booking-init {
                justify-content: initial !important;
            }
            justify-content: space-between;

            margin-top: rem(12);

            &:first-child {
                margin-top: 0;
            }

            &.border-dashed-bottom {
                border-bottom: 1px dashed rgba(215, 217, 226, 0.5);
                padding-bottom: rem(12);
            }

            &.border-bottom {
                padding-bottom: rem(12);
                &:last-child {
                    &.black {
                        border-bottom: 1px solid $c-0;
                        margin-bottom: rem(12);
                    }
                    border-bottom: 1px solid rgba(215, 217, 226, 0.5);
                }
            }

            &.total-payment {
                margin-top: rem(14);
                font-size: 14px;
                font-family: Pretendard;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $c-primary;
                padding-bottom: rem(12);

                &.black {
                    color: $c-0;
                }
            }

            dt {
                &.payment-complete-700 {
                    font-weight: 700;
                }

                &.payment-complete-800 {
                    font-weight: 800;
                }
                font-weight: 500;
                @include font-light02;
            }

            dd {
                @include font-light02;
                text-align: right;

                &.payment-complete-700 {
                    font-weight: 700;
                }

                &.payment-complete-800 {
                    font-weight: 800;
                }

                ul {
                    line-height: rem(20);
                    li {
                        overflow: hidden; // 을 사용해 영역을 감출 것
                        text-overflow: ellipsis; // 로 ... 을 만들기
                        white-space: nowrap; // 아래줄로 내려가는 것을 막기위해
                        word-break: break-all;
                        width: rem(240);
                        line-height: 20px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
