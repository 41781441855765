// react-bottom-drawer
.defaultDrawer {
    [class*='MuiDrawer-paper'] {
        border-radius: 1rem 1rem 0px 0px;
    }

    .drawer-content {
        display: flex;
        flex-direction: column;
        // TODO : 2023.04.18 디자인팀과 height or max-height 재확인
        max-height: calc(100vh - 3.75rem);
        padding: rem(30) rem(24) rem(16);
    }

    .drawerTitle {
        flex-shrink: 0;
        position: relative;
        margin-bottom: rem(24);

        .tt {
            font-size: rem(18);
            font-weight: 700;
        }

        // 내 예약정보 불러오기 Title
        .ttReserv {
            margin-top: rem(44);
            font-size: rem(18);
            font-weight: 600;
            line-height: rem(21);
        }

        .ttBig {
            padding: 1.25rem 0 0.25rem;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
        }

        .btnClose {
            position: absolute;
            top: 0;
            right: 0;
            width: rem(24);
            height: rem(24);

            &.large {
                top: -0.25rem;
                right: -0.25rem;

                svg {
                    width: 1rem;
                }
            }
        }
    }

    .drawerContents {
        width: calc(100% + 3rem);
        height: 100%;
        padding: 0 $InnerPadding 1.75rem;
        margin-bottom: rem(-16);
        margin-left: -$InnerPadding;
        overflow-y: auto;

        .selectList {
            li {
                margin-top: 1.25rem;
                font-size: 1rem;
                line-height: 2.5rem;

                &:first-child {
                    margin-top: 0;
                }

                button,
                label {
                    display: block;
                    width: 100%;
                    font-size: inherit;
                    line-height: inherit;
                    text-align: left;
                    cursor: pointer;

                    &.on {
                        color: $c-primary;
                    }
                }
            }
        }

        .teeList {
            li {
                .tee {
                    display: inline-block;
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-top: -0.25rem;
                    margin-right: 0.875rem;
                    border-radius: 100%;
                    vertical-align: middle;

                    &.red {
                        background: #ff4747;
                    }

                    &.yellow {
                        background: #ffd467;
                    }

                    &.white {
                        background: #d9d9d9;
                    }

                    &.blue {
                        background: #357ce7;
                    }

                    &.black {
                        background: #4a4a4a;
                    }

                    &.green {
                        background: #3906c3;
                    }
                }

                span {
                    padding-left: 0.375rem;
                    font-size: 0.75rem;
                    color: #a9a9a9;
                    opacity: 0.7;
                }

                .on {
                    * {
                        color: $c-primary;
                    }

                    &::after {
                        @include iconCheck($size: 1.5625rem, $color: '3906C3');
                        vertical-align: middle;
                    }
                }
            }
        }

        .labelList {
            margin-top: 1.25rem;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .allChecked {
            padding-bottom: 1.25rem;
            border-bottom: 1px solid $c-gray-200;

            .MuiTypography-root {
                font-weight: 700;
            }
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .tabContents {
            .tooltipSection {
                margin-top: 2rem;
                word-break: keep-all;
            }
        }
    }

    // 내 예약정보 불러오기 Drawer
    .my-reserv {
        .drawerContents {
            padding: 0 $InnerPadding rem(20);
        }
    }

    .drawerBtnArea {
        position: relative;
        display: flex;
        margin-top: rem(16);
        padding-top: rem(16);

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: rem(-26);
            width: 100%;
            height: rem(42);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 21.78%, #ffffff 80.43%);
        }

        button {
            flex: 1 1 auto;
            margin-left: rem(8);

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

// 내 예약정보 불러오기 모달
.mainModal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 3rem);
    transform: translateX(-50%) translateY(-50%);
    border-radius: 1rem;
    background: #ffffff;
    outline: 0;
    overflow: hidden;
    max-width: 300px !important;

    .modalContents {
        position: relative;
        padding: rem(24);
        text-align: center;
        background: #f2ebfc !important;

        .btnClose {
            position: absolute;
            top: rem(20);
            right: rem(20);
            @include iconClose($size: 1.5rem);
            background-size: 100%;
            @include textHidden;
        }

        #modalTitle {
            margin-top: rem(30);
            font-weight: 700;
            font-size: rem(22) !important;
            line-height: rem(26);
            word-break: keep-all;
        }

        .getMyReservImg {
            margin: rem(31) 0;
            width: rem(160);
            height: rem(160);
        }

        .noticeCon {
            font-weight: 400;
            font-size: rem(14);
            line-height: rem(17);
            word-break: keep-all;
        }
    }

    .modalBtnArea {
        display: flex;
        justify-content: space-around;

        .modalBtn {
            padding: rem(10) 0;
            font-size: rem(12);
            line-height: rem(14);
            color: #3c414a;
        }
    }
}

.defaultModal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 3rem);
    max-width: 350px;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 1rem;
    outline: 0;
    overflow: hidden;

    #modalTitle {
        position: relative;
        margin-bottom: rem(10);
        font-size: rem(18);
        font-weight: 600;
        line-height: rem(21);
        color: $c-0;

        .btnClose {
            position: absolute;
            top: -1.125rem;
            right: -0.25rem;
            @include iconClose($size: 1.5rem);
            background-size: 100%;
            @include textHidden;

            &.invite {
                top: 0;
                right: 0;
            }
        }

        & + .buttonWrapper {
            margin-top: rem(20);
        }
    }

    .modalContents {
        padding: rem(30) rem(24);
        background: #fff;
        font-size: rem(14);
        line-height: rem(20);

        .sortModalTitle {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
        }

        .buttonWrapper {
            button {
                margin-top: rem(12);

                &:first-child {
                    margin-top: rem(0);
                }

                &.kakao {
                    height: rem(36);
                    @include font-light02;
                    font-weight: 400;

                    &::before {
                        margin-top: 0;
                    }
                }

                &.system {
                    height: rem(36);
                    @include font-light02;
                    font-weight: 400;
                    border: solid #3906c3 rem(1);
                    background-color: #fff;
                    color: #3906c3;

                    &::before {
                        margin-top: 0;
                    }
                }
            }
        }

        .kakaoInviteInfo {
            margin-top: rem(20);
            padding: rem(24) 0 0;
            border-top: $border-gray;

            .tit {
                margin-bottom: rem(10);
                line-height: rem(19);

                span {
                    display: block;
                    padding-bottom: rem(2);
                }
            }

            .deleteBtnArea {
                margin-top: rem(20);
            }
        }

        .avatarWrap {
            .listCon {
                justify-content: center;

                li {
                    margin-left: 0.875rem;
                }
            }
        }

        .inviteTitle {
            text-align: center;
            margin-bottom: rem(24);
        }

        p {
            & + form {
                margin-top: rem(14);
            }
        }
    }

    .modalBtnArea {
        display: flex;
        margin-top: -1px;

        button,
        a {
            display: block;
            width: 100%;
            font-size: rem(16);
            font-weight: 600;
            color: #fff;
            background: $c-gray-03;
            line-height: rem(52);
            border-radius: 0px !important;
            @include ripples($c-gray-03);

            &:last-child {
                background: $c-navy;
                @include ripples-light($c-navy, 5%);
            }
        }
    }
}

.radio-sortModal-wrap {
    margin-top: rem(10);

    .radio-sortModal-wrap-labelList {
        margin-bottom: rem(14);

        &:last-child {
            margin-bottom: 0;
        }

        .MuiFormControlLabel-label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            align-items: center;
            text-align: center;
        }
    }
}

.fullPageModal {
    // z-index: 1400 !important;

    .layerPop {
        display: flex;
        flex-direction: column;
        height: 100%;

        .layerHeader {
            display: flex;
            justify-content: flex-end;
            height: 4rem;
            flex-shrink: 0;

            .btnClose {
                width: 50px !important;
                height: 100% !important;
                margin-right: -20px;
                @include textHidden;
                @include iconClose($size: 1.5rem);
                background-size: 1.5rem;
            }
        }

        .layerHeader2 {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;

            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            flex-grow: 1;

            &.self-check-in {
                background-color: #f0f1f4;
            }

            &.border {
                border-bottom: 1px solid #ebebeb;
            }

            .layerHeader_title {
                width: 100%;
                font-size: rem(18);
                font-weight: 700;
                padding: 0 rem(50);
                margin: 0;
                opacity: 0;
                transition: opacity 0.3s;
                text-align: center;
                word-break: keep-all;

                &.visible {
                    opacity: 1;
                    transition: opacity 0.3s;
                }
            }

            .btnClose {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 20px;
                border: none;
                color: #fff;
                cursor: pointer;
                width: 50px !important;
                height: 100% !important;
                @include textHidden;
                @include iconClose($size: 1.5rem);
                background-size: rem(24);
            }
        }

        & > .inner,
        .layerContents {
            height: calc(100% - 4rem);
            overflow-y: auto;
        }

        .layerContents2 {
            position: relative;
            margin-top: 4rem;
            padding-bottom: rem(16);
            overflow-y: auto;
        }

        .layerContents3 {
            position: relative;
            min-height: calc(100% - 4rem);
            margin-top: 4rem;
            padding-bottom: rem(16);
            overflow-y: auto;

            .inner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 100%;
                padding: 0 $InnerPadding;
            }
        }
    }

    .modalBtnArea {
        margin-top: rem(16);
    }
}

// 기본 alert 모달
.defaultAlert {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 3rem);
    max-width: 350px;
    // background: #fff;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 1rem;
    outline: 0;
    overflow: hidden;

    #alertConWrap {
        background: #fff;
        padding: rem(30) rem(24);
    }

    #alertTitle {
        margin-bottom: rem(20);
        font-size: rem(18);
        font-weight: 600;
        color: $c-0;
    }

    .alertContents {
        font-size: rem(14);
        line-height: rem(20);
        white-space: pre-line;

        .companyList {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;
                font-size: rem(14);
                line-height: rem(24);
            }
        }

        /* PartnerInviteModal */
        .subContents {
            font-size: rem(14);
            line-height: rem(20);
            margin-bottom: rem(14);

            dd {
                padding-left: rem(10);
                padding-bottom: rem(6);
                font-weight: 600;
                color: $c-primary;
            }
        }

        &:first-child {
            text-align: center;
        }
    }

    .alertBtnArea {
        display: flex;
        align-items: center;
        overflow: hidden;

        button,
        a {
            flex: 1;
            line-height: rem(52);
            font-size: rem(16);
            font-weight: 600;
            background: $c-gray-03;
            color: $c-f;
            text-align: center;
            overflow: hidden;
            @include ripples($c-gray-03);

            &:last-child {
                background: $c-navy;
                @include ripples-light($c-navy, 5%);
            }
        }
    }
}

.basicAlert {
    padding: 0.875rem;
    bottom: inherit !important;

    .alertBox {
        position: relative;
        padding: 1.25rem 1.25rem 1rem 1.25rem;
        background: #fff;
        border: 1px solid $c-gray-200;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;
        outline: none;

        .btnClose {
            position: absolute;
            top: 1rem;
            right: 1rem;
            font-size: 0;
            line-height: 0;
        }

        .tit {
            display: block;
            padding-bottom: 0.5rem;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1875rem;
        }

        p {
            color: $c-gray;
            font-size: 0.875rem;
            line-height: 1.25rem;
            word-break: keep-all;
        }
    }
}

// 툴팁
.defaultTooltip {
    position: relative;
    max-width: 100%;
    z-index: 20;
    font-weight: 400;

    .tooltipBox {
        position: relative;
        background: #fff;
        padding: 1rem;
        border: 1px solid #212121;
        border-radius: 0.5rem;
        font-size: 0.75rem;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.23);

        .ttWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: rem(8);

            .btnClose {
                margin-top: -0.125rem;
                @include iconClose($size: 0.75rem);
                background-size: 0.75rem;
                @include textHidden;
            }
        }

        .tt {
            @include font-light02;
            font-weight: 600;
        }

        p {
            margin-top: 0.75rem;
            color: #848588;
            line-height: 0.9375rem;
        }

        .txtCon {
            font-size: rem(12);
            line-height: rem(16);
            word-break: keep-all;
        }

        &.flexCon {
            display: flex;
            justify-content: space-between;

            .txtCon {
                margin-right: rem(12);
            }

            .btnClose {
                margin-right: -0.25rem;
                margin-top: 1px;
                flex-shrink: 0;
                @include iconClose($size: 12px);
                @include textHidden;
            }
        }
    }

    .tooltipArrow {
        position: relative;
        width: 9px;
        height: 8px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 0;
            height: 0;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
        }
    }

    &[data-popper-placement*='bottom'] {
        .tooltipArrow {
            top: -8px;

            &::before {
                bottom: 0;
                border-bottom: 8px solid #212121;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }

            &::after {
                bottom: -2px;
                border-bottom: 8px solid #fff;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
        }
    }

    &[data-popper-placement*='top'] {
        .tooltipArrow {
            bottom: -8px;

            &::before {
                top: 0;
                border-top: 8px solid #212121;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }

            &::after {
                top: -2px;
                border-top: 8px solid #fff;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
        }
    }
}

.basicTooltip {
    position: relative;
    padding: rem(7) rem(8);
    background: $c-orange;
    font-size: rem(10);
    font-weight: 700;
    line-height: rem(12);
    color: $c-f;
    border-radius: 50px;

    &.right {
        &::after {
            content: '';
            position: absolute;
            right: -4px;
            top: 50%;
            transform: translateY(-50%);
            width: 0px;
            height: 0px;
            border-top: 6px solid transparent;
            border-left: 8px solid $c-orange;
            border-right: 8px solid none;
            border-bottom: 6px solid transparent;
        }
    }

    &.left {
        &::after {
            content: '';
            position: absolute;
            left: -4px;
            top: 50%;
            transform: translateY(-50%);
            width: 0px;
            height: 0px;
            border-top: 6px solid transparent;
            border-left: 8px solid none;
            border-right: 8px solid $c-orange;
            border-bottom: 6px solid transparent;
        }
    }
}

// 필드예약 > Filter Drawer
.basicDrawer {
    // overflow: hidden;

    [class*='MuiDrawer-paper'] {
        border-radius: 1rem 1rem 0 0;
    }

    .MuiPaper-root {
        overflow-y: hidden !important;
    }

    // 내부 레이아웃 (타이틀 / 버튼 / 그라데이션)
    .drawer-content {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 3.75rem);

        .drawerTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            padding: rem(30) $InnerPadding rem(10);
            margin-bottom: 0;

            .tt {
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.1875rem;
            }

            &.scroll {
                overflow: hidden;
                border-bottom: 1px solid #ebebeb;
            }

            .btnClose {
                position: relative;
                width: rem(24);
                height: rem(24);
            }
        }

        .drawerContents {
            position: relative;
            margin-left: 0;
            padding: 1.5rem;
            width: 100%;

            // 불투명 효과
            &:after {
                content: '';
                display: block;
                position: sticky;
                bottom: -30px;
                left: 0;
                width: 100%;
                height: 35px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 21.78%, #ffffff 80.43%);
                z-index: 10;
            }
        }
    }

    // 버튼
    .drawerBtnWrap {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 6rem;
        padding: rem(6) $InnerPadding rem(20);
        padding-top: 0;
        background: #fff;

        &.noBtn {
            display: none;
        }

        .drawerBtn {
            width: 100%;
            border-radius: rem(40);
            font-size: 1rem;
            font-weight: 600;
            margin-right: rem(8);

            &:last-child {
                margin-right: 0;
            }

            &.btnInitialize {
                margin-right: 0.25rem;
                color: #c4c4c4;
                background: #f8f8f8;

                &.btnInitClicked {
                    color: #3906c3;
                    background: #eaf5f5;
                }
            }

            &.btnApply {
                color: #ffffff;
                background: #c4c4c4;

                &.btnApplyClicked {
                    background: #3906c3;
                }
            }
        }
    }
}

// 필드예약 > 상세보기 Drawer
.rezInfoDrawer {
    overflow: hidden;

    [class*='MuiDrawer-paper'] {
        border-radius: 1rem 1rem 0 0;
    }

    .MuiPaper-root {
        overflow: hidden !important;
    }

    .drawer-content {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 3.75rem);

        .drawerInner {
            display: flex;
            flex-direction: column;
            padding: $InnerPadding 0 0 0;
            overflow: hidden;

            .topBtnArea {
                display: flex;
                justify-content: flex-end;
                flex-shrink: 0;
                padding: 0 $InnerPadding;

                .btnClose {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-top: -0.25rem;
                    margin-right: -0.25rem;

                    svg {
                        padding: 0.25rem;
                    }
                }
            }

            .drawerContents {
                padding: 0 $InnerPadding 2.5rem;
                overflow-y: auto;
            }
        }
    }

    .drawerBtnArea {
        flex-shrink: 0;
    }
}

// 티타임 > 골프장별 공지사항 Modal
.lowZIndex {
    z-index: 1000 !important;
}

.highZIndex {
    z-index: 1500 !important;
}

.noticeModal {
    .noticeCon {
        height: 36vh;
        max-height: 280px;

        .noticeSwiper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% + 10px);

            .swiper-wrapper {
                height: calc(100% - 15px);
            }

            .swiper-slide {
                height: 100%;
                overflow-y: auto;
                touch-action: pan-y;
            }

            .swiper-pagination {
                display: flex;
                justify-content: center;
                flex-shrink: 0;

                .swiper-pagination-bullet {
                    width: 6px;
                    height: 6px;
                    margin: 0 3px;
                    background: $c-gray-500;
                    border-radius: 100%;
                    cursor: pointer;

                    &.swiper-pagination-bullet-active {
                        background: $c-21;
                    }
                }

                &.swiper-pagination-lock {
                    display: none;
                }
            }
        }
    }
}

// 지도 > 길찾기 Drawer
.openMapDrawer {
    [class*='MuiDrawer-paper'] {
        border-radius: 1rem 1rem 0 0;
    }

    [class*='MuiModal-backdrop'] {
        background: rgba(0, 0, 0, 0.75);
    }

    .drawerContents {
        padding: 1.5rem 2.75rem;

        ul {
            display: flex;
            justify-content: space-between;

            li {
                text-align: center;

                span {
                    display: block;
                    padding-top: 0.625rem;
                    @include font-light02;
                    font-weight: 600;
                }
            }
        }
    }
}

// snackBar
.MuiSnackbar-root {
    padding: 1.25rem 1rem;

    .MuiSnackbarContent-root {
        width: 100%;
        display: block !important;
        padding: rem(9) rem(24) !important;
        background: #10003b !important;
        border-radius: rem(40) !important;
        font-size: rem(14);
        line-height: 1.125rem;
        text-align: center;
        box-shadow: none !important;

        .MuiSnackbarContent-message {
            padding: 0 !important;
            white-space: pre-line;
        }
    }
}

// 웹 외부 접속 허용 안내
.webAccessInfoModal {
    z-index: 2000 !important;

    .drawer-content {
        position: relative;
        background: #fbfbfb;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: rem(80);
            left: -19px;
            width: 58px;
            height: 58px;
            background: rgba(242, 235, 252, 0.7);
            border-radius: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: rem(15);
            right: -75px;
            width: 150px;
            height: 150px;
            background: rgba(242, 235, 252, 0.7);
            border-radius: 100%;
        }

        .drawerContents {
            position: relative;
            z-index: 10;
        }
    }

    .logoArea {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(30);

        .logoIcon {
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.06);
            border-radius: 17px;

            svg {
                vertical-align: top;
            }
        }

        .logoTxt {
            margin-top: -4px;
            margin-left: 14px;
            font-family: 'Lexend';
            font-weight: 800;
            font-size: 28px;
            line-height: 35px;
        }
    }

    .txtCon {
        text-align: center;
        font-size: rem(18);
        font-weight: 700;
        line-height: rem(25);

        .bigTxt {
            display: block;
            margin-bottom: rem(4);
            font-size: rem(20);
        }

        p {
            span {
                color: $c-primary;
            }
        }
    }

    .btnWrap {
        margin-top: rem(30);
        text-align: center;

        .btnAnchor {
            margin-top: rem(20);
            font-size: rem(12);
            text-align: center;
            color: $c-gray;
            text-decoration: underline;
        }
    }
}

// 예약정보 조회 연동 동의
.agreementInfoModal {
    z-index: 1000 !important;
    height: 100%;
    height: calc(100vh -rem(56));

    .drawerContents {
        background: rgba(0, 0, 0, 0.8);
        overflow: hidden;
        border-radius: 1rem 1rem 0rem 0rem;
        height: 100%;

        .drawerContentWrap {
            position: relative;
            z-index: 10;
            padding: 4.375rem 1.875rem 1.625rem;
            color: $c-f;
            gap: 2.625rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            strong {
                font-size: 1.875rem;
                font-weight: 700;
                line-height: 132%;
            }

            .txtContents {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.875rem;
                width: 100%;

                div {
                    display: flex;
                    align-items: flex-start;
                    gap: 0.5rem;

                    .number {
                        display: flex;
                        width: 1.25rem;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        color: $c-f;
                        font-weight: 700;
                    }

                    .txtWrap {
                        display: flex;
                        flex-direction: column;
                        font-size: 1rem;
                    }

                    .detail {
                        color: #b09be7;
                        font-size: 0.875rem;
                        font-weight: 700;
                        line-height: 140%;
                        display: flex;
                        align-items: center;

                        .btn_ar {
                            @include textHidden;
                            @include iconGoArrow($size: 1.5rem, $color: 'B09BE7');
                            background-size: 7px;
                        }
                    }

                    .detailEtc {
                        color: #a0a4af;
                        font-size: 0.8125rem;
                        font-weight: 400;
                        line-height: 132%;
                    }
                }

                .txtSection02 {
                    margin-bottom: 1rem;
                }

                .checkSection {
                    width: 100%;

                    .agreeCheckList {
                        position: relative;
                        width: 100%;

                        .checkLabel {
                            display: flex;
                            flex-direction: column;

                            label {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                width: 100%;

                                .btn_ar {
                                    @include textHidden;
                                    @include iconGoArrow($size: 1.5rem, $color: 'A0A4AF');
                                    background-size: 7px;
                                    margin-left: auto;
                                }
                            }

                            .agreeCheckTxt {
                                color: #fff;
                                font-size: 0.875rem;
                                font-weight: 400;
                                line-height: normal;
                            }

                            .customCheck {
                                .icon {
                                    width: 1.5rem;
                                    height: 1.5rem;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .checkAlert {
                            position: absolute;
                            left: 2rem;
                            color: #ff4d6f;
                            font-size: 0.8125rem;
                            margin-top: 0.47rem;
                            animation: vibration 0.1s;
                            animation-iteration-count: 10;

                            @keyframes vibration {
                                from {
                                    transform: translateX(-0.1rem);
                                }

                                to {
                                    transform: translateX(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btnArea {
        position: absolute !important;
        top: -1.625rem;
        right: 2.25rem;

        i {
            position: absolute;
            z-index: 1300 !important;
        }
    }

    .txtCon {
        text-align: center;
        font-size: rem(18);
        font-weight: 700;
        line-height: rem(25);

        .bigTxt {
            display: block;
            margin-bottom: rem(4);
            font-size: rem(20);
        }

        p {
            span {
                color: $c-primary;
            }
        }
    }

    .btnWrap {
        margin: 2.5rem 0 4.63rem;
        text-align: center;
        width: 100%;
        display: flex;

        .btnPrimary {
            position: relative;
            @include BtnSize;
            background: $c-f;
            color: $c-primary;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                background-color: $c-gray-03 !important;
            }

            @include ripples($c-primary);
        }

        .btn_ar {
            @include textHidden;
            @include iconGoArrow($size: 1.5rem, $color: '3906c3');
            background-size: 7px;
        }
    }

    // mui background 제어
    .MuiBackdrop-root {
        background-color: none !important;
        background: none !important;
    }

    .MuiPaper-root {
        background-color: none !important;
        background: none !important;
        height: calc(100% - 3.5rem) !important;
        overflow-y: visible;
    }
}

.confirmAgreeModal {
    position: relative;
    top: 50%;
    left: 50%;
    width: calc(100% - 3rem);
    max-width: 350px;
    transform: translateX(-50%) translateY(-50%);

    outline: 0;

    #alertConWrap {
        background: #fff;
        padding: rem(30) rem(24);
        text-align: center;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        border-radius: 1rem 1rem 0 0;
    }

    #alertTitle {
        margin-bottom: rem(20);
        font-size: rem(18);
        font-weight: 600;
        color: $c-0;
    }

    .alertContents {
        font-size: rem(14);
        line-height: rem(20);
        white-space: pre-line;

        .companyList {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;
                font-size: rem(14);
                line-height: rem(24);
            }
        }

        &:first-child {
            text-align: center;
        }
    }

    .alertBtnArea {
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 0 0 1rem 1rem;

        button,
        a {
            flex: 1;
            line-height: rem(52);
            font-size: rem(16);
            font-weight: 600;
            background: $c-gray-03;
            color: $c-f;
            text-align: center;
            overflow: hidden;
            @include ripples($c-gray-03);

            &:last-child {
                background: $c-navy;
                @include ripples-light($c-navy, 5%);
            }
        }
    }

    .popUpHide {
        position: absolute;
        top: -1.625rem;
        left: 0;
        color: $c-f;
        font-size: rem(14);
        display: flex;
        align-items: center;
        justify-content: center;

        .btn_ar {
            display: inline-block;
            margin-top: 0.125rem;
            @include iconCheck($size: 1.5rem, $color: 'FFF');
            vertical-align: middle;
        }
    }
}

.maintainanceNoticeModal {
    position: relative;
    top: 50%;
    left: 50%;
    width: calc(100% - 3rem);
    max-width: 350px;
    transform: translateX(-50%) translateY(-50%);
    outline: 0;

    #alertConWrap {
        background: #fff;
        padding: rem(30) rem(24);
        text-align: center;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        border-radius: 1rem 1rem 0 0;
    }

    #alertTitle {
        margin-bottom: rem(20);
        font-size: rem(18);
        font-weight: 600;
        color: $c-0;
    }

    .alertContents {
        font-size: rem(14);
        line-height: rem(20);
        white-space: pre-line;

        .companyList {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;
                font-size: rem(14);
                line-height: rem(24);
            }
        }

        &:first-child {
            text-align: center;
        }
    }

    .alertBtnArea {
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 0 0 1rem 1rem;

        button,
        a {
            flex: 1;
            line-height: rem(52);
            font-size: rem(16);
            font-weight: 600;
            background: $c-navy;
            color: $c-f;
            text-align: center;
            overflow: hidden;
            @include ripples-light($c-navy, 5%);
        }
    }

    .popUpHide {
        position: absolute;
        top: -1.625rem;
        left: 0;
        color: $c-f;
        font-size: rem(14);
        display: flex;
        align-items: center;
        justify-content: center;

        .btn_ar {
            display: inline-block;
            margin-top: 0.125rem;
            @include iconCheck($size: 1.5rem, $color: 'FFF');
            vertical-align: middle;
        }
    }
}
