@import '../../assets/styles/scss/reset';
@import '../../assets/styles/scss/variables';
@import '../../assets/styles/scss/typography';
@import '../../assets/styles/scss/functions';
@import '../../assets/styles/scss/mixin';
@import '../../assets/styles/scss/forms';
@import '../../assets/styles/scss/board';
@import '../../assets/styles/scss/reservation';
@import '../../assets/styles/scss/buttons';
@import '../../assets/styles/scss/icons';
@import '../../assets/styles/scss/modal';

.maintainance-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .maintainance-notice {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .notice-contents {
            text-align: center;
            font-weight: 700;
            margin-bottom: 3.5rem;
            p {
                color: #3906c3;
                font-weight: 700;
                text-align: center;
            }
            display: flex;
            flex-direction: column;
        }
        .notice-date {
            text-align: end;
        }
        .logo {
            margin-top: -5rem;
            width: 50%;
            min-height: rem(120);
        }
    }
}
