// NEW
.newIcon {
    display: inline-block;
    width: rem(16);
    height: rem(16);
    line-height: rem(16);
    margin-left: rem(4);
    background: $c-orange;
    font-size: rem(10);
    font-weight: 700;
    text-align: center;
    color: $c-f;
    border-radius: 100%;
}

// 상태 아이콘
.statusIcon {
    padding: rem(3) rem(5);
    font-size: rem(10);
    font-weight: 600;
    line-height: rem(12);
    background: $c-orange;
    border-radius: 2px;
    color: $c-f;
    overflow: hidden;

    &.line {
        padding-top: rem(2);
        padding-bottom: rem(2);
        border: $border-gray;
        background: #fff;
        color: $c-gray;
    }
    &.black {
        background: $c-0;
    }
    &.selfCheckIn {
        border: 1px solid #0088ac;
        padding-top: rem(2);
        padding-bottom: rem(2);
        background: #fff;
        color: #0088ac;
        &:is(button),
        &:is(a) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 0;
            &::after {
                margin-left: -1px;
                @include iconMoreArrow($size: 12px, $color: '0088ac');
                vertical-align: top;
            }
        }
    }

    &.outReserve {
        background: #706689;
    }
}
