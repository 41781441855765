/* Lexend */
@font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Lexend-Bold.eot');
    src: url('../fonts/Lexend-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lexend-Bold.woff2') format('woff2'), url('../fonts/Lexend-Bold.woff') format('woff'),
        url('../fonts/Lexend-Bold.ttf') format('truetype'), url('../fonts/Lexend-Bold.svg#Lexend-Bold') format('svg');
}

/* roboto-100 */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/roboto-100.eot');
    src: local(''), url('../fonts/roboto-100.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-100.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-100.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-100.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-300.eot');
    src: local(''), url('../fonts/roboto-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-regular.eot');
    src: local(''), url('../fonts/roboto-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-500.eot');
    src: local(''), url('../fonts/roboto-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-700.eot');
    src: local(''), url('../fonts/roboto-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/roboto-900.eot');
    src: local(''), url('../fonts/roboto-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-900.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-900.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-900.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* Noto Sans KR */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/NotoSansKR-300.eot');
    src: local(''), url('../fonts/NotoSansKR-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/NotoSansKR-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/NotoSansKR-300.woff') format('woff'),
        /* Safari, Android, iOS */ url('../fonts/NotoSansKR-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/NotoSansKR-regular.eot');
    src: local(''), url('../fonts/NotoSansKR-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/NotoSansKR-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/NotoSansKR-regular.woff') format('woff'), format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/NotoSansKR-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/NotoSansKR-500.eot');
    src: local(''), url('../fonts/NotoSansKR-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/NotoSansKR-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/NotoSansKR-500.woff') format('woff'),
        /* Safari, Android, iOS */ url('../fonts/NotoSansKR-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/NotoSansKR-700.eot');
    src: local(''), url('../fonts/NotoSansKR-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/NotoSansKR-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/NotoSansKR-700.woff') format('woff'),
        /* Safari, Android, iOS */ url('../fonts/NotoSansKR-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/NotoSansKR-900.eot');
    src: local(''), url('../fonts/NotoSansKR-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/NotoSansKR-900.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/NotoSansKR-900.woff') format('woff'),
        /* Safari, Android, iOS */ url('../fonts/NotoSansKR-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* Pretendard */
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: nor;
    src: url('../fonts/Pretendard-Light.eot');
    src: url('../fonts/Pretendard-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Pretendard-Light.svg#Pretendard-Light') format('svg'),
        url('../fonts/Pretendard-Light.ttf') format('truetype'), url('../fonts/Pretendard-Light.woff') format('woff'),
        url('../fonts/Pretendard-Light.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Pretendard-Regular.eot');
    src: url('../fonts/Pretendard-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Pretendard-Regular.svg#Pretendard-Regular') format('svg'),
        url('../fonts/Pretendard-Regular.ttf') format('truetype'),
        url('../fonts/Pretendard-Regular.woff') format('woff'), url('../fonts/Pretendard-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Pretendard-Medium.eot');
    src: url('../fonts/Pretendard-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Pretendard-Medium.svg#Pretendard-Medium') format('svg'),
        url('../fonts/Pretendard-Medium.ttf') format('truetype'), url('../fonts/Pretendard-Medium.woff') format('woff'),
        url('../fonts/Pretendard-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Pretendard-SemiBold.eot');
    src: url('../fonts/Pretendard-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Pretendard-SemiBold.svg#Pretendard-SemiBold') format('svg'),
        url('../fonts/Pretendard-SemiBold.ttf') format('truetype'),
        url('../fonts/Pretendard-SemiBold.woff') format('woff'),
        url('../fonts/Pretendard-SemiBold.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Pretendard-Bold.eot');
    src: url('../fonts/Pretendard-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Pretendard-Bold.svg#Pretendard-Bold') format('svg'),
        url('../fonts/Pretendard-Bold.ttf') format('truetype'), url('../fonts/Pretendard-Bold.woff') format('woff'),
        url('../fonts/Pretendard-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard-ExtraBold';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/Pretendard-ExtraBold.eot');
    src: url('../fonts/Pretendard-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Pretendard-ExtraBold.svg#Pretendard-ExtraBold') format('svg'),
        url('../fonts/Pretendard-ExtraBold.ttf') format('truetype'),
        url('../fonts/Pretendard-ExtraBold.woff') format('woff'),
        url('../fonts/Pretendard-ExtraBold.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/Pretendard-Black.eot');
    src: url('../fonts/Pretendard-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Pretendard-Black.svg#Pretendard-Black') format('svg'),
        url('../fonts/Pretendard-Black.ttf') format('truetype'), url('../fonts/Pretendard-Black.woff') format('woff'),
        url('../fonts/Pretendard-Black.woff2') format('woff2');
}

/* TheJamsil */
@font-face {
    font-family: 'TheJamsil';
    font-weight: 100;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Thin.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Thin.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Thin.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Thin.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Thin.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'TheJamsil';
    font-weight: 300;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Light.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Light.eot?#iefix')
            format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Light.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Light.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Light.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'TheJamsil';
    font-weight: 400;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Regular.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Regular.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Regular.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Regular.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'TheJamsil';
    font-weight: 500;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Medium.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Medium.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Medium.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Medium.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'TheJamsil';
    font-weight: 700;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Bold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Bold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Bold.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-Bold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'TheJamsil';
    font-weight: 800;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-ExtraBold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-ExtraBold.eot?#iefix')
            format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-ExtraBold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-ExtraBold.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/TheJamsil/TheJamsil-ExtraBold.ttf') format('truetype');
    font-display: swap;
}
