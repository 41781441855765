@import '../../../assets/styles/scss/help';

/**
예약 실패 페이지
*/

//예약 완료하지 못햇습니다.
.payment-booking-fail-contents {
    position: relative;

    // 완료 페이지
    .cancel-status-area {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-bottom: 40%;

        .inner {
            width: 100%;
        }

        .cancel-status-title-msg {
            position: relative;
            font-size: rem(24);
            font-weight: 600;
            line-height: rem(28);
            white-space: nowrap;

            .name {
                color: #3906c3;
            }

            .icon {
                display: block;
                margin-bottom: rem(30);

                svg {
                    vertical-align: top;
                }
            }
        }

        .txt {
            font-weight: 400;
            margin-top: rem(12);
            @include font-light;
            color: $c-gray;
            text-align: center;
            font-size: rem(14);
            white-space: pre-line;
        }

        .cancel-status-sub-text-area {
            // height: rem(64);
            margin-bottom: rem(30);
            margin-right: rem(24);
            margin-left: rem(24);
            background-color: #f6f7f8;
            padding: rem(14) rem(14);
            border-radius: rem(8);

            overflow: auto;

            p {
                font-size: rem(12) !important;
                line-height: 18px;
                color: $c-gray;
                white-space: pre-wrap;
            }
        }
    }

    // 하단 고정 버튼
    .cancel-status-btn-bottom,
    .cancel-status-btn-fixed-bottom {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 1rem 0;
        background: #fff;
        z-index: 10;

        &.fixed {
            position: fixed;
        }

        .inner {
            display: flex;

            button {
                flex: 1 1 auto;
                margin-left: rem(8);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &.btn-full-width {
            .inner {
                display: block;

                button {
                    margin-left: 0;
                    margin-top: 0.75rem;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .booking-info {
        margin-top: rem(30);
        border-top: 1px solid $c-0;

        .payment-info-type {
            margin-top: rem(12);

            .sub-title-area {
                position: relative;

                h2 {
                    @include font-light02;
                    font-weight: 700;

                    &.green {
                        color: $c-primary;
                    }

                    span {
                        font-size: 0.875rem;
                        font-weight: 400;
                        color: $c-gray;
                    }
                }

                p {
                    padding-top: 0.25rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    color: $c-gray;
                }

                & + div {
                    margin-top: 1.25rem;
                }

                & + button {
                    margin-top: 1rem;
                }
            }

            .tt {
                padding-bottom: rem(10);
                border-bottom: 4px solid #000;
                font-size: rem(14);
                line-height: rem(16);
                font-weight: 400;
            }

            dl {
                display: flex;
                justify-content: space-between;
                margin-top: 0.75rem;

                &:first-child {
                    margin-top: 0;
                }

                &.border-bottom {
                    &:last-child {
                        &.black {
                            border-bottom: 1px solid $c-0;
                        }
                        border-bottom: 1px solid rgba(215, 217, 226, 0.5);
                        padding-bottom: rem(12);
                    }
                }

                dt {
                    font-weight: 700;
                    @include font-light02;
                }

                dd {
                    @include font-light02;
                    font-weight: 400;
                    text-align: right;

                    &.primary {
                        color: $c-primary;
                    }
                }
            }

            .total-payment {
                font-weight: 800;
                color: $c-primary;
            }

            .non-caddieFee-text {
                margin-top: rem(4);

                p {
                    color: $c-red;
                    font-size: rem(12);
                    line-height: rem(16);
                }
            }
        }
    }

    .btn-bottom {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 1rem 0;
        background: #fff;
        z-index: 10;

        &.fixed {
            position: fixed;
        }

        .inner {
            display: flex;

            button {
                flex: 1 1 auto;
                margin-left: rem(8);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &.btn-full-width {
            .inner {
                display: block;

                button {
                    margin-left: 0;
                    margin-top: 0.75rem;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
