@import '../../../assets/styles/scss/help';

.payment-cancel-status-contents {
    // 완료 페이지
    .cancel-status-area {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-bottom: 22%;

        .inner {
            width: 100%;
        }

        .cancel-status-title-msg {
            position: relative;
            font-size: rem(24);
            font-weight: 600;
            line-height: rem(28);
            white-space: nowrap;

            .name {
                color: #3906c3;
            }

            .icon {
                display: block;
                margin-bottom: rem(30);

                svg {
                    vertical-align: top;
                }
            }
        }

        .txt {
            font-weight: 400;
            margin-top: rem(12);
            @include font-light;
            color: $c-gray;
            text-align: center;
            font-size: rem(14);
            white-space: pre-line;
        }

        .cancel-status-sub-text-area {
            // height: rem(64);
            margin-bottom: rem(30);
            margin-right: rem(24);
            margin-left: rem(24);
            background-color: #f6f7f8;
            padding: rem(14) rem(14);
            border-radius: rem(8);

            overflow: auto;
            text-align: left;

            p {
                font-size: rem(12) !important;
                line-height: 18px;
                color: $c-gray;
                white-space: pre-wrap;
            }
        }
    }

    // 하단 고정 버튼
    .cancel-status-btn-bottom,
    .cancel-status-btn-fixed-bottom {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 1rem 0;
        background: #fff;
        z-index: 10;

        &.fixed {
            position: fixed;
        }

        .inner {
            display: flex;

            button {
                flex: 1 1 auto;
                margin-left: rem(8);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &.btn-full-width {
            .inner {
                display: block;

                button {
                    margin-left: 0;
                    margin-top: 0.75rem;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
