@import './mixin';

:root {
    font-size: 16px;
}
*,
*:after,
*:before {
    box-sizing: border-box;
    word-break: break-all;
}
.blind {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
}
.hide {
    display: none !important;
}
body,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
select,
table,
td,
textarea,
th,
ul {
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
table,
textarea {
    color: #000;
    font-family: 'Pretendard', 'Roboto', -apple-system, BlinkMacSystemFont, 'Malgun Gothic', '맑은 고딕', helvetica,
        'Apple SD Gothic Neo', sans-serif;
    font-weight: 400;
}

body {
    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        background-clip: padding-box;
        border: 1px solid transparent;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track {
        background: transparent;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    line-height: inherit;
    font-weight: 400;
}
textarea {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: transparent;
    border: 0;
    word-break: keep-all;
    word-wrap: break-word;
}
button,
input {
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0;
}
a {
    -webkit-tap-highlight-color: transparent;
}
button {
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    cursor: pointer;
    &:disabled {
        cursor: no-drop !important;
    }
}
fieldset,
img {
    border: 0;
}
img {
    max-width: 100%;
    vertical-align: top;
}
ol,
ul {
    list-style: none;
}
address,
em {
    font-style: normal;
}
a {
    color: inherit;
    text-decoration: none;
}
strong,
b {
    font-weight: 600;
}
iframe {
    overflow: hidden;
    margin: 0;
    border: 0;
    padding: 0;
    vertical-align: top;
}
mark {
    background-color: transparent;
}
i {
    font-style: normal;
}
input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='search']:focus,
input[type='email']:focus,
textarea:focus {
    outline: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}
button:focus {
    outline: 0;
}

@include mobileSmall {
    :root {
        font-size: 14px;
    }
}

* {
    user-select: none;
}

input,
textarea {
    user-select: auto;
}
