$baseURL: '/src';
$baseImg: $baseURL + '/img';

//디폴트 컨텐츠 좌우 여백
$InnerPadding: 1.5rem;

//색상
$c-default: #212121;
$c-0: #000;
$c-a9: #a9a9a9;
$c-c4: #c4c4c4;
$c-f: #fff;
$c-f5: #f5f5f5;
$c-f8: #f8f8f8;
$c-red: #ff4d6f;

//Primary
$c-primary: #3906c3;
$c-secandary: #ebe6f9;
$c-secandary-02: #10003b;

//Blue
$c-navy: #10003b;
$c-blue: #1145ff;
$c-blue-500: $c-blue;
$c-blue-800: $c-navy;

//Orange
$c-orange: #fe6215;

//Gray Scale
$c-21: #212121;
$c-gray: #777f8a;
$c-gray-02: #3c414a;
$c-gray-03: #a0a4af;
$c-gray-04: #d7d9e3; // placeholder numeric
$c-gray-05: #d7d9e2;

$c-gray-900: #212121;
$c-gray-700: $c-gray;
$c-gray-500: #c4c4c4;
$c-gray-400: #d3d3d3;
$c-gray-300: #e0e0e0;
$c-gray-200: #f0f1f4;
$c-gray-100: #f8f8f8;
$c-gray-000: #fff;

// color
.red {
    color: $c-red;
}
.gray {
    color: $c-gray;
}
.orange {
    color: $c-orange !important;
}

//bg
$bg-light-blue: #f2f4f6;

// border
$border-gray: 1px solid rgba(215, 217, 226, 1);
$border-gray-opacity: 1px solid rgba(215, 217, 226, 0.5);
$border-gray-dotted: 1px dotted rgba(215, 217, 226, 1);
$border-gray-dashed: 1px dashed rgba(215, 217, 226, 0.5);

//font-size
$fz-mid-bbbbb: 24px;
$fz-mid-bbbb: 22px;
$fz-mid-bbb: 20px;
$fz-mid-bb: 18px;
$fz-mid-b: 16px;
$fz-mid: 14px;
$fz-mid-s: 12px;
$fz-mid-ss: 10px;

// flex
.flex-1 {
    flex: 1;
}

*[class~='flex'] {
    display: flex;

    &[class*='jcc'] {
        justify-content: center;
    }

    &[class*='jcs'] {
        justify-content: flex-start;
    }

    &[class*='jce'] {
        justify-content: flex-end;
    }

    &[class*='jcsb'] {
        justify-content: space-between;
    }

    &[class*='itmfs'] {
        align-items: flex-start;
    }

    &[class*='itmc'] {
        align-items: center;
    }

    &[class*='itmfe'] {
        align-items: center;
    }

    &[class*='itmfe2'] {
        align-items: flex-end;
    }

    &.col {
        flex-direction: column;
    }
}

// align
.alignC {
    text-align: center !important;
}

.alignL {
    text-align: left !important;
}

// padding
.pdB0 {
    padding-bottom: 0px !important;
}
