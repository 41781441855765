@import '../../assets/styles/scss/help';

#notice {
    .inner {
        .noticeCon {
            padding-top: rem(20) !important;
            ul {
                li {
                    border-bottom: $border-gray-opacity;
                    &.ref {
                        border: none;
                    }
                    &:first-child {
                        border-top: 4px solid $c-0;
                    }

                    .anchorArea {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: rem(20) 0;
                        text-align: left;
                        cursor: pointer;

                        .ttArea {
                            padding-right: 0.625rem;

                            .title {
                                font-size: rem(16);
                                font-weight: 600;
                                color: $c-0;

                                .newIcon {
                                    position: relative;
                                    top: -1px;
                                }
                            }

                            .date {
                                display: block;
                                padding-top: rem(6);
                                @include font-light02;
                                color: $c-gray;
                            }
                        }

                        .btn_ar {
                            @include iconGoArrow($size: 12px);
                            @include textHidden;
                        }
                    }

                    &.nodata {
                        border: none !important;
                    }
                }
            }
        }

        .detailCon {
            .detailTitle {
                .titleArea {
                    border-bottom: 4px solid $c-0;
                }

                .date {
                    display: block;
                    padding-top: rem(12);
                    color: $c-gray;
                    @include font-light02;
                }
            }

            .detailContents {
                padding: 0 0 rem(30);

                img {
                    display: block;

                    & + .detailTxt {
                        margin-top: 12px;
                    }
                }

                .detailTxt {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5rem;
                }
            }
        }
    }
}
