@import '../../../assets/styles/scss/help';

/* border 1px solid ebebeb*/
.fieldBookingContentArea {
    padding-bottom: 0px !important;

    .fieldBookingInfoTop {
        padding-top: rem(20);

        & + .section {
            border-top: none !important;
        }

        .bookingState {
            margin-bottom: rem(5);

            .statusIcon {
                margin-right: rem(4);
            }
        }
    }

    .fieldBookingTitleArea {
        padding-bottom: rem(10);
        border-bottom: 4px solid $c-0;

        .statusIcon {
            display: inline-block;
            margin-bottom: rem(5);
            vertical-align: top;
        }

        h2 {
            font-size: rem(18);
            font-weight: 600;
            line-height: rem(21);
        }
    }

    .titleArea02 {
        & + div {
            margin-top: rem(10);
        }

        & + .infoType06 {
            margin-top: rem(14);
        }
    }

    .fieldBookingDetailList {
        padding: rem(14) 0;
        border-bottom: 1px solid $c-0;
    }

    .borderSection {
        .infoType06 {
            & + .listType01 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: rem(14);
                background: $c-gray-200;
                border-radius: rem(8);
                padding: rem(12) rem(14);
                overflow: hidden;

                p {
                    margin-right: rem(16);
                    font-size: rem(12);
                    line-height: rem(16);
                    color: $c-gray;
                    word-break: keep-all;
                }
            }

            .greenFee-include {
                font-weight: 600;
                font-size: 14px;
                color: $c-primary;
            }
        }
    }

    .inner {
        .section {
            padding: rem(20) 0;
            border-top: $border-gray-opacity;

            &:first-child {
                border-top: none;
            }

            .fieldBookingInnerCondition {
                ul > li {
                    list-style-type: none;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;

                    color: $c-0;
                }
            }
        }
    }
}

.fieldBookingMain {
    z-index: 1;
    position: absolute !important;
}

.loadingPage {
    z-index: 1300;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 50%;
}

.fieldBookingAllPaymentWrap {
    .infoType06 {
        dl:first-child > dd {
            color: $c-0;
            font-weight: 700 !important;
            font-size: 14px !important;
            line-height: 17px;
        }
    }
}

/* 내장 인원 버튼 */
.fieldBookingInnerPeopleBtnWrap {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;

    button {
        /* Auto layout */

        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.75rem 1.75rem;
        gap: 0.625rem;

        width: 6.25rem;
        height: 2.375rem;

        margin-left: 0.375rem;

        background: #3906c3;
        border-radius: rem(4);

        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.063rem;

        text-align: center;
        color: $c-gray-000;

        &:first-child {
            margin-left: 0;
        }
    }

    .fieldBookingInnerPeopleBtn-select {
        background: #3906c3;
        color: #ffffff;
    }

    .fieldBookingInnerPeopleBtn {
        background: #ffffff;
        border: $border-gray;
        color: #000;
    }
}

/* 취소 가능 기한 */
.fieldBookingCancelDateWrap {
    display: flex;
    justify-content: space-between;

    b {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.063rem;
        color: $c-red;
    }

    .fieldBookingIsCancelTrue {
        color: #3906c3;
    }

    .fieldBookingIsCancelFalse {
        color: $c-red;
    }
}

.fieldBookingRowNotice {
    @include font-light02;
    color: $c-gray;
    background-color: #f2f4f6;

    h2 {
        font-weight: 600;
    }

    p {
        font-weight: 400;
        margin-top: rem(10);
    }
}

.fieldBookingBtnArea {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: rem(20) $InnerPadding;
    background: #fff;
}

/* ============== 예약 완료 팝업 레이어 디자인 ================*/

/* 예약 정보 Drawer */
.fieldBookingInfo {
    .date {
        padding-bottom: 0.625rem;
        line-height: 1.1875rem;
    }

    .ttArea {
        .tt {
            font-weight: 600;
            font-size: 1.375rem;
            line-height: 1.875rem;
        }
    }

    .courseInfo {
        padding-top: 0.375rem;
        @include font-light02;
        color: $c-gray;
    }
}

.fieldBookingLayerPaymentWrap {
    padding-top: 0.75rem;

    font-size: rem(14);
    line-height: 17px;
    color: $c-primary;

    dl {
        display: flex;
        justify-content: space-between;
        font-weight: 700;

        dt {
            span {
                font-weight: 400;
            }
        }

        dd {
            b {
                color: #000;
                font-weight: 700 !important;
                font-size: 14px !important;
                line-height: 17px;
            }
        }
    }

    .warningMsg {
        margin-top: 0.25rem;
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(16);
        color: $c-red;
    }
}

/* 취소 가능 기한 */
.cancelDeadLineWrap {
    width: 100%;
    margin-top: 1.25rem;
    background: #f8f8f8;
    padding: 0.9375rem 1rem;
    //border-radius: 8px;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(17);
    font-style: normal;

    .tt {
        display: block;
        margin-bottom: 0.25rem;
        color: $c-gray;
    }

    strong {
        color: $c-0;
    }

    p {
        margin-top: 4px;
        font-size: rem(12);
        color: $c-primary;
    }

    span {
        font-size: 0.75rem;
        font-weight: 400;
    }
}

.agreeCheckList-booking {
    padding-bottom: rem(14);
    border-bottom: $border-gray-opacity;
}

.cancelDeadLineErrorMsg {
    color: $c-red !important;
}

/* 이용동의 */

.fieldBookingLayerAgreeWrap {
    margin-top: 1rem;

    ul > li {
        margin-top: 1.375rem;
    }
}

/* 이용동의 주의사항 */
.booking-cautionTxt03 {
    margin-top: rem(10);
    position: relative;
    /*padding-left: 1.125rem;*/
    font-size: 0.75rem;
    line-height: 1rem;
    color: $c-gray;
}

/* ============== 예약 완료 팝업 레이어 디자인 ================*/

/* ============== 예약 완료 페이지 ============== */

.fieldBookingComplete {
    padding-bottom: 3rem;
    //border-top: 1px solid $c-gray-200;
    .section {
        padding: rem(23) $InnerPadding rem(30);
    }

    .ttArea {
        margin-bottom: rem(18.15);
        padding-bottom: rem(18);
        border-bottom: 4px solid $c-0;

        .tt {
            display: block;
            font-weight: 500;
            font-size: rem(20);
            line-height: rem(24);
            word-break: keep-all;
            font-style: normal;
            margin-top: rem(5);
        }
    }

    //.cancelDeadLineWrap {
    //    margin-top: rem(20);
    //}
    .fieldBookingCompleteBorderLine {
        margin-top: 0.75rem;
        padding-top: 0.75rem;
        border-top: 1px solid $c-0;
    }

    .advSection {
        img {
            width: 100%;
            border-radius: rem(8);
            margin-top: rem(10);
        }
    }
}

/* ============== 예약 완료 ============== */
