@import '../../../assets/styles/scss/help';

.companion-wrapper {
    padding: 5.37rem 1.5rem 1.5rem 1.5rem;

    .title {
        display: flex;
        align-items: center;
        position: fixed;
        padding: 1.88rem 1.5em 0;
        width: 100%;
        left: 0;
        top: 3.063rem;
        z-index: 1000;
        height: 4.51rem;
        background: #fff;

        h2 {
            flex: 1;
            color: #000;
            font-size: 1.375rem;
            font-weight: 700;
            line-height: 1.75rem;
        }

        .refresh-area {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .partner-info-wrapper {
        overflow-y: auto;
        padding-top: 3.76rem;

        .list-container {
            display: block;

            li {
                display: flex;
                align-items: center;
                margin-left: 0.313rem;
                margin-top: rem(20);

                &:first-child {
                    margin-top: 0;
                }

                .Avatar {
                    width: 3.625rem;
                    height: 3.625rem;
                    margin-right: rem(24);

                    .name {
                        display: none !important;
                        font-size: 0;
                        text-indent: -9999px;
                        line-height: 0;
                    }

                    .number {
                        display: none;
                    }

                    .btnAdd {
                        width: 3.625rem;
                        height: 3.625rem;
                    }
                }

                .userInfo {
                    width: calc(100% - 4.625rem);

                    .name {
                        display: block;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.1875rem;
                        @include textEllipsis;

                        .memberName {
                            display: inline-block;
                            font-weight: 600;
                            line-height: rem(19);
                            vertical-align: middle;
                        }

                        .lastFourDigits {
                            color: $c-gray;
                            font-size: 0.875rem;
                        }
                    }

                    .state {
                        margin-top: rem(6);
                        @include font-light02;
                        color: $c-gray;

                        span:not(:first-child) {
                            &::before {
                                content: '';
                                display: inline-block;
                                width: 1px;
                                height: 0.625rem;
                                margin: 0 0.375rem;
                                background: #d3d3d3;
                            }
                        }

                        .complete {
                            color: #3906c3;
                        }
                    }

                    &.invite {
                        .name {
                            font-weight: 600;
                        }
                    }

                    .icon {
                        width: 1.25rem;
                        height: 1.25rem;
                        margin: 0 0.25rem;
                    }
                }
            }
        }

        .bookingAlert {
            margin-top: 2rem;
            margin-bottom: 3.125rem;
            padding: 0.88rem;
            background: #F0F1F4;
            border-radius: 0.5rem;

            .tt {
                font-size: 0.75rem;
                font-weight: 600;
                margin-bottom: 0.38rem;
            }

            .listType01 {
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1rem;
            }

            // .AlertTxt {
            //     font-size: 0.875rem;
            //     font-weight: 400;
            //     line-height: 1.0625rem;
            // }

            // .btnTooltip {
            //     margin-left: 0.375rem;
            // }

            // .defaultTooltip {
            //     // inset: inherit !important;
            //     // transform: inherit !important;
            //     left: rem(12) !important;
            //     // bottom: 26px !important;
            //     width: rem(260);
            // }
        }
    }
}