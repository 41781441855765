@import '../../../assets/styles/scss/help';

// Ripples
@mixin ripples($color, $opacity: 12%) {
    transition: background 1s;

    &:disabled {
        transition: background 0s !important;
    }

    &:not(:disabled):active {
        background-color: darken($color, $opacity) !important;
        transition: background 0s;
    }
}

@mixin ripples-light($color, $opacity: 12%) {
    transition: background 1s;

    &:disabled {
        transition: background 0s !important;
    }

    &:not(:disabled):active {
        background-color: lighten($color, $opacity) !important;
        transition: background 0s;
    }
}

.self-check-in-wrapper {
    height: 100vh;
    padding: 0rem !important;
    overflow: hidden;

    .content-wrapper {
        // position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        margin-left: calc(-50vw + 50%);
        margin-bottom: calc(+50vh + 50%);
        //border-radius: 1rem;
        overflow: hidden;
        z-index: 0;

        img,
        video,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    .round-info {
        margin-top: 9.38rem;
        text-align: center;
        align-items: center;
        width: 100%;
        color: #fff;
        margin-bottom: 5.19rem; //컨펌 후 변경 예정

        .round-info-date {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 0.75rem;
        }

        .round-info-course-time {
            h2 {
                font-size: 1.5rem;
                font-weight: 700;
                margin-bottom: 0.75rem;

                span {
                    display: inline-block;
                    width: 0.0625rem;
                    height: 1rem;
                    background-color: #fff;
                }
            }
        }

        .round-info-golfclub-name {
            font-size: 0.875rem;
            font-weight: 500;
        }
    }

    .check-in-content {
        position: absolute;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        width: 100%;

        .check-in-card {
            padding-top: 3.13rem;
            padding-bottom: 3.13rem;
            border-radius: 16px;
            // background: linear-gradient(160deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.07) 46.14%, rgba(0, 0, 0, 0.40) 98.96%);
            background: #6138cf;
            height: 13.5rem;
            text-align: center;
            font-size: 0.875rem;
            font-family: Pretendard;
            line-height: 1.25rem;
            text-align: center;

            &.check-before {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                .check-in-touch {
                    width: 100px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .checkInImg {
                        margin-top: rem(-30);
                        margin-left: rem(7);
                        max-height: rem(40);
                        z-index: 99;

                        img,
                        svg {
                            stroke: #6138cf;
                            stroke-width: 1.5;
                        }
                    }
                }
            }

            .infoTxt {
                margin-top: rem(15.56);
                text-align: center;
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(20);
                white-space: pre-line;
                opacity: 0.8;

                span {
                    font-size: 1.25rem !important;
                }
            }

            &:not(.checked-in) {
                p {
                    color: #fff;
                    opacity: 80%;
                    margin-top: 1rem;
                }
            }

            &.checked-in {
                padding: 1.06rem 1.25rem 1rem 1.25rem;
                text-align: left;
                border: 0px;
                background: rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(25px);
                margin-bottom: 3.63rem;

                .check-in-info {
                    color: #fff;

                    .top-container {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 0.55rem;

                        .booking-type {
                            flex: 1;
                            font-size: 0.75rem;
                            font-weight: 500;
                            opacity: 70%;
                        }

                        .btn-area {
                            color: #fff;
                            font-size: 0.75rem;
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: right;

                            button {
                                // padding: 0.625rem;
                                background-size: 1rem !important;
                                @include iconRefresh($color: fff);
                                @include textHidden;
                                cursor: pointer;
                                opacity: 90%;

                                &.rotate {
                                    animation: rotate 1s linear infinite;
                                }

                                @keyframes rotate {
                                    0% {
                                        transform: rotate(0deg);
                                    }

                                    100% {
                                        transform: rotate(360deg);
                                    }
                                }
                            }
                        }
                    }

                    .name-container {
                        width: 100%;
                        display: flex;
                        height: 4.44rem;
                        flex-direction: row;
                        margin-bottom: 0.7rem;
                        padding-bottom: 0.75rem;
                        border-bottom: 1px solid #ffffff1f;

                        .name-golf-bag {
                            flex: 1;
                            padding-top: 0.5rem;

                            .name-gender {
                                display: flex;
                                flex-direction: row;
                                font-size: 1.25rem;
                                font-weight: 700;
                                margin-bottom: 0.37rem;
                                opacity: 90%;

                                .gender {
                                    display: inline-block;
                                    font-weight: 500;
                                }
                            }

                            .golf-bag {
                                display: block;
                                white-space: nowrap;
                                width: 8rem;
                                font-size: 0.875rem;
                                opacity: 70%;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }

                        .locker {
                            flex: 0.8;
                            display: flex;
                            flex-direction: column;
                            padding: 0.3rem 0rem 0.6rem;
                            justify-content: center;
                            align-items: center;
                            border-radius: 1rem;
                            background: #6138cf;

                            .title {
                                font-size: 0.75rem;
                                font-weight: 500;
                            }

                            .num {
                                font-size: 1.75rem;
                                font-weight: 700;
                            }
                        }
                    }

                    .helper-info {
                        color: #fff;
                        display: flex;
                        font-size: 1.375rem;
                        font-weight: 400;
                        margin-bottom: 0.7rem;
                        padding-bottom: 0.7rem;
                        border-bottom: 1px solid #ffffff1f;

                        dl {
                            position: relative;
                            display: flex;
                            font-size: rem(16);

                            dt {
                                color: var(--primary-primary-400, #b09be7);
                                font-size: 0.875rem;
                                font-family: Pretendard;
                            }

                            &:not(:first-child) {
                                margin-left: 1rem;
                            }

                            dd {
                                margin-left: 0.5rem;
                                color: #fff;
                                font-size: 0.875rem;
                                font-family: Pretendard;

                                span {
                                    display: inline-block;
                                    padding-right: 0.3rem;
                                }
                            }
                        }
                    }

                    .info {
                        font-size: 0.75rem;
                        line-height: 1rem;
                        opacity: 50%;
                    }
                }
            }
        }

        .qr-card {
            display: flex;
            flex-direction: row;
            margin-top: 0.63rem;
            border-radius: 16px;
            width: 100%;
            background: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(25px);
            padding: 0.75rem 1.25rem 0.75rem 1.25rem;
            opacity: 80%;

            .qr-card-contents {
                display: flex;
                flex-direction: row;
                font-size: 0.875rem;
                font-weight: 500;
                width: 95%;
                color: #fff;

                p {
                    align-self: center;
                }

                i {
                    position: relative;
                    z-index: 1;
                    margin-right: 0.25rem;

                    &.icon-qr {
                        @include iconQr($size: 1.5rem, $color: 'fff');
                        opacity: 80%;
                    }
                }
            }

            .icon-click {
                align-self: flex-end;
                position: relative;
                width: 1.5rem;
                height: 1.5rem;
                @include icon-click($size: 1.5rem, $color: 'fff');
            }
        }
    }
}

.qr-drawer {
    height: 100vh;
    display: flex;
    align-items: center;

    .qr-wrapper {
        width: 100%;
        padding: rem(30);
        overflow: hidden;
        text-align: center;

        .imgQr {
            height: 100%;
            max-height: 100%;
        }

        .disabled {
            opacity: 0.1;
        }

        .qr-title {
            h2 {
                color: #000;
                font-size: 1.375rem;
                font-weight: 700;
            }

            p {
                color: var(--primary-primary, #3906c3);
                text-align: center;
                font-size: 1rem;
                font-family: Pretendard;
                font-weight: 600;
            }
        }

        .check-in-image {
            margin-top: rem(101.87);

            img,
            svg {
                max-height: rem(70);
            }

            display: flex;
            flex-direction: column;
            margin-top: rem(24);
            align-items: center;

            svg {
                max-height: (200);
            }

            .roundInfo {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: rem(10);
                @include font-light02;
                color: $c-gray;

                span {
                    position: relative;
                    display: inline-block;
                    padding-left: 9px;
                    margin-left: 8px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 1px;
                        height: rem(10);
                        background: $c-gray-400;
                    }

                    &:first-child {
                        padding-left: 0;
                        margin-left: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            @include mobileSmall {

                img,
                svg {
                    max-height: 80px;
                }
            }

            .qr-code {
                flex: 1;
                width: rem(200);
                height: rem(200);
                padding: rem(30);
                border-radius: 1rem;
                border: 1px solid rgba(215, 217, 226, 0.5);
                overflow: hidden;

                .imgQr {
                    height: 100%;
                    max-height: 100%;
                }

                .disabled {
                    opacity: 0.1;
                }
            }

            .no-qr {
                display: inline-block;
                width: rem(200);
                height: rem(200);
                background-color: #f8f8f8 !important;
                border-radius: 8px;
                vertical-align: top;
                @include iconNoData($size: rem(140));
                @include textHidden;
            }
        }

        p {
            margin-top: rem(24);
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(19);

            .icon {
                svg {
                    vertical-align: top;
                }
            }
        }

        .infoTxt {
            margin-top: rem(15.56);
            text-align: center;
            font-size: rem(14);
            font-weight: 400;
            line-height: rem(20);
            white-space: pre-line;
            opacity: 0.8;
        }
    }

    .btn-wrapper {
        width: 100%;
        text-align: center;
        bottom: 2.5rem;
        position: absolute;
    }
}

.tag-name-modal {
    .btnArea {
        top: 0.44rem !important;
    }

    .infoMsg {
        font-size: rem(12);
        color: $c-primary;
        line-height: 1rem;
        z-index: 1;

        &.error {
            // padding-top: rem(6);
            color: $c-orange;
            // 2022.03.23 로그인 에러메세지 표출 시 아이디 인증횟수 제한으로 인해 리스폰스메세지값에 개행문자가 추가됨
            white-space: pre-line;

        }
    }

}

#check-in-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 3rem);
    max-width: 350px;
    // background: #fff;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 1rem;
    outline: 0;
    overflow: hidden;

    #check-in-modal-wrapper {
        background: #fff;
        padding: 1.88rem 1.5rem 1.88rem 1.5rem;

        #alertTitle {
            margin-bottom: 0.5rem;
            font-size: 1.125rem;
            font-weight: 600;
            color: $c-0;
        }

        .alertContents {
            margin-bottom: 1.25rem;
            font-size: rem(14);
            line-height: rem(20);
            white-space: pre-line;
            color: #777f8a;
        }

        .member-list {
            display: flex;
            flex-direction: column;
            gap: 0.63rem;
            padding-bottom: 1.88rem;

            .member-list-item {
                padding: 0.75rem 1rem 0.75rem 1rem;
                background: rgba(240, 241, 244, 0.40);
                border-radius: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px transparent;

                &.selected {
                    border-radius: 0.5rem;
                    border: 1px solid var(--primary-primary-900, #3906C3);
                    background: rgba(235, 230, 249, 0.40);
                }

                .member-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.375rem;

                    .member-name {
                        color: #000;
                        font-size: 1rem;
                        font-weight: 600;
                        // margin-bottom: 0.38rem;
                    }

                    .member-detail {
                        display: flex;
                        flex-direction: row;

                        .member-check-in {
                            color: var(--primary-primary, #3906C3);
                            font-size: 0.875rem;
                            font-weight: 400;

                            &::after {
                                display: inline-block;
                                content: '';
                                margin-left: 0.38rem;
                                margin-right: 0.38rem;
                                width: 0.0625rem;
                                height: 0.625rem;
                                background: #d3d3d3;
                            }
                        }

                        .member-locker {
                            color: #777F8A;
                            font-size: 0.875rem;
                            font-weight: 400;

                        }
                    }

                    .member-checkin-type {
                        display: flex;
                        // margin-top: 0.44rem;
                        width: auto;
                        padding: 0.1875rem 0.3125rem;
                        align-items: center;
                        gap: 0.625rem;
                        color: #FFF;
                        font-size: 0.625rem;
                        font-weight: 600;

                        &.golaga {
                            border-radius: 0.125rem;
                            background: #6138CF;
                        }

                        &.front {
                            border-radius: 0.125rem;
                            background: #706689;
                        }
                    }
                }

                .customCheck .icon {
                    margin-right: 0rem;
                    width: 1.25rem;
                    height: 1.25rem;
                }

            }
        }
    }

    .alertBtnArea {
        display: flex;
        align-items: center;
        overflow: hidden;

        button,
        a {
            flex: 1;
            line-height: rem(52);
            font-size: rem(16);
            font-weight: 600;
            background: $c-gray-03;
            color: $c-f;
            text-align: center;
            overflow: hidden;
            @include ripples($c-gray-03);

            &:last-child {
                background: $c-navy;
                @include ripples-light($c-navy, 5%);
            }
        }
    }
}