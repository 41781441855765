.skeleton {
    border-radius: 0.25rem;

    &.tabButton {
        display: inline-block;
        width: 80px;
        height: 30px;
        margin-right: 0.375rem;
    }
}

// [공통] Skeleton List
.skeletonList {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.75rem;

    .skeleton {
        &.listImg {
            width: 5.75rem;
            height: 5.75rem;
            margin-right: 1rem;
        }

        &.listTxt {
            width: 100px;
            height: 14px;
            margin-bottom: 0.75rem;
        }

        &.listAddress {
            width: 50px;
            height: 14px;
            margin-bottom: 0.75rem;
        }

        &.listDes {
            width: 170px;
            height: 14px;
            padding-top: 1rem;
        }
    }
}

// Visual Slider
.skeleton {
    &.visualSlide {
        width: 100%;
        height: 480px;
    }
}

// [Main] Main List
.skeleton {
    &.mainListImg {
        width: 84vw;
        height: 48.5vw;
        max-width: 350px;
        max-height: 200px;
    }

    &.mainListTxt {
        width: 130px;
        height: 20px;
        margin-top: 1.125rem;

        &:last-child {
            margin-top: 0;
        }
    }

    &.mainListTxt2 {
        width: 300px;
        height: 20px;
    }
}

// [Main] Best List
.skeleton {
    &.bestImg {
        width: 89vw;
        height: 57.8vw;
        max-height: 266px;
    }

    &.bestTit {
        display: inline-block;
        width: 150px;
    }

    &.bestTxt {
        display: inline-block;
        width: 270px;
        margin-top: 0.5rem;

        &:first-child {
            margin-top: 0;
        }
    }
}

// [Food] Category MenuList
.skeleton {
    &.desTxt {
        width: 175px;
        height: 20px;
    }

    &.priceTxt {
        width: 130px;
        height: 20px;
        margin-top: 0.25rem;
    }
}

// [Food] Restaurant Info
.skeletonWrap {
    .tit {
        width: 170px;
        height: 29px;
        margin-bottom: 0.75rem;
    }

    .listDes02 {
        width: 80%;
        height: 20px;
        margin-bottom: 0.75rem;
    }

    .info {
        width: 170px;
        height: 20px;
        margin-bottom: 0.75rem;
    }
}

// [Course-guide] Course Contents
.skeleton {
    &.titTxt {
        width: 170px;
        height: 20px;
    }

    &.courseChoice {
        width: 80px;
        height: 20px;
    }

    &.courseImg {
        width: 100%;
        height: 164px;
    }

    &.courseDes {
        width: 100%;
        height: 20px;
        margin-bottom: 0.75rem;
    }

    &.courseInfo {
        width: 100%;
        height: 78px;
    }

    &.courseBtn {
        width: 100%;
        height: 40px;
    }
}

// [Board]
.skeleton {
    &.boardTitle {
        display: inline-block;
        margin-bottom: -4px;
        margin-right: 8px;
        width: 90px;
        height: 25px;
    }

    &.boardTitle2 {
        width: 150px;
        height: 23px;
    }
}
