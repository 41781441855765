@import './assets/styles/scss/variables';
@import './assets/styles/scss/reset';
@import './assets/styles/scss/typography';
@import './assets/styles/scss/functions';
@import './assets/styles/scss/mixin';
@import './assets/styles/scss/forms';
@import './assets/styles/scss/board';
@import './assets/styles/scss/reservation';
@import './assets/styles/scss/buttons';
@import './assets/styles/scss/icons';
@import './assets/styles/scss/modal';

html,
body,
#root,
#contents {
    min-height: 100vh;
    min-height: calc(var(--vh) * 100);
}

.naviBottom {
    #contents {
        padding-bottom: rem(64);
    }
}

.inner {
    padding: 0 $InnerPadding;
}

.fullWidth {
    width: calc(100% + (#{$InnerPadding} * 2));
    margin-left: -$InnerPadding;
}

.fullPage {
    height: 100%;
    overflow: hidden;

    #root {
        display: flex;
        flex-direction: column;
    }

    body,
    #root {
        height: 100%;
    }

    #contents,
    .contentsArea {
        height: 100%;
    }
}

.gap {
    display: block;
    height: 0.625rem;
    background: #f8f8f8;
}

.bgLightBlue {
    background: $bg-light-blue;
}

.preLine {
    white-space: pre-line;
}

// root
#root {
    padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px)
        env(safe-area-inset-left, 0px);
}

// header
#header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;

    &.fixed {
        background: #fff;
        border-bottom: 1px solid #ebebeb;
    }

    &.absolute {
        position: absolute !important;
    }

    &.borderHeader {
        background: #fff;
        border-bottom: 1px solid #ebebeb;
    }

    .headerWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: rem(56);

        & > div {
            display: flex;
            align-items: center;
            height: 100%;
            overflow: hidden;

            button {
                width: 50px !important;
                height: 100% !important;
                flex-shrink: 0;
                font-size: 0;
                line-height: 0;
            }
        }

        .leftCon {
            flex-shrink: 0;
            margin-left: -20px;
        }

        .rightCon {
            flex-shrink: 0;
            justify-content: flex-end;
            margin-right: -20px;
        }

        .pageTitle {
            max-width: calc(100vw - 100px);
            font-size: rem(20);
            font-weight: 700;
            line-height: rem(20);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:first-child {
                margin-left: 20px;
            }
        }

        .btnHome {
            /*@include iconHome($color: '212121');*/
            background-size: 1.5rem;
        }

        .btnAlarm {
            background-size: 1.5rem;

            &.on {
                &::after {
                    content: '';
                    position: absolute;
                    top: 1.375rem;
                    right: 1.1875rem;
                    width: 0.4375rem;
                    height: 0.4375rem;
                    background: $c-orange;
                    border-radius: 100%;
                }
            }
        }

        .btnNotice {
            position: relative;
            @include iconAlarm($color: 'ffffff');
            background-size: 1.5rem;

            &.on {
                &::after {
                    content: '';
                    position: absolute;
                    top: 1.375rem;
                    right: 1.1875rem;
                    width: 0.4375rem;
                    height: 0.4375rem;
                    background: $c-orange;
                    border-radius: 100%;
                }
            }
        }

        .noticeTooltip {
            position: absolute;
            right: 45px;
            padding-right: 0.25rem;
            overflow: hidden;
            max-width: calc(100% - 3rem - 15px);

            .txtBox {
                border-radius: 50px;
                background: $c-f8;
                padding: 0.5rem 0.75rem;
                @include font-xlight;
                color: $c-gray;
                //white-space: nowrap;
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
                animation-name: fadeIn;
                animation-fill-mode: forwards;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0px;
                    height: 0px;
                    border-top: 6px solid transparent;
                    border-left: 8px solid $c-f8;
                    border-right: 8px solid none;
                    border-bottom: 6px solid transparent;
                }
            }

            .txt {
                display: inline-block;
                vertical-align: middle;
                overflow: hidden;
                animation-timing-function: ease-out;
                animation-name: fadeIn;
                animation-fill-mode: forwards;
            }

            &.close {
                .txtBox {
                    animation-name: fadeOut;
                }
            }
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: translateX(-100%);
            }

            to {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @keyframes fadeOut {
            from {
                opacity: 1;
                transform: translateX(0);
            }

            to {
                opacity: 0;
                transform: translateX(100%);
            }
        }

        .btnClose {
            @include iconClose($size: 1.5rem);
            background-size: 1.5rem;
        }
    }

    & + #contents,
    & + .contentsArea {
        padding-top: rem(56);
    }

    .bgLightBlue & {
        background: #fff;
    }
}

@include mobileSmall {
    #header {
        .headerWrap {
            height: 3.45rem;

            .noticeTooltip {
                right: 40px;
            }
        }

        & + #contents,
        & + .contentsArea {
            padding-top: 3.375rem;
        }
    }
}

// contents
#contents {
    position: relative;

    .contentsArea {
        position: relative;
        padding-bottom: rem(20);
    }

    .linkedGolfClub {
        .noti {
            padding-top: rem(12);
            font-size: rem(13);
            color: $c-gray;
            div {
                line-height: 1.105rem;
                display: flex;
                flex-direction: row;
            }
        }
    }

    &.bgGray {
        background: $c-f8;

        &.dark {
            background: $c-f5;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: rem(56);
            background: #fff;
        }

        section {
            background: #fff;
        }

        .gap {
            background: none;
        }
    }
}

@include mobileSmall {
    #contents {
        &.bgGray {
            &::before {
                height: 3.375rem;
            }
        }
    }
}

.titleArea {
    padding: rem(20) 0 rem(16);
    margin-bottom: rem(30);
    border-bottom: 4px solid $c-0;

    h1 {
        display: block;
        font-weight: 600;
        font-size: rem(24);
        line-height: rem(28);
        word-break: keep-all;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(17);
        padding-top: 0.875rem;
        color: $c-gray-02;
        //font-size: 1rem;
        //line-height: 1.375rem;
        //color: $c-gray;
    }

    &.border {
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 1.5625rem;
    }

    #header + .contentsWrap & {
        margin-top: 0.75rem;
    }

    &.nonPaddingbtm {
        margin-bottom: 0;
    }
}

.titleArea02 {
    position: relative;

    h2 {
        @include font-light02;
        font-weight: 700;

        &.green {
            color: $c-primary;
        }

        span {
            font-size: 0.875rem;
            font-weight: 400;
            color: $c-gray;
        }
    }

    p {
        padding-top: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $c-gray;
    }

    .btnAr {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -0.5rem;
        @include iconDropDown($color: '848588');
        @include textHidden;

        &.open {
            transform: rotate(-180deg);
        }
    }

    & + div {
        margin-top: 1.25rem;
    }

    & + button {
        margin-top: 1rem;
    }
}

.titleArea03 {
    margin-bottom: rem(10);

    h2 {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(21);

        &.green {
            color: $c-primary;
        }
    }
}

.fixedTtArea {
    position: sticky;
    top: 0;
    left: 0;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    padding: 0 $InnerPadding;
    background: #fff;

    .titleArea {
        padding: 0.75rem 0;

        h1 {
            span {
                display: inline-block;
                font-size: 1rem;
                vertical-align: middle;
            }
        }

        p {
            padding-top: 0.25rem;
        }
    }

    &.border {
        border-bottom: 1px solid $c-gray-200;
    }
}

@include mobileSmall {
    .titleArea {
        padding: 0.75rem 0 1.25rem;
    }
}

// List Style
.listType01 {
    li {
        position: relative;
        padding-left: 0.3125rem;
        margin-top: 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $c-gray;
        word-break: keep-all;

        &:first-child {
            margin-top: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0.4375rem;
            left: 0;
            width: 0.125rem;
            height: 0.125rem;
            border-radius: 100%;
            background: $c-gray;
        }
    }

    &.fz13 {
        li {
            font-size: 0.8125rem;
        }
    }
}

.listType02 {
    li {
        position: relative;
        padding-left: 1rem;
        margin-top: 0.25rem;
        font-size: 0.8125rem;
        line-height: 1.1875rem;
        color: $c-gray;
        word-break: keep-all;

        &:first-child {
            margin-top: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0.5625rem;
            left: 0.375rem;
            width: 2px;
            height: 2px;
            border-radius: 100%;
            background: $c-gray;
        }
    }
}

.listType03 {
    li {
        position: relative;
        padding-left: 10px;
        margin-top: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: $c-gray;
        word-break: keep-all;

        &:first-child {
            margin-top: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0.5625rem;
            left: 0;
            width: 3px;
            height: 3px;
            border-radius: 100%;
            background: $c-gray;
        }
    }
}

.listType04 {
    li {
        position: relative;
        padding-left: 14px;
        margin-left: 10px;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $c-gray;
        word-break: keep-all;

        &:first-child {
            margin-top: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0.5625rem;
            left: 0;
            width: 3px;
            height: 3px;
            border-radius: 100%;
            background: $c-gray;
        }
    }
}

.listType05 {
    margin-top: rem(20);
    border-top: 4px solid $c-0;

    li {
        border-bottom: $border-gray-opacity;

        a {
            position: relative;
            display: block;
            padding: rem(22) 0;
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(19);
            color: $c-0;

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                @include iconGoArrow($size: 12px);
            }
        }
    }
}

.listType06 {
    li {
        position: relative;
        padding-left: 10px;
        @include font-light02;
        word-break: keep-all;

        &::before {
            content: '-';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.listType07 {
    li {
        position: relative;
        padding-left: 10px;
        @include font-light02;
        word-break: keep-all;
        font-size: rem(12);
        //line-height: rem(16);
        margin-bottom: 4px;
        color: #777f8a;
        word-break: keep-all;

        &::before {
            content: '*';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.listType08 {
    li {
        border-bottom: $border-gray-opacity;
        display: flex;
        justify-content: space-between;
        padding: rem(16) 0;

        div {
            font-size: rem(16);
            line-height: rem(19);
            color: $c-0;
        }
        .address {
            color: $c-gray;
            font-size: rem(14);
        }
    }
}

.selectedList {
    margin-top: 1rem;
    padding: 0.75rem 0.75rem;
    background: #eaf5f5;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;

    span {
        position: relative;
        display: block;
        margin-top: 0.25rem;
        padding-left: 1.5rem;
        word-break: keep-all;

        &:first-child {
            margin-top: 0;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            @include iconCheck($size: 1.25rem, $color: '3906C3');
        }

        &.memberList {
            margin-top: 0.75rem;

            &:first-child {
                margin-top: 0.25rem;
            }
        }

        .member {
            display: inline-block;
            padding: 0.25rem 0.5rem;
            margin-right: 0.25rem;
            margin-top: -0.3125rem;
            border: 1px solid $c-c4;
            border-radius: 0.875rem;
            font-weight: 400;
            vertical-align: top;
        }
    }
}

// Thumb List Style
@mixin prdInfo {
    .prdInfo {
        .tit {
            display: block;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1875rem;
        }

        .location {
            display: block;
            padding-top: 0.25rem;
            font-size: 0.8125rem;
            line-height: 1rem;
        }

        p {
            padding-top: 0.5rem;
            font-size: 0.875rem;
            color: #848588;
            line-height: 1.0625rem;
        }

        .price {
            display: block;
            padding-top: 1rem;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1875rem;
        }
    }
}

.thumbListType01 {
    @include prdInfo;

    .swiper {
        padding: 0 $InnerPadding;

        .swiper-slide {
            width: 84vw;
            max-width: 350px;

            & > a {
                display: block;
                width: 100%;

                img {
                    width: 100%;
                }
            }

            &:first-child:last-child {
                width: 100%;
            }
        }

        .thumbArea {
            position: relative;
            margin-bottom: 1.125rem;
            border-radius: 0.5rem;
            overflow: hidden;

            img,
            .noImg,
            .skeleton {
                height: 48.5vw;
                max-height: 200px;
                object-fit: cover;
            }
        }

        .prdInfo {
            margin-top: 1.125rem;
        }
    }
}

// Tab Style
.tabArea {
    margin-bottom: 1.25rem;
}

#outdoorTabType01 {
    border-top: $border-gray;
    background: rgba(240, 241, 244, 0.4);
    position: sticky;
    top: 56px;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 99;
    margin-top: rem(20);

    ul {
        padding: 0;
        width: 100%;
        display: flex;
        /*overflow-x: auto;*/
        background: rgba(240, 241, 244, 0.4);

        .border-line {
            width: 1px;
            height: 16px;
            border: rgba(215, 217, 226, 0.5);
        }

        .tab-border-line {
            height: rem(18);
            width: rem(1);
            margin-top: rem(15);
            margin-bottom: rem(15);
            border-right: 1px solid rgba(215, 217, 226, 0.5);
            border-color: rgba(215, 217, 226, 0.5);
        }

        li:first-child {
            a,
            button {
                border-left: none !important;
                border-radius: 0px 6px 0px 0px !important;
            }

            &.on {
                a,
                button {
                    &:before {
                        border: none !important;
                    }
                }
            }
        }

        li:last-child {
            a,
            button {
                border-right: none !important;
                border-radius: 6px 0px 0px 0px !important;
            }

            &.on {
                a,
                button {
                    &:after {
                        border: none !important;
                    }
                }
            }
        }

        li {
            border-bottom: 1px solid $c-primary;
            white-space: nowrap;
            width: 100%;
            background: rgba(240, 241, 244, 0.4);

            &:first-child {
                button {
                    &:before {
                        display: none;
                    }
                }
            }

            &:last-child {
                button {
                    &:after {
                        display: none;
                    }
                }
            }

            a,
            button {
                position: relative;
                display: block;
                width: 100%;
                padding: rem(14) rem(15) rem(13);
                font-size: rem(16);
                font-weight: 400;

                color: $c-gray;
                font-weight: 400;
                line-height: rem(19);
            }

            &.on {
                background: rgba(240, 241, 244, 0.4);
                border-bottom: none;
                border-right: none;

                a,
                button {
                    box-sizing: border-box !important;
                    position: relative;
                    font-weight: 700;
                    color: $c-primary;
                    border-radius: 6px 6px 0px 0px;
                    border: 1px solid #3906c3;
                    border-bottom: none;

                    display: block;
                    width: 100%;
                    padding: rem(13) rem(15) rem(14);
                    font-size: rem(16);
                    line-height: 1.3125rem;
                    background: $c-f;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;

                        height: 10px;
                        width: 20px;

                        bottom: 0;
                    }

                    &:after {
                        right: -20px;

                        border-radius: 0 0 0 10px;
                        -moz-border-radius: 0 0 0 10px;
                        -webkit-border-radius: 0 0 0 10px;

                        -webkit-box-shadow: -10px 0 0 0 #fff;
                        box-shadow: -10px 0 0 0 #fff;
                        border-left: 1px solid $c-primary;
                        border-bottom: 1px solid $c-primary;
                    }

                    &:before {
                        left: -20px;

                        border-radius: 0 0 10px 0;
                        -moz-border-radius: 0 0 10px 0;
                        -webkit-border-radius: 0 0 10px 0;

                        -webkit-box-shadow: 10px 0 0 0 #fff;
                        box-shadow: 10px 0 0 0 #fff;
                        border-right: 1px solid $c-primary;
                        border-bottom: 1px solid $c-primary;
                    }

                    /*&::after {
                        background: $c-f;
                        border-bottom: none;
                    }*/
                }
            }
        }

        li {
            &.on {
                border-right: none !important;
            }

            &:after {
                content: '';
                position: absolute;

                height: 18px;
                width: 1px;

                border-right: 1px solid rgba(215, 217, 226, 0.5);
                top: rem(15);
                bottom: rem(15);
            }

            &.border-none {
                &:after {
                    display: none;
                }
            }

            &:first-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.tabType01 {
    position: sticky;
    top: rem(56);
    left: 0;
    width: 100%;
    background: #fff;
    border-bottom: $border-gray;
    z-index: 98;

    ul {
        width: 100%;
        display: flex;
        padding: 0 $InnerPadding;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            white-space: nowrap;
            width: 100%;

            a,
            button {
                position: relative;
                display: block;
                width: 100%;
                padding: rem(15) rem(15) rem(12);
                font-size: rem(16);
                font-weight: 400;
                line-height: 1.3125rem;
            }

            &.on {
                a,
                button {
                    font-weight: 700;
                    color: $c-primary;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 0.125rem;
                        background: $c-primary;
                    }
                }
            }
        }
    }
}

@include mobileSmall {
    #outdoorTabType01 {
        top: 3.375rem !important;
    }
}

.tabType02 {
    button {
        margin-left: 0.375rem;
        padding: rem(8) rem(12);
        border: 1px solid #dcdddd;
        font-size: 0.9375rem;
        color: rgba(33, 33, 33, 0.7);
        line-height: 1.125rem;
        border-radius: 3.125rem;

        &.active {
            background: $c-primary;
            border-color: $c-primary;
            color: $c-f;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.tabType03 {
    margin-bottom: 1.25rem;

    [class*='MuiTab-root'] {
        min-height: 2.25rem;
        padding: 0px !important;
        border: 1px solid $c-gray-200;
        border-left: none;
        font-size: 0.875rem;

        &:first-child {
            border-left: 1px solid $c-gray-200;
            border-radius: 0.5rem 0px 0px 0.5rem;
        }

        &:last-child {
            border-radius: 0px 0.5rem 0.5rem 0px;
        }

        &.Mui-selected {
            background: #eaf5f5;
            border-color: #eaf5f5;
            color: $c-primary !important;
            font-weight: 700 !important;
        }
    }

    .MuiTabs-indicator {
        display: none !important;
    }
}

// scrollArea
.scrollArea {
    padding: 0 1.5rem 1px;
    white-space: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .inner & {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
    }
}

.scrollArea02 {
    overflow-y: auto;
}

// section
.section {
    padding: 1.25rem $InnerPadding;

    &.line {
        border-top: 1px solid $c-gray-200;
    }

    .inner > & {
        padding-left: 0;
        padding-right: 0;
    }

    .titleArea {
        padding-top: 0;
        margin-top: -0.5rem;
    }

    .btnArea {
        margin-top: 1.5rem;
    }

    .innerSection {
        margin-top: 0.75rem;
    }

    .borderSection {
        margin-top: rem(12);
        padding-top: rem(12);
        border-top: $border-gray;

        &:first-child {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
        }
    }

    .tooltipFlexCon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.375rem !important;

        .cautionTxt {
            margin-bottom: 0px !important;
        }
    }
}

.section02 {
    padding: rem(30) $InnerPadding;
}

.lineSection {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.infoTxtCon {
    @include font-light02;

    p {
        word-break: keep-all;
        white-space: pre-line;
    }
}

.dropDownArea {
    &.open {
        display: block;
    }

    &.close {
        display: none;
    }
}

.cautionTxt {
    position: relative;
    padding-left: 1.125rem;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $c-gray;
    word-break: keep-all;

    &::before {
        position: absolute;
        top: 0.1875rem;
        left: 0;
        @include iconCaution($size: 0.875rem);
    }
}

.cautionTxt02 {
    position: relative;
    padding-left: 1.125rem;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $c-gray;
    word-break: keep-all;

    &::before {
        position: absolute;
        top: 0.1875rem;
        left: 0;
        @include iconCaution($size: 0.875rem);
    }
}

.cautionTxt03 {
    position: relative;
    padding-left: 1.125rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $c-gray;

    &::before {
        position: absolute;
        top: 0.1875rem;
        left: 0;
        @include iconCaution02;
        margin-top: -0.125rem;
    }
}

// Information Style
.infoType01 {
    dl {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.0625rem;

        &:first-child {
            margin-top: 0;
        }

        &.defaultTxt {
            font-size: 1rem !important;
        }

        &.discount {
            dd {
                color: $c-primary;
            }
        }

        dt {
            .btnTooltip {
                margin-top: -2px;
                vertical-align: middle;
            }
        }

        dd {
            text-align: right;
        }

        span {
            display: block;
            padding-top: 0.125rem;
            font-size: 0.875rem;
            font-weight: 400 !important;
            color: $c-gray !important;
            line-height: 1.25rem;

            &.inline {
                display: inline;
            }
        }

        .defaultTooltip {
            .tooltipContainer {
                position: relative;
                left: 1.5rem;
            }
        }
    }

    .totalArea {
        margin-bottom: 1rem;
        padding-bottom: 0.625rem;
        border-bottom: 1px solid #ebebeb;

        dl {
            align-items: center;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1875rem;
        }
    }

    .totalPayment {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid #212121;

        dl {
            font-weight: 700;
            color: $c-primary;

            dt {
                font-size: 1rem;
            }

            dd {
                font-size: 1.25rem;
            }

            span {
                padding-top: 0.375rem;
            }

            &.black {
                color: $c-21;
            }
        }
    }
}

.infoType02 {
    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.875rem 0;
        border-top: 1px solid $c-gray-200;

        &:first-child {
            padding-top: 0;
            border-top: none;
        }

        &:last-child {
            padding-bottom: 0;
        }

        dl {
            dt {
                margin-bottom: 0.1875rem;
                font-size: 0.75rem;
                line-height: 20px;
                color: $c-gray;
            }

            dd {
                font-size: 1rem;
                line-height: 1.1875rem;
            }
        }

        .tt {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25rem;
        }

        .btnArea {
            margin-top: 0 !important;

            a,
            button {
                font-size: 0.875rem;
            }
        }
    }
}

.infoType03 {
    border-top: 1px solid $c-21;
    border-bottom: 1px solid $c-21;

    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.875rem 0;
        border-top: 1px solid $c-gray-200;

        &:first-child {
            border-top: none;
        }

        &.nodata {
            height: 50vh;
            align-items: center;
            justify-content: center;
        }

        dl {
            width: 100%;

            dt {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.5rem;
                font-size: 1rem;
                line-height: 1.25rem;

                .date {
                    font-size: 0.75rem;
                    line-height: 20px;
                    color: $c-gray;
                }
            }

            dd {
                margin-top: 0.25rem;
                font-size: 0.8125rem;
                // line-height: 1.125rem;
            }
        }

        .green {
            color: $c-primary;
        }

        .red {
            color: $c-red;
        }
    }
}

.infoType04 {
    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: rem(52);

        dl {
            display: flex;
            justify-content: space-between;
            width: 100%;

            dt {
                flex-shrink: 0;
                width: 6.125rem;
                font-size: 1rem;
                font-weight: 600;
                color: $c-0;
            }

            dd {
                font-size: 1rem;
                text-align: right;
                color: $c-gray-02;

                &.mail {
                    width: calc(100% - 6.125rem) !important;
                    @include textEllipsis;
                }
            }
        }

        .tt {
            font-size: 1rem;
            font-weight: 600;
            color: $c-0;
        }

        .btnArea {
            flex-shrink: 0;
            margin-top: 0 !important;
            margin-left: rem(10);

            a,
            button {
                width: rem(47);
                font-size: rem(12);
            }
        }
    }
    .promotion-apply {
        margin-top: rem(0);
        width: 100%;

        .discountTxt {
            color: #ff4d6f;
            font-size: rem(16);
            font-weight: 700;
            margin-left: auto;
        }
        dt {
            font-weight: 600;
        }
        dl {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: rem(18) 0;
        }
        &::before {
            display: block;
            content: '';
            width: 100%;
            // height: 0.725rem;
            border-top: 1px solid #d7d9e2;
            opacity: 0.5;
        }
    }
}

.infoType05 {
    dl {
        margin-top: 0.875rem;

        &:first-child {
            margin-top: 0;
        }

        dt {
            font-size: rem(16);
            font-weight: 600;
        }

        dd {
            @include font-light02;
            color: $c-gray-02;
            margin-top: rem(10);

            .address {
                li {
                    margin-top: 2px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            .link {
                @include font-light02;
                color: #3c414a;
                text-decoration: underline;
                text-align: left;
            }
        }
    }
}

.infoType06 {
    dl {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: rem(8);
        @include font-light02;

        &:first-child {
            margin-top: 0;
        }

        &.defaultTxt {
            font-size: 1rem !important;
        }

        &.discount {
            dd {
                color: $c-primary;
            }
        }

        &.deadline {
            dd {
                color: $c-red;
                font-weight: 700;

                &.external {
                    color: black;
                    font-weight: 600;
                }
            }
        }

        &.totalPrice {
            dd {
                color: $c-primary;
                font-weight: 800;
            }
        }

        dt {
            flex-shrink: 0;
            min-width: 100px;
            font-weight: 600;

            span {
                color: $c-gray;
            }

            .btnTooltip {
                margin-left: rem(4);
                margin-top: -4px;
                vertical-align: middle;
            }
        }

        dd {
            text-align: right;

            b {
                font-weight: 800;
                color: $c-primary;
            }

            white-space: pre-line;
        }

        span {
            font-size: 0.875rem;
            font-weight: 400 !important;
            color: $c-gray !important;
        }

        &.totalPayment {
            @include font-light02;
            font-weight: 700;
            color: $c-primary;

            dd {
                font-weight: 800;
            }
        }
    }
}

.infoType07 {
    .tt {
        padding-bottom: rem(10);
        border-bottom: 4px solid #000;
        font-size: rem(18);
        line-height: rem(21);
        font-weight: 600;
    }

    dl {
        display: flex;
        justify-content: space-between;
        margin-top: 0.75rem;

        &:first-child {
            margin-top: 0;
        }

        dt {
            font-weight: 700;
            @include font-light02;
        }

        dd {
            @include font-light02;
            text-align: right;

            ul {
                li {
                    margin-top: 0.75rem;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.foodCatWrap {
    min-height: calc(100vh - 252px);
}

#food-ccDetail-infoBox {
    padding-top: rem(10);
    margin-top: 0;
}

// Information Box Style
.infoBox01 {
    padding: rem(14);
    background: $c-gray-100;
    border-radius: rem(8);

    dl {
        display: flex;
        justify-content: space-between;
        margin-top: rem(5);
        font-size: rem(13);
        line-height: rem(16);
        color: $c-gray;

        &:first-child {
            margin-top: 0;
        }

        dt {
            width: rem(90);
            flex-shrink: 0;
        }

        dd {
            text-align: right;
        }
    }

    & + .InfoFlexCon {
        margin-top: 1.25rem;
    }

    p {
        font-size: rem(12);
        line-height: rem(16);
        word-break: keep-all;
        color: $c-gray;

        &.textIndent {
            display: flex;

            &::before {
                content: '*';
                padding-right: 2px;
            }
        }
    }
}

// border Style
.borderTop {
    padding-top: rem(12) !important;
    margin-top: rem(12) !important;
    border-top: 1px solid $c-0;
}

// tooltip
.tooltipSection {
    margin-top: 1.5rem;
    padding: 1rem;
    background: #f8f8f8;
    border-radius: 0.5rem;

    .tit {
        display: block;
        margin-bottom: 0.375rem;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.125rem;

        &::before {
            margin-top: -2px;
            margin-right: 0.3125rem;
            @include iconTooltipLine($size: 0.875rem);
            vertical-align: middle;
        }
    }
}

// Avatar
.Avatar {
    width: 3.375rem;
    flex-shrink: 0;
    text-align: center;

    .thumbArea {
        max-width: rem(56);
        margin: 0 auto rem(12);
    }

    .profileImg {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        background: #fff;
        border-radius: 100px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid rgba(0, 0, 0, 0.06);
            border-radius: 100%;
            overflow: hidden;

            &.borderNone {
                border: none;
            }
        }

        .nonMember {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.3);
            font-size: 0.6875rem;
            color: $c-f;
            z-index: 5;
            border-radius: 100%;
        }

        .btnDelete {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;

            &::after {
                position: absolute;
                right: -0.125rem;
                bottom: 0;
                @include iconDeleteAvatar;
            }
        }
    }

    .name {
        margin: auto;
        max-width: 100%;
        @include font-light02;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .icon {
            width: 1rem;
            height: 1rem;
            margin-right: 0.25rem;
        }
    }

    .number {
        display: block;
        padding-top: 0.25rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #c4c4c4;
    }

    &.head {
        .thumbArea {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: -5px;
                left: -5px;
                width: calc(100% + 10px);
                height: calc(100% + 10px);
                border: 3px solid $c-orange;
                border-radius: 100%;
            }

            .profileImg {
                img {
                    border: none;
                }

                &::after {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    @include iconHeadAvatar;
                    z-index: 10;
                }
            }

            &.off {
                .profileImg {
                    &::after {
                        @include iconHeadAvatar($color: '000');
                    }
                }
            }
        }
    }
}

.avatarWrap {
    .listCon {
        display: flex;
        justify-content: space-between;

        li {
            min-width: 0;
            flex: 1 1 0;
            margin-left: rem(8);

            &:first-child {
                margin-left: 0 !important;
            }

            .Avatar {
                width: 100%;
            }
        }

        .skeleton-item {
            opacity: 0.5;
        }
    }

    .btnCon {
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        .btnAdd {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include textHidden;
        }
    }
}

// Card List
.cardListWrap {
    .cardList {
        li {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 1.75rem;
            padding-right: 4.375rem;

            &:first-child {
                margin-top: 0.25rem;
            }

            &:last-child {
                margin-bottom: 2rem;
            }

            .cardImg {
                position: relative;
                width: 2.1875rem;
                height: 3.5rem;
                margin-right: 1rem;
                flex-shrink: 0;
                border-radius: 0.25rem;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .cardInfo {
                width: calc(100% - 3.1875rem);

                .name {
                    display: block;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.1875rem;
                    @include textEllipsis;
                }

                span {
                    display: block;
                    margin-top: 0.375rem;
                    font-size: 0.875rem;
                    line-height: 1.0625rem;
                    color: $c-gray;
                    @include textEllipsis;
                }
            }

            .btnDelete {
                position: absolute;
                top: 50%;
                right: 0;
                width: auto;
                padding: 0 0.625rem;
                transform: translateY(-50%);
            }

            &.disabled {
                .cardImg {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.5);
                        z-index: 5;
                    }
                }

                .cardInfo {
                    span {
                        color: $c-c4;
                    }
                }
            }
        }
    }

    .btnAddCard {
        display: block;
        width: 100%;
        background: #fff;
        border: 1px solid $c-primary;
        text-align: center;
        font-size: 1rem;
        color: $c-primary;
        border-radius: 0.5rem;
        line-height: 3.5rem;

        &::before {
            margin-right: 0.5rem;
            margin-top: -2px;
            @include iconAddLine02($size: 0.875rem, $color: '3906C3');
            vertical-align: middle;
        }
    }
}

// 썸네일 공통
.noImg,
.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10.875rem;
    text-align: center;

    p {
        font-size: rem(18);
        line-height: rem(20);
        margin-bottom: rem(12);
    }

    span {
        display: block;
        @include font-light;
        color: $c-gray;
        font-size: rem(14);
        line-height: rem(20);
        //padding-bottom: rem(70);
    }

    .icon {
        display: block !important;
        //margin: rem(58) auto rem(20 );
    }
}

.noImg {
    background: #ebebeb;
    border-radius: 0.5rem;

    span {
        color: $c-f;
    }

    .icon {
        display: block !important;
        margin: 0 auto 0.5rem;
        @include iconNoImg;
        opacity: 0.9;
    }

    .txtCon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0.7;
    }
}

.noImgLogo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: url('./assets/images/common/noImg_bg.jpg') no-repeat center center;
    background-size: cover;
    border-radius: 0;

    img {
        position: relative;
        position: unset !important;
        width: auto;
        height: auto !important;
        z-index: 10;
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
    }
}

.noData {
    .icon {
        //@include iconMenu($size: 2.125rem, $color: 'ccc');
    }
}

.noDataList {
    position: relative;
    padding: 30vh 0;
    text-align: center;
    font-size: rem(14);
    line-height: rem(28);
    color: $c-0;

    .icon {
        display: block;
        margin-bottom: rem(20);

        svg {
            vertical-align: top;
        }
    }

    .bigTxt,
    p {
        font-size: rem(18);
        line-height: rem(24);
    }

    span {
        display: block;
        margin-top: rem(12);
        @include font-light();
        color: $c-gray;
    }

    .btnArea {
        text-align: center;
        margin-top: rem(16);

        a,
        button {
            display: inline-block;
            vertical-align: top;
            width: auto;
            padding: 0 rem(20);
            margin-left: rem(10);

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.loadingArea {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .txt {
        margin-top: rem(30);
        font-size: rem(24);
        font-weight: 600;
        line-height: rem(28);
        color: $c-0;
    }
}

.food-ccDetail-contents {
    min-height: auto !important;
}

#foodOrder {
    .food-ccDetail {
        position: sticky;
        top: 6.45rem !important;
        border-bottom: 1px solid rgba(215, 217, 227, 0.5);

        .foodresList-category-scroll {
            white-space: nowrap;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        ul {
            width: auto !important;

            li {
                button {
                    padding-top: rem(14);
                    padding-bottom: rem(13);
                    /* padding: rem(14) 0 rem(13) 0;*/
                }
            }
        }
    }

    .tabType01 {
        top: rem(56);
        z-index: 98;
    }

    .titleArea {
        padding: 0px 0px 1rem !important;
    }

    #foodCategory {
        padding-top: 0px !important;
        margin-top: 0px !important;

        .noData {
            height: 70vh;
        }

        .food-ccDetail-category {
            position: sticky;
            top: 9.5rem;
        }
    }
}

// 완료 페이지
.completeArea {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 22%;

    .inner {
        width: 100%;
    }

    .completeMsg {
        position: relative;
        font-size: rem(24);
        font-weight: 600;
        line-height: rem(28);
        white-space: pre-line;

        .name {
            color: #3906c3;
        }

        .icon {
            display: block;
            margin-bottom: rem(30);

            svg {
                vertical-align: top;
            }
        }
    }

    .txt {
        font-weight: 400;
        margin-top: rem(12);
        @include font-light;
        color: $c-gray;
    }
}

// 정산/결제
#sharePayment {
    position: relative;

    .agreeCheck {
        label {
            display: flex;
            font-size: 0.875rem;
            line-height: 1.25rem;

            span {
                display: block;
                color: $c-gray;
            }
        }
    }

    .btnArea {
        padding: 0.5rem $InnerPadding;
        background: #fff;
        border-top: 1px solid #eee;
    }
}

.cartSliderWrap {
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
    margin-top: 0.625rem;
    margin-bottom: 1rem;
    border-top: 1px solid $c-gray-200;
    border-bottom: 1px solid $c-gray-200;

    .swiper-slide {
        width: 70vw;
        max-width: 270px;
    }

    .swiper-pagination {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
        text-align: center;
        z-index: 10;

        .swiper-pagination-bullet {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-left: 0.25rem;
            background: #e0e0e0;
            border-radius: 25px;
            transition: all 0.3s ease;

            &:first-child {
                margin-left: 0;
            }

            &.swiper-pagination-bullet-active {
                width: 18px;
                background: $c-gray;
            }
        }
    }
}

.shareDetailTbl {
    $tbl-padding: 0.625rem 0.5rem;

    .shareTbl {
        width: 100%;
        border-top: 1px solid $c-21;

        thead {
            th {
                padding: $tbl-padding;
                background: $c-f8;
                border-bottom: 1px solid $c-gray-200;
                border-left: 1px solid $c-gray-200;
                text-align: left;

                &:first-child {
                    border-left: none;
                    padding-left: 0.375rem;
                    padding-right: 0.375rem;
                }

                b {
                    display: block;
                    font-size: 0.8125rem;
                    font-weight: 700;
                    line-height: 1rem;

                    .btnTooltip {
                        vertical-align: top;
                    }

                    .tooltipBox {
                        padding: 0.75rem 1rem;
                    }
                }

                span {
                    display: block;
                    padding-top: 0.25rem;
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: 0.875rem;
                    color: $c-gray;
                }
            }
        }

        tbody {
            th {
                padding: 0.625rem 0.375rem;
                text-align: left;
                border-bottom: 1px solid $c-gray-200;

                .name {
                    display: block;
                    font-size: 0.875rem;
                    font-weight: 700;
                    line-height: 1.0625rem;

                    &.host {
                        display: inline-block;
                        padding: 0.25rem 0.5rem;
                        background: rgba(137, 234, 103, 0.12);
                        border-radius: 1.25rem;
                        color: $c-primary;
                        vertical-align: top;
                    }
                }

                span {
                    display: block;
                    padding-top: 0.125rem;
                    font-size: 0.8125rem;
                    font-weight: 400;
                    line-height: 1rem;
                    color: $c-gray;
                }
            }

            td {
                padding: $tbl-padding;
                border-bottom: 1px solid $c-gray-200;
                border-left: 1px solid $c-gray-200;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                text-align: left;
                vertical-align: middle;
                letter-spacing: -0.02em;

                b {
                    font-weight: 700;
                }
            }

            tr {
                &.me {
                    td {
                        font-weight: 700;
                    }
                }

                &.allPayed {
                    th,
                    td {
                        background: rgba(255, 252, 237, 0.4);
                    }

                    th {
                        .name {
                            white-space: nowrap;

                            &::after {
                                content: '';
                                display: inline-block;
                                width: 0.875rem;
                                height: 0.875rem;
                                margin-left: 0.125rem;
                                margin-top: -0.25rem;
                                background: url('./assets/images/common/icon_payed.png') no-repeat center center;
                                background-size: 100%;
                                vertical-align: middle;
                            }
                        }
                    }

                    td {
                        font-weight: 700;
                        color: $c-blue !important;
                    }
                }

                &.disabled {
                    opacity: 0.5;

                    td {
                        font-weight: 400 !important;
                    }
                }
            }
        }
    }

    .statusInfo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0.625rem;
        font-size: 0.75rem;
        color: $c-gray;
        line-height: 0.875rem;

        span {
            margin-left: 1rem;

            &:first-child {
                margin-left: 0;
            }

            &::before {
                content: '';
                display: inline-block;
                width: 0.5rem;
                height: 0.5rem;
                margin-right: 0.25rem;
                border-radius: 100%;
            }

            &.host {
                &::before {
                    background: $c-primary;
                }
            }

            &.advance {
                &::before {
                    background: $c-blue;
                }
            }
        }
    }
}

.dropDownList {
    &:first-child {
        margin-top: 0;
    }

    .btnTitle {
        position: relative;
        display: block;
        width: 100%;
        padding: 1rem 0;
        border-bottom: 1px solid $c-gray-200;
        font-size: 1rem;
        text-align: left;
        line-height: 1.1875rem;
    }

    .dropDownCon {
        .labelList {
            margin-top: 1rem !important;

            &:first-child {
                margin-top: 1.25rem !important;
            }
        }
    }
}

[class*='MuiAccordion-root'] {
    padding: 0;
    box-shadow: none !important;
    border-radius: 0px !important;
    margin: 0.5rem 0 0;

    &::before {
        display: none !important;
    }

    &.Mui-expanded {
        margin: 0 !important;
    }

    .Mui-expanded {
        min-height: auto !important;
        padding: 0;
        margin: 0;
    }

    .MuiAccordionSummary-content {
        position: relative;
        margin: 0;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -0.5rem;
            @include iconDropDown($color: '848588');
            @include textHidden;
        }

        &.Mui-expanded {
            margin: 0;
            padding: 1rem 0;

            &::after {
                transform: rotate(-180deg);
            }
        }
    }

    .MuiAccordionDetails-root {
        padding: 0;
    }
}

// footer
.btnFloatingWrap {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 1.5rem;
    bottom: 6.25rem;
    z-index: 100;
    overflow: hidden;

    .btnFloating {
        height: 3rem;
        padding: 0.875rem 1rem;
        background-color: $c-primary;
        border-radius: 30px;
        overflow: hidden;
        cursor: pointer;
        animation: spreadWidth 0.4s;
        animation-fill-mode: forwards;

        .floatingCon {
            display: flex;
            align-items: center;
            position: relative;
            top: -0.125rem;
            padding-bottom: 0.1875rem;
            font-size: 0.75rem;
            font-weight: 300;
            color: $c-f;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 2px;
                bottom: 0;
                width: calc(100% - 2px);
                height: 1px;
                border-radius: 1px;
                background: $c-f;
                transition: width 0.4s ease-in;
            }

            i {
                flex-shrink: 0;
                width: 1.25rem;
                height: 1.25rem;
                background: url('./assets/images/common/icon_floating.png') no-repeat center center;
                background-size: 98%;
                vertical-align: middle;
                animation: rotateLeft 0.4s;
                animation-fill-mode: forwards;
            }

            .txt {
                max-width: 65vw;
                margin-left: 0.375rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &.fold {
            padding: 0.875rem;
            animation: foldWidth 0.4s;
            animation-fill-mode: forwards;

            .floatingCon {
                &:after {
                    width: calc(100% - 4px);
                }

                i {
                    animation: rotateRight 0.4s;
                    animation-fill-mode: forwards;
                }
            }
        }

        @keyframes spreadWidth {
            from {
                width: 3rem;
            }

            to {
                width: 100%;
            }
        }

        @keyframes foldWidth {
            from {
                width: 100%;
            }

            to {
                width: 3rem;
            }
        }

        @keyframes rotateRight {
            from {
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            to {
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @keyframes rotateLeft {
            from {
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }

            to {
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }
        }
    }
}

#Navigation {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    background: $c-0;
    z-index: 100;

    a {
        display: block;
        flex: 1 1 0;
        text-align: center;
        padding: rem(10) 0 rem(12);
        font-size: rem(10);
        font-weight: 500;
        color: $c-f;
        opacity: 0.6;

        i {
            display: block;

            &.home,
            &.main {
                @include iconHome02;
            }

            &.search {
                @include iconSearch;
            }

            &.smartRound {
                @include iconSmartRound;
                background-size: 2.375rem;
                background-position: -1.5px center;
            }

            &.mypage {
                @include iconMypage;
            }

            &.checkin {
                @include iconCheckIn;
            }

            &.courseguide {
                @include iconCourseGuide;
            }

            &.food {
                @include iconMenu;
            }

            &.payment {
                @include iconPayment;
            }
        }

        span {
            display: block;
            padding-top: rem(4);
            line-height: rem(12);
        }

        &.mypage {
            opacity: 1;

            span {
                opacity: 0.6;
            }

            &.on {
                span {
                    opacity: 1;
                }
            }
        }

        &.on {
            opacity: 1;

            i {
                &.home,
                &.main {
                    @include iconHome02_on;
                }

                &.smartRound {
                    @include iconSmartRound_on;
                    background-size: 2.375rem;
                    background-position: -1.5px center;
                }

                &.mypage {
                    @include iconMypage_on;
                }

                &.checkin {
                    @include iconCheckIn($color: $c-f);
                }

                &.courseguide {
                    @include iconCourseGuide_on;
                }

                &.food {
                    @include iconMenu_on;
                }

                &.payment {
                    @include iconPayment($color: $c-f);
                }
            }
        }
    }
}

// 이용약관, 개인정보 수집 동의
#agreeContents {
    .agreeCon {
        color: $c-gray;

        p,
        ol {
            color: $c-gray;
            font-size: rem(16);
            line-height: rem(24);
            word-break: keep-all;
        }

        section {
            margin-bottom: rem(30);
            line-height: rem(24);
        }

        h2,
        h3 {
            margin-bottom: 0.375rem;
            color: $c-21;
            font-size: 1.0625rem;
            font-weight: 600;
        }

        .agreeInfoTb {
            width: 100%;
            margin-top: 0.5rem;
            border-top: 1px solid $c-21;
            border-bottom: 1px solid $c-gray-200;

            .agreeInfoTr {
                td {
                    text-align: center;
                }
            }

            thead {
                th {
                    padding: 0.5rem 0.25rem;
                    background: #f8f8f8;
                    border-bottom: 1px solid $c-gray-200;
                    font-weight: 400;
                    text-align: center;
                    @include font-light;
                }
            }

            tbody {
                tr {
                    td {
                        padding: 0.5rem 0.25rem;
                        border-top: 1px solid $c-gray-200;
                        border-left: 1px solid $c-gray-200;
                        text-align: left;
                        color: $c-gray;
                        @include font-light;
                        word-break: keep-all;

                        &:first-child {
                            border-left: none;
                        }

                        &.bdl {
                            border-left: 1px solid $c-gray-200;
                        }
                    }

                    &:first-child {
                        td {
                            border-top: none;
                        }
                    }
                }
            }

            & + p {
                margin-top: 0.5rem;
            }
        }

        table {
            th,
            td {
                font-size: rem(14);
                line-height: rem(20);
            }
        }

        .smallTxt {
            @include font-xlight;
        }

        .circleNum {
            font-family: 'Roboto';
        }

        .underLine {
            color: $c-21;
            border-bottom: 1px solid $c-21;
        }

        a[class*='MuiLink'] {
            color: $c-gray !important;
        }
    }
}

.agreeCheckLabel {
    margin-bottom: rem(24);

    & > label {
        display: flex;
        padding: 0.75rem 0;
        cursor: pointer;

        .customCheck {
            .icon {
                margin-right: rem(8);
            }
        }

        p {
            padding-top: rem(2);
            font-size: rem(16);
            line-height: rem(19);
            font-weight: 600;
            color: $c-0;
        }
    }
}

// 앱 이용 권한 안내
#appPermission {
    overflow-y: auto;

    .infoCon {
        padding: 1.5625rem 0;
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;

        section {
            margin-top: 1.5625rem;

            &:first-child {
                margin-top: 0;
            }

            h2 {
                margin-bottom: 0.375rem;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.25rem;
            }

            p {
                font-size: 1rem;
                font-weight: 300;
                line-height: 1.25rem;
                color: $c-gray;
            }

            .prmList {
                margin-top: 0.875rem;

                li {
                    display: flex;
                    align-items: center;
                    margin-top: 0.625rem;
                    line-height: 1.0625rem;

                    .icon {
                        flex-shrink: 0;
                        width: 2.875rem;
                        height: 2.875rem;
                        background: #f0f0f0;
                        text-align: center;
                        border-radius: 100%;

                        svg {
                            width: 1.5rem;
                        }
                    }

                    .txtArea {
                        padding-left: 1rem;

                        strong {
                            display: block;
                            font-size: 0.875rem;
                            font-weight: 400;
                        }

                        span {
                            display: block;
                            padding-top: 0.125rem;
                            font-size: 0.8125rem;
                            color: $c-gray;
                        }
                    }
                }
            }
        }
    }

    .agreeInfo {
        margin-top: 0.9375rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $c-gray;
    }
}

@include mobileSmall {
    #appPermission {
        .infoCon {
            padding: 1.25rem 0;
        }
    }
}

// 인트로 동영상
.videoPlayerArea {
    position: relative;
    width: 100vw;
    height: 100%;
    overflow: hidden;

    .fullScreenPlayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        z-index: 0;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto !important;
            height: auto !important;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .autoScreenPlayer {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 100;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
        }

        .videoPlayer {
            position: relative;
            display: inline-block;
            width: auto !important;

            video {
                width: auto !important;
                height: 130% !important;
                margin-top: -15%;
            }
        }
    }

    .introTxtArea {
        position: absolute;
        top: 0;
        left: 50%;
        width: 63vh;
        width: calc(var(--vh) * 63);
        max-width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        text-align: center;
        color: $c-f;
        z-index: 150;

        .txtCon {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 30% $InnerPadding 20%;

            .title {
                opacity: 0;
                padding-bottom: 0.625rem;
                font-size: 1.125rem;
                line-height: 1.3125rem;
                animation: slideUp 1s ease 0.5s forwards;
            }

            .golfClubName {
                opacity: 0;
                display: block;
                font-size: rem(32);
                font-weight: 700;
                line-height: rem(38);
                animation: slideUp 1s ease 1s forwards;
            }

            .bookingInfo {
                opacity: 0;
                padding-top: rem(14);
                animation: slideUp 1s ease 1.5s forwards;
                color: rgba(255, 255, 255, 0.7);
                @include font-light02;

                .date {
                    padding-bottom: rem(6);
                }

                .location {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: inline-block;
                        position: relative;
                        padding-left: 9px;
                        margin-left: 8px;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            width: 1px;
                            height: rem(10);
                            background: rgba(255, 255, 255, 0.7);
                        }

                        &:first-child {
                            padding-left: 0;
                            margin-left: 0;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }

            .btnSkip {
                opacity: 0;
                display: flex;
                width: rem(140);
                padding: rem(14) 0;
                line-height: 1.1875rem;
                margin: 0 auto;
                background: $c-f;
                font-size: rem(16);
                font-weight: 600;
                text-align: center;
                color: #000;
                border-radius: 40px;
                vertical-align: top;
                animation: fadeIn 1s ease 2s forwards;
                color: $c-primary;
                align-items: center;
                justify-content: center;
                width: 100%;
                .btn_ar {
                    @include textHidden;
                    @include iconGoArrow($size: 1.5rem, $color: '3906c3');
                    background-size: 7px;
                }
            }

            @keyframes slideUp {
                0% {
                    opacity: 0;
                    transform: translateY(30px);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }
}

.datepicker-outdoor-bg {
    background: #ffffff !important;

    border-bottom: 1px solid rgba(215, 217, 227, 0.5);
    margin-bottom: rem(6);
}

.datePickerWrap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0.625rem 0;
    background: #fbfbfc;
    overflow: hidden;

    * {
        font-family: 'Pretendard', 'Roboto', -apple-system, BlinkMacSystemFont, 'Malgun Gothic', '맑은 고딕', helvetica,
            'Apple SD Gothic Neo', sans-serif;
    }

    .btnDate {
        position: relative;
        width: 3.25rem;
        flex-shrink: 0;
        margin-left: $InnerPadding;
        margin-right: 0.625rem;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.3125rem;
        text-align: left;

        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            @include iconDropDown02;
            margin-left: 0.75rem;
        }
    }

    .dateSlider {
        width: 100%;
        overflow: hidden;

        & > div {
            padding: 0 !important;

            button {
                display: none !important;
            }
        }

        .jb {
            display: none !important;
        }

        .Tx {
            margin-top: 0 !important;
        }

        .day {
            margin: 4px 0 2px;
            line-height: 14px;
            opacity: 0.5;

            &.red {
                color: $c-red;
            }
        }

        .date {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            font-size: 1.125rem;

            &.red {
                color: $c-red;
            }
        }

        .dayList {
            min-width: 34px;
            min-height: auto;
            padding: 0 6px;
            margin: 0;

            &:hover {
                background-color: transparent;
            }

            &.weekend {
                color: $c-blue !important;

                .day {
                    color: $c-blue;
                }

                & + .weekend {
                    color: $c-red !important;

                    .day {
                        color: $c-red;
                    }
                }

                .red {
                    color: $c-red;
                }
            }
        }

        .dayOn {
            background: none;

            .day {
                color: $c-primary;
                opacity: 0.7;

                &.red {
                    color: $c-red;
                }
            }

            .date {
                background: $c-primary;
                border-radius: 100%;
                color: $c-f !important;
            }
        }
    }
}

.button-container{
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    button{
        width: inherit;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid black;
        background : #fff;
        color: black;
    }
    button.active{
        background: black;
        color: #fff;
    }
}
