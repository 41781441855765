@import '../../assets/styles/scss/help';

.smartRoundGuideBg {
    background: url('../../assets//images/smart-round/smartRoundBg.png') no-repeat center center;
    background-size: cover;
}

#smartRoundGuide {
    #header {
        border-bottom: none !important;

        .pageTitle {
            color: #fff;
        }

        .btnBack {
            background-size: 1rem 1rem !important;
            @include iconBack($color: 'fff');
        }

        &.fixed {
            background-color: rgba(0, 0, 0, 0.8);
            backdrop-filter: blur(17px);
        }
    }

    .contentsArea {
        padding-bottom: 0 !important;
    }

    .ttArea {
        margin-bottom: rem(20);
        color: $c-f;

        h2 {
            font-size: rem(24);
            height: rem(29);
            font-weight: 700;
        }

        p {
            padding-top: rem(4);
            font-size: rem(14);
            line-height: rem(20);
            opacity: 0.7;
        }
    }

    .section {
        &+.section {
            margin-top: rem(20);
        }
    }

    .guideCon {
        position: relative;
        padding-bottom: 28px;
    }

    .guideSwiperWrap {
        background: $c-f;
        border-radius: rem(18);
        overflow: hidden;

        .guideTxt {
            margin-bottom: rem(30);

            .tit {
                display: block;
                margin-bottom: rem(8);
                font-size: rem(22);
                font-weight: rem(28);
            }

            p {
                font-size: rem(14);
                line-height: rem(20);
                color: $c-gray-02;
            }
        }

        img {
            position: relative;
            display: block;
            width: rem(192);
            max-width: 85%;
            margin: 0 auto;
            opacity: 0;
            transition: all 0.3s ease-in;
            transform: translateY(50%);
        }

        .list {
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: $InnerPadding $InnerPadding 0;
            overflow: hidden;

            &.swiper-slide-active {
                img {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        &.swiperActive-0 {
            background: linear-gradient(180deg, #e6ebf9 84.45%, #ffffff 100%);
        }

        &.swiperActive-1 {
            background: #ebf8f5;
        }

        &.swiperActive-2 {
            background: #f2edfd;
        }


    }

    // 예약정보 불러오기
    .callMyReserve {
        background: #C5D6F4;
        border-radius: rem(18);
        padding: 1.5rem;

        .guideTxt {
            .titWrap {
                margin-bottom: 0.5rem;

                .plusIcon {
                    opacity: initial;
                    margin-right: 0.5rem;
                    width: 1.5rem;
                    height: 1.75rem;
                }

                .tit {
                    color: #3906C3;
                    font-size: 1.375rem;
                    font-weight: 700;
                    line-height: 1.75rem;
                    margin-bottom: 0;
                }
            }


            p {
                color: #191F29;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }

        }

        .imgWrap {
            text-align: center;
        }

        .btnArea {
            margin-top: 0;
        }
    }

    .swiper-pagination-bullets {
        position: absolute;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        text-align: center;
        //z-index: 10;

        .swiper-pagination-bullet {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-left: 0.5rem;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 25px;
            transition: all 0.3s ease;

            &:first-child {
                margin-left: 0;
            }

            &.swiper-pagination-bullet-active {
                background: rgba(255, 255, 255, 1);
            }
        }
    }
}