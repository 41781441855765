@import '../../../assets/styles/scss/help';

#get-my-reserv {
    width: 100%;

    .reser-inner {
        width: 100%;

        section {
            .tit {
                text-align: center;
                font-weight: 700;
            }

            .txt {
                text-align: center;
                line-height: 136%;
                color: #3c414a;
            }

            .img-area {
                text-align: center;
            }
        }

        #section_top {
            padding-top: rem(30);

            .tit {
                font-size: rem(26);
                line-height: 132%;
            }

            .txt {
                margin-top: rem(10);
                font-size: rem(16);
                p {
                    position: relative;
                    margin-top: 4px;
                    color: #3906c3;
                    text-align: center;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 136%; /* 1.36rem */
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .under-line {
                        position: absolute;
                        bottom: 3px;
                        width: 10.5rem;
                        flex-shrink: 0;
                        border-bottom: 0.3125rem solid #f2eaff;
                        z-index: -2;
                    }
                }
            }

            .img-area {
                margin-top: rem(-75);
            }
        }

        #section_mid {
            padding-top: rem(48);
            padding-bottom: rem(48);
            background: #e7ecf2;

            .tit {
                font-size: rem(24);
            }

            .img-area {
                margin-top: rem(12);
                margin-left: 18px;
            }
        }

        #section_btm {
            position: relative;
            padding-top: rem(48);
            background: #fff9f9;
            margin-bottom: rem(90);
            .tit {
                margin-bottom: rem(10);
                font-size: rem(24);
            }

            .img-area {
                margin-top: rem(74);
            }

            .btnPartners {
                position: absolute;
                top: rem(167);
                left: 50%;
                margin-left: -66px;
                padding: 8px 14px;
                height: 34px;
                border: 1px solid #e7e5eb;
                border-radius: 4px;
                background: #fff;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: rem(14);
                line-height: rem(18);
                .btn_ar {
                    @include textHidden;
                    @include iconGoArrow($size: 1rem, $color: '10003B');
                    background-size: 7px;
                    margin: 0 0 0 auto;
                }
            }
        }
    }

    .txt {
        font-size: rem(16);
        line-height: rem(22);
        word-break: keep-all;
    }

    .btnArea {
        position: fixed;
        bottom: 0;
        background: #fff;
        width: 100%;
        padding: rem(20) rem(24);
        .button {
            font-size: 1rem;
        }
        .checkLabel {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 1.22rem;
            label {
                font-size: 0.875rem;
                span {
                    color: $c-primary;
                    &.gray {
                        color: $c-gray !important;
                    }
                }
            }
            .btn_ar {
                @include textHidden;
                @include iconGoArrow($size: 1.5rem, $color: 'A0A4AF');
                background-size: 7px;
                margin: 0 0 0 auto;
            }
        }
    }
}
