@import '../../../assets/styles/scss/help';
@import '../../../assets/styles/scss/typography';

/* banner Common Styles */

// banner height
$banner-height-100: 100px;
$banner-height-84: 84px;
// banner-bg-color
$banner-bg-dark: #151515;
$banner-bg-light-purple: #f2ebfc;
$banner-bg-dark-purle: #5325f5;
// banner-radius
.banner-radius {
    border-radius: rem(8);
}

/* font Common Styles */
.font-emphasis {
    color: #b18aeb;
}
.font-weight-regular {
    font-weight: 400;
}

/* banner Type Styles */
.banner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: $banner-height-84;

    /* 검색 내 즐겨찾기 배너 */
    &.type-05 {
        background: $banner-bg-dark-purle;
    }

    /* 내정보 배너 */
    &.type-06 {
        background: linear-gradient(0deg, #eaebf5 0%, #eaebf5 100%), #191b1e;
    }

    /* 체크인프로모션 상단 배너 */
    &.type-07 {
        height: $banner-height-100;
        background: $banner-bg-dark;
        gap: rem(17);
    }
    /* 체크인프로모션 중간 배너 */
    &.type-08 {
        height: $banner-height-100;
        background: $banner-bg-light-purple;
        gap: rem(17);
    }
}

// 타이틀영역
.banner-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(5);
    min-width: max-content;
    z-index: 10;

    &.type-05 {
        padding: 0 0 0 rem(20);
        gap: rem(5);
        font-style: normal;

        .banner-title {
            color: #f4ff7a;
            font-size: rem(16);
            font-weight: 700;
            line-height: normal;
        }
        .banner-subtitle {
            color: $c-f;
            opacity: 0.7;
            font-size: rem(12);
            font-weight: 400;
            line-height: 136%;
        }
    }
    &.type-06 {
        padding: rem(19) rem(20);
        gap: rem(4);
        color: $c-0;
        font-style: normal;

        .banner-title {
            font-size: rem(14);
            font-weight: 400;
            line-height: 132%;
        }
        .banner-subtitle {
            font-size: rem(18);
            font-weight: 700;
            line-height: 136%;
        }
    }

    &.type-07 {
        color: $c-f;
        font-style: normal;
        font-family: 'TheJamsil';
        line-height: 136%;
        padding: rem(24) rem(20);

        .banner-title {
            font-size: rem(18);
            font-weight: 400;
        }
        .banner-subtitle {
            font-size: rem(20);
            font-weight: 700;
        }
    }

    &.type-08 {
        color: $c-0;
        font-family: 'TheJamsil';
        line-height: 136%;
        padding: rem(26) rem(20) rem(26) 0;
        position: absolute;
        right: 0;

        .banner-title,
        .banner-subtitle {
            font-size: rem(18);
            font-weight: 700;
        }
    }
}

// 이미지영역
.banner-image-wrapper {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    height: $banner-height-84;

    &.type-05 {
        width: 160px;
        right: 0;
    }

    &.type-06 {
        width: 204px;
        right: 0;
    }

    &.type-07 {
        width: 128.003px;
        height: $banner-height-100;
        right: 0;
        padding-top: 4px;
    }

    &.type-08 {
        width: 189px;
        height: $banner-height-100;
        left: 0;
    }
}
