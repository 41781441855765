@import '../../../assets/styles/scss/help';

.dimmed-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 101;
}

.promotion-checkin-modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 105;
    width: 100%;
    height: 100%;
}

.promotion-checkin-modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 0 rem(30) rem(34) rem(30);
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.promotion-checkin-image-wrapper {
    z-index: 102;
    height: rem(130);
    margin: 0 auto;
}

.promotion-checkin-image {
    width: rem(268.341);
    height: rem(278.583);
    background: url('../../../assets/images/promotion/promotion_checkin_img.png') center/100% no-repeat;
}

.promotion-checkin-content-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    border-radius: rem(16);
    background: #151515;
    height: rem(377);
}

.promotion-checkin-content {
    display: inline-flex;
    padding: 0 rem(25.5) rem(34) rem(26.5);
    flex-direction: column;
    align-items: center;
    gap: rem(28);
    position: absolute;
}

.promotion-checkin-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(12);
    min-width: rem(249);

    .promotion-checkin-main-title {
        color: $c-f;
        text-align: center;
        font-size: rem(22);
        font-style: normal;
        font-weight: 700;
        line-height: 136%;
    }

    .promotion-checkin-sub-title {
        color: $c-f;
        text-align: center;
        font-size: rem(14);
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
    }

    .promotion-checkin-sub-title-02 {
        color: rgba(255, 255, 255, 0.5);
        /* 최소 */
        font-size: rem(12);
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
    }
}

.promotion-checkin-button-wrapper {
    width: rem(140);
}

.promotion-checkin-button-title {
    color: #fff;
    text-align: center;
    font-size: rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.promotion-checkin-bottom-image {
    z-index: 105;
    width: rem(51.535);
    height: rem(42.266);
    position: relative;
    left: rem(86);
    background: url('../../../assets/images/promotion/promotion_gift_srixon_blue.png') center/100% no-repeat;
}
