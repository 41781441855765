@import '../../../assets/styles/scss/help';

$drawerHeight: calc(100vh - 3.75rem);

#root {
    //.golfZoneSearch-inp {
    //  width: 100%;
    //  position: fixed;
    //  padding: 20px;
    //  top: 0;
    //  height: 64px;
    //  font-size: 14px;
    //  border-bottom: 0;
    //}

    .golfZonSearch {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .topBar {
            height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;
            padding: 0 $InnerPadding;
        }

        .tabType01 {
            margin-top: rem(-4);
            margin-bottom: 0;
            flex-shrink: 0;
        }

        .golfZoneList {
            .golfZoneListItem {
                width: 100%;
                align-items: first baseline;
                border-bottom: 1px solid;
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-color: rgba(215, 217, 226, 0.5);

                .golfZoneWrapper {
                    display: flex;
                    justify-content: space-between;

                    .rankNumber {
                        flex-shrink: 0;
                        color: $c-primary;
                        margin-right: 0.938rem;
                        font-size: 1rem;
                        font-weight: 700;
                    }

                    .golfZoneInfo {
                        width: 100%;

                        .golfZoneInfoArea {
                            .infoTitleArea {
                                display: flex;

                                .golfZoneName {
                                    font-size: rem(16);
                                    line-height: rem(19);
                                    font-weight: 700;
                                    cursor: pointer;
                                }

                                .statusIcon {
                                    margin-left: rem(5);
                                }
                            }

                            .golfZoneSubInfo {
                                @include font-light02;
                                color: $c-gray;
                                margin-top: rem(3);
                                cursor: pointer;
                            }
                        }
                    }

                    .itemSideArea {
                        flex-shrink: 0;
                        justify-content: space-between;
                        display: flex;

                        .btnInterest {
                            svg {
                                width: rem(18);
                                height: rem(18);
                            }
                        }
                    }
                }
            }

            &.wideNum {
                .golfZoneListItem {
                    .golfZoneWrapper {
                        .rankNumber {
                            width: rem(20);
                        }
                    }
                }
            }
        }
    }

    .golfZoneSearchNoData {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
    }
}

.searchDrawer {
    overflow: hidden;
    height: auto;

    .drawer-content {
        overflow: hidden;
        flex-direction: column;
        display: flex;
        max-height: $drawerHeight;
        min-height: $drawerHeight;
        padding: 20px;

        .drawerTitle {
            display: flex;
            flex-shrink: 0;
            justify-content: space-between;
            margin-bottom: rem(10);

            .btnClose {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0;
                color: $c-gray-900;

                svg {
                    display: block;
                    width: 1.009rem;
                }
            }
        }

        .search-inp {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
        }

        .drawerContents {
            overflow: hidden;
            flex-direction: column;
            display: flex;
            position: relative;
            overflow-y: auto;
            padding: 0;

            .historyView {
                overflow: hidden;
                flex-direction: column;
                display: flex;

                .historyTop {
                    display: flex;
                    flex-shrink: 0;
                    justify-content: space-between;
                    margin: rem(30) $InnerPadding rem(20);

                    .recentSearch {
                        font-size: rem(18);
                        font-weight: 600;
                    }

                    .resetBtn {
                        margin-left: auto;
                        font-size: 0.875rem;
                        font-weight: 400;
                        color: #fe6215;
                    }
                }

                .scrollArea02 {
                    padding: 0 $InnerPadding;
                }

                .historyList {
                    display: flex;
                    flex-wrap: wrap;
                    text-align: center;
                    justify-content: left;
                    margin-right: rem(-10);

                    .historyItem {
                        display: flex;
                        margin-right: rem(10);
                        border-radius: rem(50);
                        border: $border-gray;
                        padding: rem(9) rem(15);
                        margin-bottom: rem(10);

                        .historyWord {
                            color: $c-gray;
                            font-weight: 400;
                            @include font-light02;
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            margin-right: rem(14);
                        }

                        .historyDeleteBtn {
                            //margin-left: auto;
                        }
                    }
                }
            }

            .searchingView {
                margin-top: 1.563rem;
                overflow: hidden;
                flex-direction: column;
                display: flex;

                .allSearchArea {
                    display: flex;
                    height: 21px;
                    flex-shrink: 0;
                    justify-content: space-between;

                    .allSearch {
                        width: 100%;
                        display: flex;
                        font-weight: 500;
                        align-items: baseline;

                        .searchingContent {
                            color: $c-primary;
                            font-size: rem(18);
                            font-weight: 700;
                            @include textEllipsis;
                            width: auto !important;
                        }

                        .allSearchingCheck {
                            margin-left: rem(4);
                            font-size: 0.875rem;
                            color: $c-gray;
                            flex-shrink: 0;
                            margin-right: 0.5rem;
                        }
                    }

                    .rightArrow {
                        margin-left: auto;
                        vertical-align: middle;
                    }
                }

                .scrollArea02 {
                    margin-top: rem(8);
                }

                .searchingList {
                    .searchingItem {
                        padding: rem(10) 0;
                        border-bottom: $border-gray-opacity;

                        &:last-child {
                            border-bottom: none;
                        }

                        .searchingName {
                            display: flex;
                            align-items: center;

                            .golfZoneName {
                                font-weight: 600;
                                font-size: 1rem;
                                margin-right: 0.375rem;
                                display: block;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }

                            .notAlliance {
                                display: flex;
                                flex-shrink: 0;
                                font-size: 0.625rem;
                                color: $c-gray;
                                background-color: white;
                                align-items: center;
                                border-radius: rem(2);
                                padding: rem(3) rem(5);
                                border: rem(1) solid $c-gray-05;
                            }
                        }

                        .golfZoneLocation {
                            display: flex;
                            margin-top: rem(3);
                            @include font-light02;
                            white-space: pre;
                            font-weight: 400;
                            color: $c-gray;
                        }
                    }
                }
            }
        }
    }
}

.searchResultHeader {
    display: flex;
    align-items: center;
    height: 4rem;
    margin: rem(10) $InnerPadding rem(10) rem(10);

    .searchResultBtnBack {
        width: rem(18) !important;
        height: 100% !important;
        flex-shrink: 0;
        line-height: 0;
        margin-left: rem(8);
        margin-right: rem(10);
    }

    .searchWordArea {
        width: 100%;
        padding: rem(11) rem(11);
        background-color: #f0f1f4;
        border-radius: rem(4);
        display: flex;

        .searchWord {
            font-size: rem(14);
        }

        .btnArea {
            display: flex;
            height: 1.063rem;
            margin-left: rem(10);

            .btnDelete {
                &::after {
                    @include iconDelete;
                }
            }

            span {
                margin-left: 12px;
            }
        }
    }
}
