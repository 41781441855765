@import '../../assets/styles/scss/help';

#smart-round-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    background: $c-0;
    z-index: 100;

    a {
        display: block;
        flex: 1 1 0;
        text-align: center;
        padding: rem(10) 0 rem(12);
        font-size: rem(10);
        font-weight: 500;
        color: $c-f;
        opacity: 0.6;

        i {
            display: block;

            &.self-check-in-v2 {
                @include icon-self-check-in;
            }

            &.partner-v2 {
                @include icon-partner;
            }

            &.course-guide-v2 {
                @include icon-course-guide;
            }

            &.food-v2 {
                @include icon-food;
            }
        }

        span {
            display: block;
            padding-top: rem(4);
            line-height: rem(12);
        }

        &.on {
            opacity: 1;
        }
    }
}

#smart-round-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;

    &.black {
        background: #fff;
    }

    .header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: rem(56);

        &>div {
            display: flex;
            align-items: center;
            height: 100%;
            overflow: hidden;

            button {
                width: 50px !important;
                height: 100% !important;
                flex-shrink: 0;
                font-size: 0;
                line-height: 0;
            }
        }

        .left-con {
            flex-shrink: 0;
            margin-left: -20px;
        }

        .right-con {
            flex-shrink: 0;
            justify-content: flex-end;
            margin-right: -20px;
        }

        .btnHome {
            /*@include iconHome($color: '212121');*/
            background-size: 1.5rem;
        }

        .btnAlarm {
            background-size: 1.5rem;

            &.on {
                &::after {
                    content: '';
                    position: absolute;
                    top: 1.375rem;
                    right: 1.1875rem;
                    width: 0.4375rem;
                    height: 0.4375rem;
                    background: $c-orange;
                    border-radius: 100%;
                }
            }
        }

        .btnNotice {
            position: relative;
            // @include iconAlarm($color: 'ffffff');
            background-size: 1.5rem;

            &.on {
                &::after {
                    content: '';
                    position: absolute;
                    top: 1.375rem;
                    right: 1.1875rem;
                    width: 0.4375rem;
                    height: 0.4375rem;
                    background: $c-orange;
                    border-radius: 100%;
                }
            }
        }

        .noticeTooltip {
            position: absolute;
            right: 45px;
            padding-right: 0.25rem;
            overflow: hidden;
            max-width: calc(100% - 3rem - 15px);

            .txtBox {
                border-radius: 50px;
                background: $c-f8;
                padding: 0.5rem 0.75rem;
                @include font-xlight;
                color: $c-gray;
                //white-space: nowrap;
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
                animation-name: fadeIn;
                animation-fill-mode: forwards;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0px;
                    height: 0px;
                    border-top: 6px solid transparent;
                    border-left: 8px solid $c-f8;
                    border-right: 8px solid none;
                    border-bottom: 6px solid transparent;
                }
            }

            .txt {
                display: inline-block;
                vertical-align: middle;
                overflow: hidden;
                animation-timing-function: ease-out;
                animation-name: fadeIn;
                animation-fill-mode: forwards;
            }

            &.close {
                .txtBox {
                    animation-name: fadeOut;
                }
            }
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: translateX(-100%);
            }

            to {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @keyframes fadeOut {
            from {
                opacity: 1;
                transform: translateX(0);
            }

            to {
                opacity: 0;
                transform: translateX(100%);
            }
        }

        .btnClose {
            @include iconClose($size: 1.5rem);
            background-size: 1.5rem;
        }
    }

    &+#contents,
    &+.contentsArea {
        padding-top: rem(56);
    }

    .bgLightBlue & {
        background: #fff;
    }
}