@import '../../../assets/styles/scss/help';

// 셀프체크인 경품 프로모션 안내 문구
.promotion-checkin-notice-wrapper {
    display: flex;
    padding: rem(14);
    margin-bottom: rem(20);
    border-radius: rem(8);
    background: #f6f3fb;
    align-items: center;
    gap: rem(8);

    .promotion-checkin-notice-icon {
        flex-shrink: 0;
        width: 40px; // fixed
        height: 53px; // fixed
        background-size: 100%;
        background-position: center;
        background-image: url('../../../assets/images/promotion/promotion_gift.png');
    }

    .promotion-checkin-notice {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: rem(4);
    }

    .promotion-checkin-notice-label {
        color: #10003b;
        font-size: rem(14);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .promotion-checkin-notice-content {
        color: #706689;
        font-size: rem(12);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .font-weight-bold {
            font-weight: 700;
        }
    }
}
