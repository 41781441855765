@import '../../../assets/styles/scss/help';
@import '../payment.scss';
/**
예약 완료 페이지
*/

#payment-booking-success {
    position: relative;
    padding-top: 3.5rem;

    .payment-booking-success-contents {
        .payment-booking-success {
            .section {
                padding: rem(20) 0 rem(12);
                .tt-area {
                    border-bottom: 4px solid $c-0;
                    padding-bottom: rem(14);

                    .tt {
                        display: block;
                        font-weight: 500;
                        font-size: rem(20);
                        line-height: rem(24);
                        word-break: keep-all;
                        font-style: normal;
                        margin-top: rem(5);
                    }
                }
            }

            .payment-booking-success-info {
                &.border-top {
                    padding: rem(12) 0 rem(20) 0;
                    border-top: 1px solid rgba(215, 217, 226, 0.5);
                }

                .tt {
                    padding-bottom: rem(10);
                    border-bottom: 4px solid #000;
                    font-size: rem(18);
                    line-height: rem(21);
                    font-weight: 600;
                }
                //펼치기 전용 컨텐츠
                .payment-expanded-content {
                    padding-bottom: rem(12);
                    dl {
                        &.dl-sub-text {
                            margin-top: 0;
                            dd {
                                color: $c-gray;
                                font-size: rem(12);
                                font-style: normal;
                                font-weight: 400;
                                line-height: rem(16);
                                padding-top: rem(4);
                                padding-left: rem(12);
                            }
                        }
                    }
                    // 카트피가 포함 된 경우
                    &.border-bottom {
                        border-bottom: 1px solid rgba(215, 217, 226, 0.5);
                    }
                }
                // 접기 펼치기 가능 컨텐츠
                .payment-collapse-content {
                    padding: 0;
                    dl {
                        &.dl-sub-text {
                            margin-top: 0;
                            dd {
                                color: $c-gray;
                                font-size: rem(12);
                                font-style: normal;
                                font-weight: 400;
                                line-height: rem(16);
                                padding-top: rem(4);
                                padding-left: rem(12);
                            }
                        }
                    }
                    // 카트피가 포함 된 경우
                    &.border-bottom {
                        border-bottom: 1px solid rgba(215, 217, 226, 0.5);
                    }
                }

                dl {
                    display: flex;

                    .payment-booking-init {
                        justify-content: initial !important;
                    }
                    justify-content: space-between;

                    margin-top: rem(12);

                    &:first-child {
                        margin-top: 0;
                    }

                    &.border-dashed-bottom {
                        border-bottom: 1px dashed rgba(215, 217, 226, 0.5);
                        padding-bottom: rem(12);
                    }

                    &.border-bottom {
                        padding-bottom: rem(12);
                        &:last-child {
                            &.black {
                                border-bottom: 1px solid $c-0;
                                margin-bottom: rem(12);
                            }
                            border-bottom: 1px solid rgba(215, 217, 226, 0.5);
                        }
                    }

                    &.total-payment {
                        font-size: 14px;
                        font-family: Pretendard;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        color: $c-primary;
                        margin-bottom: rem(10);

                        &.black {
                            color: $c-0;
                            margin-bottom: rem(12);
                            margin-top: rem(12);
                        }
                    }

                    dt {
                        &.payment-success-700 {
                            font-weight: 700;
                        }

                        &.payment-success-800 {
                            font-weight: 800;
                        }
                        font-weight: 500;
                        @include font-light02;
                    }

                    dd {
                        @include font-light02;
                        text-align: right;

                        &.payment-success-700 {
                            font-weight: 700;
                        }

                        &.payment-success-800 {
                            font-weight: 800;
                        }

                        ul {
                            line-height: rem(20);
                            li {
                                overflow: hidden; // 을 사용해 영역을 감출 것
                                text-overflow: ellipsis; // 로 ... 을 만들기
                                white-space: nowrap; // 아래줄로 내려가는 것을 막기위해
                                word-break: break-all;
                                width: rem(240);
                                line-height: 20px;

                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                        }

                        .btnDetail {
                            position: relative;
                            @include font-light02;
                            font-weight: 700;
                            text-align: left;
                            cursor: pointer;

                            &::after {
                                content: '';
                                position: relative;
                                top: rem(4);
                                right: 0;
                                margin-left: rem(8);
                                @include iconDropDown($color: '000');
                                @include textHidden;
                            }

                            &.collapse {
                                &::after {
                                    transform: rotate(-180deg);
                                }
                            }
                        }
                    }
                }

                .sub-title-area {
                    position: relative;
                    margin-bottom: rem(14);

                    h2 {
                        @include font-light02;
                        font-weight: 700;

                        &.green {
                            color: $c-primary;
                        }

                        span {
                            font-size: 0.875rem;
                            font-weight: 400;
                            color: $c-gray;
                        }
                    }
                }
            }

            .fieldBookingsuccessBorderLine {
                margin-top: 0.75rem;
                padding-top: 0.75rem;
                border-top: 1px solid $c-0;
            }

            .advSection {
                img {
                    width: 100%;
                    border-radius: rem(8);
                    margin-top: rem(10);
                }
            }

            .payment-booking-notice-bg-grey {
                background: #f6f7f8;
                padding: rem(13) rem(14);

                &.margin-top {
                    margin-top: rem(20);
                }
                .payment-booking-info-wrap {
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(6);

                    h2 {
                        flex: 0 0 auto;
                        margin-right: 5px;
                    }

                    .payment-booking-info-text {
                        font-weight: 400;
                        font-size: rem(14);
                        line-height: rem(16.71);
                        flex: 1 1 auto;
                        text-align: right;
                    }

                    .payment-booking-cancel {
                        font-weight: 700;
                        font-size: rem(14);
                        line-height: rem(16.71);
                        flex: 1 1 auto;
                        text-align: right;

                        &.true {
                            color: #3906c3;
                        }

                        &.false {
                            color: $c-red;
                        }
                    }
                }
                .payment-booking-cancel-notice {
                    font-size: rem(12);
                    line-height: rem(16);
                    color: $c-red;
                }
            }
        }
    }
}

// 하단 고정 버튼
.payment-booking-cancel-status-btn-bottom {
    position: sticky;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: rem(30) 0;
    background: #fff;
    z-index: 10;

    .inner {
        display: flex;

        button {
            flex: 1 1 auto;
            margin-left: rem(8);

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &.btn-full-width {
        .inner {
            display: block;

            button {
                margin-left: 0;
                margin-top: 0.75rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
