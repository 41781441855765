.fw {
    width: 100%;
}
.color-grey-secondary {
    color: #53576d;
}
.mbottom-medium {
    margin-bottom: 16px;
}
.text-center {
    text-align: center;
}
.bold {
    font-weight: 700;
}
.main-para {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.25px;
}
.light {
    font-weight: 300;
}
.fine-text {
    font-size: 13px;
    line-height: 1.23;
    letter-spacing: -0.25px;
}
.color-grey-medium {
    color: #aaaaaa;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.pull-left {
    float: left !important;
}
.text-uppercase {
    text-transform: uppercase;
}
.color-grey-light {
    color: #cccccc;
}
.small-heading-sm {
    font-size: 16px;
    line-height: 1.125;
    letter-spacing: -0.26px;
}
.mbottom-small {
    margin-bottom: 8px;
}
.bg-grey-secondary {
    background-color: #53576d;
}
.bg-grey-light {
    background-color: #cccccc;
}
.color-white {
    color: #ffffff;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-pointer {
    cursor: pointer;
}
.mobile-datepicker {
    width: 100%;
    color: #53576d;
}
.mobile-datepicker:before,
.mobile-datepicker:after {
    content: ' ';
    display: table;
}
.mobile-datepicker:after {
    clear: both;
}
.mobile-datepicker .month {
    width: 100%;
    padding-top: 2.25rem;
}
.mobile-datepicker .month:first-child {
    padding-top: 20px;
}
.mobile-datepicker .month:before,
.mobile-datepicker .month:after {
    content: ' ';
    display: table;
}
.mobile-datepicker .month:after {
    clear: both;
}
.mobile-datepicker .month .month-title {
    width: 100%;
    letter-spacing: -0.25px;
    margin-bottom: 1.5rem;
    display: flex;
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(32);
    color: #000;
}
.mobile-datepicker .month .month-title:before {
    display: none;
}

.mobile-datepicker .month .month-title:after {
    content: ' ';
    display: table;
}
.mobile-datepicker .month .month-title:after {
    clear: both;
}
.mobile-datepicker .month .month-title span {
    letter-spacing: -0.25px;
    display: block;
    margin-right: 0.25rem;
    color: #000;
}
.mobile-datepicker .month ul {
    padding-left: 0;
    list-style: none;
    margin: 0 -2px;
}
.mobile-datepicker .month ul:before,
.mobile-datepicker .month ul:after {
    content: ' ';
    display: table;
}
.mobile-datepicker .month ul:after {
    clear: both;
}
.mobile-datepicker .month ul li {
    float: left !important;
    text-align: center;
    width: 14.28%;
}
.mobile-datepicker .month ul.days {
    margin-bottom: 16px;
}
.mobile-datepicker .month ul.days li {
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    color: #212121;
}

.mobile-datepicker .month ul.days li.sunday {
    color: #ff4d6f;
}

.mobile-datepicker .month ul.days li.saturday {
    color: #1771c3;
}

.mobile-datepicker .month ul.date li {
    font-size: 0.875rem;
    line-height: 1.125;
    letter-spacing: -0.26px;
    font-weight: 300;
    margin-bottom: 8px;
    min-height: 32px;
    cursor: pointer;
}

.mobile-datepicker .month ul.date li.sunday,
.mobile-datepicker .month ul.date li.holiday,
.mobile-datepicker .month ul.date li.saturday.holiday {
    color: #ff4d6f;
}
.mobile-datepicker .month ul.date li.disabledsunday {
    opacity: 0.3;
    color: #ff4d6f;
}
.mobile-datepicker .month ul.date li.saturday {
    color: #1771c3;
}
.mobile-datepicker .month ul.date li.disabledsaturday {
    opacity: 0.3;
    color: #1771c3;
}

.mobile-datepicker .month ul.date li span {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
}
.mobile-datepicker .month ul.date li span:hover,
.mobile-datepicker .month ul.date li span:focus {
    text-decoration: none;
    cursor: pointer;
}
.mobile-datepicker .month ul.date li.active span {
    background-color: #3906c3;
    color: #ffffff;
}
.mobile-datepicker .month ul.date li.disabled {
    opacity: 0.3;
    pointer-events: none;
}
.mobile-datepicker .month ul.date li.disabled span {
    cursor: not-allowed;
}
.mobile-datepicker .month ul.date li.vis-hidden {
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    pointer-events: none;
}
