// Basic Buttons
@mixin BtnSize {
    position: relative;
    display: block;
    width: 100%;
    border: none;
    border-radius: rem(40);
    font-size: rem(16);
    text-align: center;
    line-height: rem(48);
    overflow: hidden;

    &.medium {
        font-size: rem(14);
        line-height: rem(38);
        border-radius: rem(8);
    }

    &.small {
        font-size: rem(12);
        line-height: rem(32);
        border-radius: rem(6);
    }

    &.nonRadius {
        border-radius: 0px !important;
    }
}

// Ripples
@mixin ripples($color, $opacity: 12%) {
    transition: background 1s;

    &:disabled {
        transition: background 0s !important;
    }

    &:not(:disabled):active {
        background-color: darken($color, $opacity) !important;
        transition: background 0s;
    }
}

@mixin ripples-light($color, $opacity: 12%) {
    transition: background 1s;

    &:disabled {
        transition: background 0s !important;
    }

    &:not(:disabled):active {
        background-color: lighten($color, $opacity) !important;
        transition: background 0s;
    }
}

.btnPrimary {
    position: relative;
    @include BtnSize;
    background: $c-primary;
    color: $c-f;
    font-weight: 600;

    &:disabled {
        background-color: $c-gray-03 !important;
    }

    @include ripples($c-primary);
}

.btnPayment {
    @include BtnSize;
    background: $c-navy;
    color: $c-f;

    &:disabled {
        background-color: $c-gray-400 !important;
    }
}

.btnPrimaryLine {
    @include BtnSize;
    background: $c-f;
    border: 1px solid $c-primary !important;
    color: $c-primary !important;

    @include ripples($c-f, 17%);
}

.btnSecondary {
    @include BtnSize;
    background: $c-secandary !important;
    color: $c-primary !important;

    &:disabled {
        background-color: $c-f8 !important;
        color: $c-gray-500 !important;
    }

    @include ripples($c-secandary);
}

.btnFilterInit {
    @include BtnSize;
    background: $c-gray-02 !important;
    color: $c-f !important;
    width: 40% !important;

    &:disabled {
        background-color: $c-gray-03 !important;
    }

    @include ripples($c-gray-02);
}

.btnFilterApply {
    @include BtnSize;
    background: $c-gray-03 !important;
    color: $c-f !important;

    width: 60% !important;

    &:disabled {
        background-color: $c-primary !important;
        color: $c-f !important;
    }

    &.on {
        background-color: $c-primary !important;

        @include ripples($c-primary);
    }
}

.btnGray {
    @include BtnSize;
    background: $c-gray-03 !important;
    color: #fff !important;
    font-weight: 600;

    @include ripples($c-gray-03);
}

.btnGrayLight {
    @include BtnSize;
    background: $c-gray-200 !important;
    color: $c-gray-02 !important;
    font-weight: 600;

    @include ripples($c-gray-200);
}

.btnDarkGray {
    @include BtnSize;
    background: $c-gray-02 !important;
    color: #fff !important;
    font-weight: 600;

    &:disabled {
        background: $c-gray-03 !important;
    }

    @include ripples($c-gray-02);
}

.btnCTA {
    @include BtnSize;
    background: $c-navy !important;
    color: $c-f !important;

    @include ripples-light($c-navy);
}

.btnGrayLine02 {
    display: block;
    width: auto;
    padding: rem(6.5) rem(10);
    background: #fff;
    border: 1px solid $c-gray-200;
    border-radius: rem(8);
    @include font-light02;
}

.btnGrayLine03 {
    display: block;
    width: 100%;
    height: rem(42);
    background: #fff;
    border: 1px solid $c-gray-200;
    border-radius: rem(8);
    font-size: rem(14);

    &:disabled {
        border: none;
        color: $c-f;
        background: $c-gray-400;
    }
}

.btnGrayLine04 {
    @include BtnSize;
    background: #fff;
    border: 1px solid $c-gray-05;
}

button {
    @mixin inlineStyleIcon {
        margin-right: 0.5rem;
        margin-top: -4px;
        vertical-align: middle;
    }

    &.kakao {
        @include ripples(#fee500);

        &:disabled {
            background-color: darken(#fee500, 12%) !important;
            transition: background 0s;
            color: $c-0;
        }

        &:before {
            content: '';
            width: 1.5rem;
            position: relative;
            height: 1.5rem;
            display: inline-block;
            background: url('../../images/common/icon_kakao.png') no-repeat 0 0;
            background-size: contain;
            @include inlineStyleIcon;
            //@include iconKakao;
        }
    }

    &.system {
        @include ripples(#ffffff);

        &:disabled {
            background-color: darken(#ffffff, 12%) !important;
            transition: background 0s;
            color: $c-0;
        }

        &:before {
            content: '';
            width: 1.5rem;
            position: relative;
            height: 1.5rem;
            display: inline-block;
            background: url('../../images/common/icon-link.png') no-repeat 0 0;
            background-size: contain;
            @include inlineStyleIcon;
            //@include iconKakao;
        }
    }

    // 카카오, 애플 딤처리
    &.kakao2,
    &.apple2 {
        position: relative;

        &:disabled {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                background: #000;
                opacity: 0.3;
                z-index: 10;
            }
        }
    }

    &.apple {
        @include ripples(#fff, 17%);

        &:disabled {
            background-color: darken(#fff, 17%) !important;
            transition: background 0s;
            color: $c-0;
        }

        &:before {
            @include iconApple($color: '000');
            @include inlineStyleIcon;
        }
    }

    &.mail {
        background: none;
        border: 1px solid #fff;
        color: $c-f;

        &:before {
            @include iconMail($color: 'fff');
            @include inlineStyleIcon;
        }
    }
}

.btnBack {
    @include iconBack;
    @include textHidden;
    background-size: 1rem 1rem;
    vertical-align: top;
}

.btnAlarm {
    @include iconAlarm;
    @include textHidden;

    &.on {
        &::after {
            content: '';
            position: absolute;
            top: 0.125rem;
            right: 0.0625rem;
            width: 0.4375rem;
            height: 0.4375rem;
            background: $c-orange;
            border-radius: 100%;
        }
    }
}

.btnSmall {
    width: 4.625rem;
    height: 2rem;
    line-height: 2rem;
    background: #d3d3d3;
    text-align: center;
    color: $c-f;
    border-radius: rem(40);
    font-size: 0.875rem;

    // 인증요청 버튼 별도 추가
    &.auth {
        &.on {
            background: $c-f;
            color: $c-0;
            border: 1px solid $c-gray-03;

            @include ripples($c-f, 17%);
        }
    }

    &.on {
        background: $c-primary;
    }
}

//.btnSmall {
//    width: 4.625rem;
//    height: 2rem;
//    line-height: 2rem;
//    background: #d3d3d3;
//    text-align: center;
//    color: $c-f;
//    border-radius: rem(40);
//    font-size: 0.875rem;
//
//    &.on {
//        background: $c-primary;
//    }ㅣ
//}

.btnSelectModal {
    position: relative;
    width: 100%;
    padding: 1rem 0;
    border-bottom: $border-gray;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;

    .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;

        svg {
            polyline {
                stroke: $c-0;
            }
        }
    }
}

.btnRefresh {
    padding: 0.625rem;
    background-size: 1rem !important;
    @include iconRefresh;
    @include textHidden;
    cursor: pointer;

    &.rotate {
        animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.buttonBox {
    margin-top: 0.75rem;
    padding: 1rem 1.25rem;
    border: 1px solid $c-gray-200;
    border-radius: 0.5rem;

    &:first-child {
        margin-top: 0;
    }

    .txtAreaWrap {
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            @include iconMoreArrow($size: 1.25rem, $color: '848588');
            transform: translateY(-50%);
            vertical-align: middle;
        }

        .txtArea {
            cursor: pointer;
        }

        .tt {
            display: block;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.1875rem;
        }
    }

    .btnReSelect {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 10px 0;
        background: #fff;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: $c-primary;
        z-index: 5;

        &::after {
            margin-top: -0.1875rem;
            margin-left: 0.25rem;
            @include iconReselected;
            vertical-align: middle;
        }
    }

    p {
        padding-top: 0.4063rem;
        font-size: 0.8125rem;
        line-height: 1rem;
        color: $c-gray;
    }

    &.selected {
        border-color: $c-primary;
    }

    &.disabled {
        background: $c-f8;
        border-color: $c-gray-200 !important;

        .txtAreaWrap {
            .txtArea {
                cursor: default;
            }

            .tt {
                color: $c-gray;
            }

            &::after {
                @include iconMoreArrow($size: 1.25rem, $color: 'c4c4c4');
            }
        }

        p {
            color: $c-c4;
        }

        .selectedList {
            background-color: $c-gray-200;
            color: $c-gray;

            span {
                &:before {
                    @include iconCheck($size: 1.25rem, $color: '848588');
                }
            }
        }
    }
}

.btnListWrap {
    margin-top: 1.5rem;

    button {
        margin-top: 0.375rem;

        &:first-child {
            margin-top: 0;
        }
    }
}

// 툴팁
.btnTooltip {
    position: relative;
    @include iconTooltip;
    @include textHidden;
    overflow: visible;

    .toolTipArrow {
        position: relative;
        left: 4px;
        top: -14px;
        display: block;
        width: 9px;
        height: 8px;
        z-index: 30;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 0;
            height: 0;
            top: 0;
            border-top: 8px solid #212121;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
            top: -2px;
            border-top: 8px solid #fff;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
        }
    }
}

//회원가입시 default open
.btnTooltipWrap {
    position: relative;
    width: 100%;

    .member_tooltip_open {
        position: absolute;
        width: 214px;
        height: 111px;
        bottom: rem(38);
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        background: url('../../images/membership/member_tooltip_open.png') no-repeat 0 0;
        background-size: contain;
        z-index: 1;

        &+button {
            margin-left: 0 !important;
        }
    }
}

// 체크박스, 라디오
.checkLabel {
    display: flex;
    align-items: center;
}

.customCheck {
    display: inline-block;

    label {
        display: block;
    }

    input[type='checkbox'] {
        display: none;
    }

    .icon {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        cursor: pointer;

        svg {
            vertical-align: middle;
        }

        &.round {
            position: relative;
            border-radius: 100%;
            overflow: hidden;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid $c-gray-03;
                border-radius: 100%;
            }

            &.checked {
                background: $c-primary;

                &::after {
                    display: none;
                }
            }
        }
    }

    &.checked {
        color: $c-21;
    }
}

.customCheck02 {
    display: block;

    input[type='radio'],
    input[type='checkbox'] {
        display: none;

        &:checked {
            &+span {
                color: $c-primary;

                &+.icon {
                    display: inline-block;
                    margin-top: -0.125rem;
                    @include iconCheck($size: 1.5625rem, $color: '3906C3');
                    vertical-align: middle;
                }
            }
        }
    }
}

.customCheck03 {
    display: inline-block;
    padding: rem(8) rem(14);
    border: $border-gray;
    border-radius: 50px;
    vertical-align: top;

    &.checked {
        border-color: $c-primary;

        span {
            color: $c-primary;
        }
    }

    .icon {
        display: inline-block;
        margin-top: -0.125rem;
        margin-left: rem(-6);
        @include iconCheck($size: 1.5rem, $color: 'A0A4AF');
        vertical-align: middle;
    }

    input[type='radio'],
    input[type='checkbox'] {
        display: none;

        &:checked {
            &+.icon {
                @include iconCheck($size: 1.5rem, $color: '3906C3');
            }
        }
    }
}

[class*='MuiRadio-root'] {
    width: rem(22);
    height: rem(22);
    padding: 0px !important;
    margin-right: 0.5rem !important;
    color: $c-gray-05 !important;

    [class*='MuiSvgIcon-root'] {
        &[data-testid='RadioButtonUncheckedIcon'] {
            transform: scale(1.1) !important;
            -webkit-transform: scale(1.1) !important;
            -moz-transform: scale(1.1) !important;
            -ms-transform: scale(1.1) !important;

            path {
                stroke: #fff;
                stroke-width: 1px !important;
            }
        }
    }

    &.Mui-checked {
        color: $c-0 !important;

        [class*='MuiSvgIcon-root'] {
            &[data-testid='RadioButtonCheckedIcon'] {
                transform: scale(0.9) !important;
                -webkit-transform: scale(0.9) !important;
                -moz-transform: scale(0.9) !important;
                -ms-transform: scale(0.9) !important;
            }
        }
    }

    &.Mui-disabled {
        color: $c-gray-500 !important;

        &>span {
            background-color: $c-gray-200 !important;
            border-radius: 100%;
        }

        [class*='MuiSvgIcon-root'] {
            &[data-testid='RadioButtonCheckedIcon'] {
                transform: scale(0.9) !important;
                -webkit-transform: scale(0.9) !important;
                -moz-transform: scale(0.9) !important;
                -ms-transform: scale(0.9) !important;
            }
        }
    }
}

[class*='MuiCheckbox-root'] {
    padding: 0px !important;
    margin-right: 0.5rem !important;
}

// SelectBox
.customSelect {
    flex-shrink: 0;
    height: 1.5rem;
    line-height: 1.5rem;
    background: none;
    border: none;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    color: #7e7e7e;

    &::after {
        content: '';
        position: relative;
        top: 1px;
        display: inline-block;
        margin-left: 0.25rem;
        @include iconDropDown($size: 12px, $color: '848588');
    }
}

.customSelect02 {
    height: 1.1875rem;
    line-height: 1.1875rem;
    background: none;
    border: none;
    font-size: 1rem;
    color: #7e7e7e;

    &::after {
        content: '';
        position: relative;
        top: 2px;
        display: inline-block;
        margin-left: 0.25rem;
        @include iconDropDown($color: '848588');
    }
}

// 하단 고정 버튼
.btnFixedBottom {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 1rem 0;
    background: #fff;
    z-index: 10;

    &.fixed {
        position: fixed;
    }

    .inner {
        display: flex;

        button {
            flex: 1 1 auto;
            margin-left: rem(8);

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &.btnFullWidth {
        .inner {
            display: block;

            button {
                margin-left: 0;
                margin-top: 0.75rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.btnFixedBottom02 {
    position: fixed;
    display: flex;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
}

.btnGrayLine {
    display: block;
    width: 100%;
    height: rem(36);
    border: 1px solid $c-gray-03;
    font-size: rem(14);
    font-weight: 400;
    text-align: center;
    border-radius: rem(40);
}

.btnAdd {
    @include iconAdd;
}

.moreBtnArea {
    flex-shrink: 0;
    font-size: 0.875rem;
    text-align: center;
    color: $c-gray;
    line-height: 1rem;

    button,
    a {
        display: block;
        width: 100%;
        color: $c-gray;

        //&::after {
        //    @include iconDropDown($size: 1rem, $color: '848588');
        //    display: block !important;
        //    margin: 4px auto 0;
        //}
    }
}

.sideBtnArea {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: 1rem $InnerPadding;

    a,
    button {
        border-bottom: 1px solid $c-gray-500;
        font-size: 0.8125rem;
        line-height: 1.1875rem;
        color: $c-gray;
    }
}

// Link
.linkArea {
    margin-top: 1.875rem;
    text-align: center;

    a {
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 400;
        color: $c-gray;
        line-height: 1rem;
        border-bottom: 1px solid $c-gray-500;
    }
}

// Switch
.MuiFormControlLabel-root {
    margin: 0 !important;
}

.defaultSwitch {
    &.MuiSwitch-root {
        width: 46px;
        height: 24px;
        padding: 0;
    }

    & .MuiSwitch-switchBase {
        padding: 0;
        margin: 0;
        transition-duration: 300ms;

        &.Mui-checked {
            transform: translateX(22px);
            color: #fff;

            &+.MuiSwitch-track {
                background-color: #3906c3;
                opacity: 1;
                border: 0;
            }

            &.Mui-disabled+.MuiSwitch-track {
                opacity: 0.5;
            }

            & .MuiSwitch-thumb {
                border-color: #3906c3;
            }
        }
    }

    & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        border: 1px solid #ebebeb;
        box-shadow: none;
        transition: border-color 500ms;
    }

    & .MuiSwitch-track {
        border-radius: 25px;
        background-color: #e0e0e0;
        opacity: 1;
        transition: background-color 500ms;
    }
}

// 찜하기 / 좋아요 / 관심골프장
.btnLike {
    @include iconLike;
    @include textHidden;

    &.on {
        @include iconLikeOn;
    }
}

// filed-main floating button2
.floating-button-container {
    position: fixed;
    max-width: calc(100% - 3rem);
    padding: 14px;
    color: #fff;
    bottom: 6.25rem;
    right: 1.5rem;
    width: 48px;
    height: 48px;
    border-radius: 30px;
    background-color: #3906c3;
    cursor: pointer;
    z-index: 300;
    transition: all 0.3s ease-in-out;

    i {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        background: url('../../../assets/images/common/icon_floating.png') no-repeat center center;
        background-size: 98%;
        vertical-align: middle;
    }

    .txt {
        max-width: fit-content;
        font-size: 12px;
        margin-left: 0.375rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .floating-content {
        display: flex;
        align-items: center;
        position: relative;
        top: -0.125rem;
        padding-bottom: 0.1875rem;
        font-size: 0.75rem;
        font-weight: 300;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 2px;
            bottom: 0;
            width: calc(100% - 4px);
            height: 1px;
            border-radius: 1px;
            background: $c-f;
            transition: width 0.4s ease-in;
        }
    }

    &.expanded {
        width: 300px;
        border-radius: 30px;
    }

    &.collapsed {
        width: 48px;
        height: 48px;
        border-radius: 30px;
    }
}