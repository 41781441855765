@import '../../assets/styles/scss/help';

.mainContentsWrap {
    .btnAlarm {
        @include iconAlarm($color: 'fff');
    }
}

.mainVisual {
    position: relative;
    .visualArea {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 549px;
        padding: $InnerPadding $InnerPadding 5rem;
        background-size: cover;
        color: #fff;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
        }
        .txtWrap {
            position: relative;
            z-index: 10;
        }
        b {
            display: block;
            font-size: 1.125rem;
            font-weight: 400;
            text-shadow: 2px 2px 4px rgba(33, 33, 33, 0.5);
        }
        strong {
            display: block;
            margin-top: 0.5rem;
            font-size: 1.875rem;
            font-weight: 700;
            text-shadow: 2px 2px 4px rgba(33, 33, 33, 0.5);
        }
        p {
            margin-top: 1rem;
            font-size: 1rem;
            line-height: 1.375rem;
            opacity: 0.7;
            text-shadow: 2px 2px 4px rgba(33, 33, 33, 0.5);
            white-space: pre;
        }
        .location {
            display: block;
            margin-top: 1.25rem;
            font-size: 0.9375rem;
            opacity: 0.7;
            text-shadow: 2px 2px 4px rgba(33, 33, 33, 0.5);
            &::before {
                content: '';
                display: inline-block;
                width: 0.625rem;
                height: 0.8125rem;
                margin-right: 0.375rem;
                margin-top: -0.1875rem;
                background: url("data:image/svg+xml,%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.49997 5.05553V5.0571C9.49997 5.9259 9.19697 6.84773 8.73455 7.74069C8.27424 8.62956 7.67261 9.45801 7.11502 10.1313C6.46394 10.9168 5.75702 11.6529 4.99999 12.3338C4.24295 11.6529 3.53603 10.9168 2.88496 10.1313C2.32737 9.45801 1.72573 8.62956 1.26542 7.74069C0.803002 6.84773 0.5 5.9259 0.5 5.0571L0.5 5.05686C0.499553 4.15448 0.763948 3.27268 1.25911 2.5231C1.75424 1.77357 2.45762 1.19021 3.2796 0.845997C4.10153 0.501806 5.00566 0.411929 5.87782 0.587504C6.75001 0.763086 7.55183 1.19643 8.18149 1.83355L8.18244 1.8345C8.60138 2.25613 8.93373 2.75788 9.16 3.31083C9.38626 3.86379 9.50186 4.45682 9.49997 5.05553Z' stroke='white'/%3E%3Cpath d='M3.93934 3.60731C4.22064 3.326 4.60218 3.16797 5 3.16797C5.39782 3.16797 5.77936 3.326 6.06066 3.60731C6.34196 3.88861 6.5 4.27014 6.5 4.66797C6.5 5.06579 6.34196 5.44733 6.06066 5.72863C5.77936 6.00993 5.39782 6.16797 5 6.16797C4.60218 6.16797 4.22064 6.00993 3.93934 5.72863C3.65804 5.44733 3.5 5.06579 3.5 4.66797C3.5 4.27014 3.65804 3.88861 3.93934 3.60731Z' stroke='white'/%3E%3C/svg%3E%0A")
                    no-repeat center center;
                background-size: 0.625rem;
                vertical-align: middle;
            }
        }
    }
    .swiper-scrollbar {
        left: 0;
        width: 88%;
        height: 1px;
        margin: 0 $InnerPadding;
        bottom: 2.5rem;
        background: rgba(255, 255, 255, 0.1);
        .swiper-scrollbar-drag {
            background: #fff;
        }
    }
    .btnAlarm {
        position: absolute;
        top: 1.25rem;
        right: 0.875rem;
        z-index: 50;
    }
}
.mainContents {
    padding: 1.875rem 0;
    h2 {
        margin-bottom: 1rem;
        font-size: 1.375rem;
        font-weight: 700;
    }
    section {
        padding: 1.875rem $InnerPadding;
        .prdInfo {
            .tit {
                display: block;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.1875rem;
            }
            .location {
                display: block;
                padding-top: 0.25rem;
                font-size: 0.8125rem;
                line-height: 1rem;
            }
            p {
                padding-top: 0.5rem;
                font-size: 0.875rem;
                color: #848588;
                line-height: 1.0625rem;
            }
        }
    }

    .mainListCon {
        padding-left: 0;
        padding-right: 0;
        h2 {
            padding: 0 $InnerPadding;
        }
    }

    .mainEnjoyCon {
        .enjoyLst {
            .list {
                margin-top: 0.75rem;
                &:first-child {
                    margin-top: 0;
                }
                a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .thumbArea {
                        position: relative;
                        width: 5.75rem;
                        height: 5.75rem;
                        margin-right: 1rem;
                        flex-shrink: 0;
                        border-radius: 0.5rem;
                        overflow: hidden;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .prdInfo {
                        max-width: calc(100% - 6.75rem);
                        p {
                            padding-top: 1rem;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    .mainPopularCon {
        position: relative;
        background: #f6f8f5;
        margin-top: 4.6875rem;
        h2 {
            position: absolute;
            top: -2.5rem;
            left: $InnerPadding;
            z-index: 10;
        }
        .popularListArea {
            .list {
                text-align: center;
                a {
                    display: inline-block;
                    max-width: 400px;
                    border-radius: 0.5rem;
                    overflow: hidden;
                }
                .thumbArea {
                    max-width: 100%;
                    img {
                        width: 100%;
                        height: 57.8vw;
                        max-height: 266px;
                        object-fit: cover;
                    }
                }
                .txtArea {
                    padding: 1rem;
                    background: #fff;
                    margin-bottom: 1px;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    border-top: none;
                    border-radius: 0px 0px 0.5rem 0.5rem;
                    .iconBadge {
                        display: inline-block;
                        width: 3.75rem;
                        font-size: 0.75rem;
                        margin-bottom: 0.625rem;
                        font-weight: 700;
                        color: #3906c3;
                        border: 1px solid rgba(33, 33, 33, 0.12);
                        border-radius: 0.625rem;
                        line-height: 1.375rem;
                    }
                    .tit {
                        display: block;
                        font-size: 1.125rem;
                        font-weight: 700;
                    }
                    p {
                        padding-top: 0.5rem;
                        font-size: 0.875rem;
                        color: #848688;
                        line-height: 1.25rem;
                        word-break: keep-all;
                    }
                }
            }
        }
    }
}
