@import '../../assets/styles/scss/help';

#myPage {
    section {
        padding: 0 1.5rem;
        background: #fff;
    }

    .myInfoArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(20) 0 rem(30);

        .myInfo {
            display: flex;
            align-items: center;
            width: 100%;

            .Avatar {
                flex-shrink: 0;
                width: rem(90);

                .thumbArea {
                    max-width: 100%;
                    margin-bottom: 0;
                }

                .name {
                    display: none;
                }
            }

            .infoCon {
                padding-left: rem(16);

                .name {
                    display: block;
                    font-size: rem(26);
                    font-weight: 600;
                    line-height: rem(31);
                }

                .modify {
                    display: block;
                    padding-top: rem(4);
                    font-size: rem(14);
                    line-height: rem(17);
                    color: $c-gray;
                }
            }
        }

        .btnModify {
            display: block;
            height: 2rem;
            line-height: 2rem;
            padding: 0 0.625rem;
            background: #ebebeb;
            border: none;
            border-radius: 0.5rem;
            font-size: 0.875rem;
            color: $c-gray;
        }
    }

    .myQuickMenu {
        position: relative;
        display: flex;
        border-top: 4px solid $c-0;
        border-bottom: 1px solid rgba(215, 217, 226, 0.5);

        .cols {
            flex: 1;
            border-left: 1px solid rgba(215, 217, 226, 0.5);
            padding: rem(16);

            &:first-child {
                border-left: none;
                padding-left: 0;
            }

            .tt {
                display: block;
                font-size: rem(16);
                font-weight: 600;
            }

            dl {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $c-primary;
                margin: -0.125rem 0 -0.375rem;

                dt {
                    @include font-light02;
                }

                dd {
                    font-size: rem(24);
                    line-height: rem(29);
                    font-weight: 800;
                }
            }
        }

        .tooltip-box {
            .tooltip {
                display: flex;
                align-items: center;
                position: absolute;
                top: -1.625rem;
                left: 0;
                background: #FE6215;
                border-radius: 2.625rem;
                padding: 0.5rem 1rem;

                span {
                    color: #fff;
                    font-size: 0.8125rem;
                }

                .arrow {
                    margin-left: 0.438rem;
                }
            }

            .svg {
                position: absolute;
                left: 1.813rem;
                top: 0.125rem;
            }
        }
    }


    .menuListWrap {
        margin-top: rem(16);

        .menuList {
            li {
                line-height: rem(52);

                a,
                .flex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                a {
                    position: relative;
                }

                strong {
                    display: flex;
                    align-items: center;
                    font-size: 1rem;

                    svg {
                        width: 1.25rem;
                        margin-top: -0.125rem;
                        margin-right: 0.625rem;
                    }
                }

                span {
                    font-size: 0.875rem;
                    color: $c-gray;

                    &.surfix {
                        position: absolute;
                        right: 12.75px;
                    }

                    .num {
                        font-weight: 600;
                        color: $c-primary;
                    }
                }
            }
        }
    }

    .num {
        font-weight: 600;
        color: $c-primary;
    }

    .advSection {
        margin-top: rem(24);
    }
}

#myInfoModify {
    .inner {
        padding-top: rem(20);
        padding-bottom: rem(24);
        background: $c-f;
    }
}

.profileArea {
    margin-bottom: rem(25);
    text-align: center;

    .profileImgArea {
        position: relative;
        width: rem(90);
        margin: 0 auto;

        .Avatar {
            width: 100%;

            .thumbArea {
                max-width: 100%;
            }

            .name {
                display: none;
            }
        }

        label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;

            &::after {
                position: absolute;
                right: -6px;
                bottom: 0;
                @include iconPhoto($size: 1.9375rem);
            }
        }

        input[type='file'] {
            display: none;
        }
    }

    .name {
        display: block;
        margin-top: rem(8);
        font-size: rem(18);
        font-weight: 700;
        line-height: rem(21);
    }

    .mail {
        display: block;
        margin-top: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        color: $c-gray;
    }
}

.myInfoDetail {
    border-top: 1px solid $c-0;
    padding-top: rem(24);

    .joinRoute {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        margin-top: -4px;
        margin-right: rem(8);

        svg {
            vertical-align: top;
        }
    }
}

.agreeSection {
    margin-top: rem(24);
    padding-top: rem(24);
    border-top: $border-gray-opacity;

    .ttArea {
        display: flex;
        justify-content: space-between;

        .tt {
            p {
                font-weight: 600;
                line-height: rem(24);
                color: $c-0;
            }

            span {
                color: $c-gray;
                font-size: 0.875rem;
                line-height: 1.063rem;
            }
        }
    }

    .agreeCon {
        padding-top: rem(16);
        @include font-light;
        color: $c-gray;

        p {
            word-break: keep-all;
        }
    }
}

.withdrawalInfo {
    margin-bottom: rem(48);

    .section {
        padding: rem(30) 0 0 !important;

        &:first-child {
            padding-top: 0px !important;
        }
    }

    .tt {
        display: block;
        margin-bottom: 0.75rem;
        font-size: 1.0625rem;
        font-weight: 500;
        line-height: 1.25rem;
    }

    .agreeCheckLabel {
        margin-top: rem(24);
        margin-bottom: 0px !important;
    }
}

.pushList {
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 0;
        border-bottom: 1px solid $c-gray-300;

        .txtArea {
            strong {
                display: block;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.1875rem;
            }

            span {
                display: block;
                margin-top: 0.25rem;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                color: $c-gray;
            }
        }
    }
}

// 회사소개
.companyInfo {
    margin-top: 1.5rem;

    section {
        padding: rem(20) 0;
        border-bottom: $border-gray-opacity;

        &:first-child {
            padding-top: 0;
        }

        h2 {
            margin-bottom: rem(10);
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(19);
        }

        p {
            @include font-light02;
            color: $c-gray;
        }
    }
}

.favoritesHeader {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    font-size: rem(20);

    .pageTitle {
        margin-left: rem(23);
        font-weight: 700;
    }
}

// 즐겨찾는 골프장
.mypageFilterArea {
    height: 100%;
    overflow-y: auto;

    .golfClubList {
        .golfClubBox {
            padding: rem(16) rem(24);

            /*&:first-child {
                padding-top: 0;
            }*/
        }

        .nodata {
            .icon {
                margin-top: rem(-20);
            }
        }
    }
}