@import "../../assets/styles/scss/help";

// 알림 리스트
.alarmList {
    border-top: 1px solid $c-gray-200;

    ul {
        li {
            padding: 0 $InnerPadding;
            a {
                display: block;
                padding: 1.25rem 0;
                border-bottom: 1px solid $c-gray-200;
                .category {
                    display: block;
                    padding-bottom: 0.125rem;
                    font-size: 0.75rem;
                    font-weight: 700;
                    line-height: 0.875rem;
                    color: $c-primary;
                }
                .title {
                    @include font-medium;
                    word-break: keep-all;
                }
                .date {
                    display: block;
                    padding-top: 0.3125rem;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    color: $c-gray;
                }
            }

            &.active {
                background: #f4fcf9;
            }
        }
    }
}


