// 인트로 동영상
.videoPlayerArea {
    position: relative;
    width: 100vw;
    height: 100%;
    overflow: hidden;

    // 세레니티 가로형 인트로 동영상 
    .full-bg-serenity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: #000;
    }

    .auto-screen-serenity {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 100;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
        }

        .videoPlayer {
            position: relative;
            display: inline-block;
            width: auto !important;
            height: 100%;

            video {
                position: relative;
                left: 50%;
                width: 145% !important;
                height: 100%;
                transform: translateX(-50%);
            }
        }
    }
}