@import '../../../assets/styles/scss/help';

#courseGuide {
    &>.inner {
        display: flex;
        flex-direction: column;
        height: 100%;

        .btnArea {
            flex-shrink: 0;
            margin-top: rem(12);
        }
    }
}

.courseInfoCard {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 1.25rem;
    padding: 1rem 1.25rem;
    box-shadow: rgba(0, 0, 0, 0.25) 20px 20px 100px -20px, rgba(0, 0, 0, 0.3) 20px 20px 60px -30px;
    overflow: hidden;
}

.courseCon {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% + 2.5rem);
    height: 100%;
    margin: 0 -1.25rem;
    overflow: hidden;

    .ttArea {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        margin: 0 1.25rem;
        padding-bottom: rem(20);

        .golfClubTitle {
            flex-shrink: 0;
            font-size: rem(22);
            font-weight: 700;
            line-height: rem(26);
        }
    }

    .courseDetail {
        height: 100%;
        padding: 0 1.25rem;
        overflow-y: auto;

        .courseImgArea {
            border-radius: rem(8);
            text-align: center;
            overflow: hidden;

            img {
                width: 100%;
                // max-width: 400px;
            }

            .courseNoImg {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: rem(175);
                background: url('../../../assets/images/smart-round/courseguide_noImg.jpg') no-repeat center center;
                background-size: cover;

                &::after {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.6);
                }

                p {
                    position: relative;
                    font-size: rem(12);
                    font-weight: 400;
                    line-height: rem(18);
                    color: $c-f;
                    z-index: 10;
                }
            }
        }

        .courseInfo {
            position: relative;
            margin-top: rem(24);

            h3 {
                margin-bottom: rem(12);
                font-size: 1rem;
                font-weight: 600;
                line-height: rem(19);
            }

            p {
                color: #888;
                font-size: rem(14);
                line-height: rem(20);
            }

            .detailCourse {
                margin-top: 0.75rem;
                padding: 0.625rem 0.9375rem;
                background: #f3f3f4;
                border-radius: 0.5rem;

                dl {
                    display: flex;
                    margin-top: 0.25rem;
                    font-size: 0.75rem;
                    line-height: 1rem;

                    &:first-child {
                        margin-top: 0;
                    }

                    dt {
                        width: 3.4375rem;
                        font-weight: 600;

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 0.1875rem;
                            height: 0.1875rem;
                            margin-right: 0.5rem;
                            background: #212121;
                            border-radius: 100%;
                            vertical-align: middle;
                        }
                    }
                }
            }

            .locationRef {
                position: relative;
                top: -4px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 100%;
            height: 30px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
            z-index: 10;
        }

        &.afterHide {
            &::after {
                display: none;
            }
        }
    }
}