@import '../../../assets/styles/scss/help';

.fw-regular {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.business-information-wrapper {
    padding: rem(20) rem(24);
    background: #f0f1f4;
    color: $c-0;

    .business-information-about {
        font-size: rem(14);
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .expanded {
            display: block;
        }
    }

    .business-information-container {
        padding-top: rem(12);
    }

    .business-information-info {
        display: flex;
        align-items: flex-start;

        font-size: rem(12);
    }

    .business-information-info+.business-information-info {
        margin-top: rem(4);
    }

    .business-information-label {
        color: $c-gray;
        min-width: rem(73);
        max-width: rem(100);
        margin-right: rem(16);
    }

    .business-information-link {
        margin-top: 0.75rem;
        display: flex;
        padding: 0.25rem 0.5rem;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.25rem;
        border: 1px solid #D7D9E2;

        color: #000;
        font-family: Pretendard;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 135%;
        /* 1.0125rem */
    }
}

.business-information-copyright {
    color: $c-gray;
    font-size: rem(12);
    padding-top: rem(12);
}

.btn-detail {
    cursor: pointer;
    @include iconDropDown02($color: '000');

    &.expanded {
        transform: rotate(180deg);
    }
}