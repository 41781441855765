@import '../../assets/styles/scss/help';

// Food
.foodTabDetailArea {
    margin-top: 2.5rem !important;
    padding: $InnerPadding;
}

.foodInfo {
    .foodInfoCon {
        width: 100%;

        p {
            color: #292929;

            &:first-child {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.375rem;
                opacity: 0.7;
            }
        }

        .foodInfoDetail {
            margin-top: 1rem;

            li {
                margin-top: 0.4375rem;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                color: #292929;
                opacity: 0.5;

                .icon {
                    position: relative;
                    top: 0.0625rem;
                    margin-right: 0.375rem;

                    svg {
                        width: 0.625rem;

                        path {
                            width: 100%;
                        }
                    }

                    &.clock {
                        svg {
                            width: 0.75rem;
                            margin-right: -0.125rem;
                        }
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.foodRcmndArea {
    margin-top: 3rem;

    h2 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }
}

.foodCatArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;

    h3 {
        font-weight: 700;
        font-size: 1.25rem;
    }

    select {
        border: none;
        -webkit-appearance: none;
        color: $c-gray-700;
        font-size: 1rem;
    }
}

.foodTabArea {
    position: sticky;
    top: rem(105);
    left: 0;
    height: 3.75rem;
    background: #fff;
    padding: rem(12) 0;
    z-index: 10;
}

@include mobileSmall {
    .foodTabArea {
        top: 5.875rem;
    }
}

// foodMenuListItem
.listArea {
    padding: 1rem 0;
    border-bottom: $border-gray-opacity;

    &:nth-last-child(2) {
        border-bottom: none;
    }

    .imgWrap {
        flex-shrink: 0;
        width: 4.5rem;
        height: 4.5rem;
        margin-left: 0.75rem;
        border-radius: rem(18);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .ttArea {
        .category {
            display: inline-block;
            vertical-align: top;
            height: 1.25rem;
            line-height: 1.25rem;
            padding: 0px 0.4375rem;
            border: 1px solid $c-gray-300;
            border-radius: 3.125rem;
            font-weight: 400;
            font-size: 0.75rem;
            color: $c-gray;
        }
    }

    p {
        font-size: 1rem;
        line-height: 1.1875rem;

        &.name {
            font-weight: 700;
            .statusIcon {
                display: inline-block;
                margin-top: -2px;
                margin-left: rem(2);
                vertical-align: middle;
            }
        }

        &.desc {
            padding-top: 0.5rem;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            word-break: keep-all;
            color: $c-gray;
        }

        &.price {
            padding-top: 0.625rem;
            font-weight: 700;
            color: $c-primary;
        }

        .subject {
            margin-right: 0.5rem;
        }
    }
}

// ResInfo
.infoArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem 0 1.5rem;
    gap: 0.5rem;
}
.noticeInfo {
    margin: rem(12) 0;
}
