.loading-global {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    background: #000;
    opacity: 70%;
    z-index: 9999;
}