@import '../../assets/styles/scss/help';

// 스마트 라운드
#smartRound {
    height: 100%;
    padding: 0rem !important;
    overflow: hidden;

    #header {
        position: inherit !important;
    }

    .inner {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        height: 100%;

        .btnCheckInWrapper {
            position: absolute;
            margin-top: rem(96);
            width: 100vw;
            z-index: 10;

            button {
                display: flex;
                margin-left: auto;
                margin-right: rem(24);
                align-items: center;
                font-size: rem(13);
                line-height: rem(16);
                color: white;

                i {
                    position: relative;
                    z-index: 1;
                    //margin-top: -0.125rem;
                    margin-right: 0.25rem;

                    &.iconQr {
                        @include iconQr($size: 1.25rem, $color: 'fff');
                    }

                    &.iconGps {
                        @include iconGps($size: 1.25rem, $color: 'fff');
                    }
                }
            }
        }
    }

    .titleArea {
        flex-shrink: 0;
    }

    .tabSelfCheckIn {
        position: absolute;
        display: flex;
        width: 100vw;
        justify-content: space-between;
        margin-top: rem(92);
        padding-left: rem(99);
        padding-right: rem(99);
        z-index: 2;

        button {
            font-weight: 700;
            position: relative;
            @include font-light02;
            color: white;
            padding-bottom: rem(6);
            opacity: 0.7;

            &.on {
                opacity: 1;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: rem(-3);
                    width: 100%;
                    height: 3px;
                    background: #fff;
                }
            }

            margin-bottom: rem(20);
        }
    }

    .smartRoundWrap {
        width: 100%;
        height: 100%;

        margin-top: rem(138);

        .swiper {
            height: 100%;
            min-height: 26.875rem;
            overflow: visible !important;

            .swiper-slide {
                height: initial;
            }
        }

        .swiper-pagination {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
            text-align: center;
            //z-index: 10;

            .swiper-pagination-bullet {
                display: inline-block;
                width: 6px;
                height: 6px;
                margin-left: 0.5rem;
                background: #fff;
                border-radius: 25px;
                transition: all 0.3s ease;

                &:first-child {
                    margin-left: 0;
                }

                &.swiper-pagination-bullet-active {
                    width: 18px;
                    background: #212121;
                }
            }
        }
    }

    .videoArea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        margin-left: calc(-50vw + 50%);
        height: 100vh;
        margin-bottom: calc(+50vh + 50%);
        //border-radius: 1rem;
        overflow: hidden;
        z-index: 0;

        img,
        video,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;
    }

    .smCard {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        padding: rem(30) $InnerPadding rem(66);
        border: 1px solid #ebebeb;
        background: #fff;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        overflow: hidden;
        z-index: 2;

        &:not(.inBg) {
            &:not(.front) {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: rem(66);
                    width: auto;
                    height: 1px;
                    background: rgba(215, 217, 226, 0.5);
                }
            }
        }

        &.inBg {
            border-radius: 0rem;
            background: transparent;
            padding: 0rem 0rem;
        }

        .detailArea {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            z-index: 100;
        }

        .ttArea {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: rem(35);

            h2 {
                display: flex;
                align-items: center;
                font-size: rem(22);
                font-weight: 700;
                line-height: rem(28);

                .btnTooltip {
                    margin-left: 0.375rem;
                }
            }

            .num {
                display: block;
                font-size: rem(16);
                color: #3906c3;
                line-height: 1.0625rem;
            }

            .refreshArea {
                position: relative;
                right: rem(-4);
                display: flex;
                align-items: center;
            }
        }

        &.back {
            padding: 0rem;

            .btnSelfCheckIn {
                margin-top: 0;
                margin-left: auto;
                margin-right: rem(20);
            }
        }

        .bookingAlert {
            position: relative;
            display: flex;
            //justify-content: center;
            align-items: center;
            padding-top: 1rem;
            margin-top: 1rem;
            //max-height: calc(100vh - 36.25rem);

            .AlertTxt {
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.0625rem;
            }

            .btnTooltip {
                margin-left: 0.375rem;
            }

            .defaultTooltip {
                inset: inherit !important;
                transform: inherit !important;
                left: rem(20) !important;
                bottom: 26px !important;
                width: rem(260);
            }
        }

        .selfCheckInArea {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin: 0.25rem 0 1.5rem;

            &.qr {
                margin: 0;
                padding-bottom: 5.125rem;
            }

            .flexCon {
                margin: rem(30) 0 rem(30);
            }

            .roundInfo {
                text-align: center;

                h2 {
                    font-size: 1.375rem;
                    font-weight: 700;
                    line-height: 1.625rem;
                    word-break: keep-all;
                }

                .num {
                    padding-top: rem(8);
                    font-weight: 600;
                    color: $c-primary;
                }

                .location {
                    display: block;
                    font-size: 0.875rem;
                    color: $c-gray;
                    line-height: 1.0625rem;
                }
            }

            .checkInImg {
                margin-top: rem(101.87);
                text-align: center;

                img,
                svg {
                    max-height: rem(70);
                }

                &.qr {
                    display: flex;
                    flex-direction: column;
                    margin-top: rem(24);
                    align-items: center;

                    svg {
                        max-height: (200);
                    }

                    .roundInfo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: rem(10);
                        @include font-light02;
                        color: $c-gray;

                        span {
                            position: relative;
                            display: inline-block;
                            padding-left: 9px;
                            margin-left: 8px;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                width: 1px;
                                height: rem(10);
                                background: $c-gray-400;
                            }

                            &:first-child {
                                padding-left: 0;
                                margin-left: 0;

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                @include mobileSmall {
                    img,
                    svg {
                        max-height: 80px;
                    }
                }

                .qrWrapper {
                    flex: 1;
                    width: rem(200);
                    height: rem(200);
                    padding: rem(30);
                    border-radius: 1rem;
                    border: 1px solid rgba(215, 217, 226, 0.5);
                    overflow: hidden;

                    .imgQr {
                        height: 100%;
                        max-height: 100%;
                    }

                    .disabled {
                        opacity: 0.1;
                    }
                }

                .noQr {
                    display: inline-block;
                    width: rem(200);
                    height: rem(200);
                    background-color: #f8f8f8 !important;
                    border-radius: 8px;
                    vertical-align: top;
                    @include iconNoData($size: rem(140));
                    @include textHidden;
                }
            }

            .qrInfoTxt {
                margin-top: rem(24);
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(19);

                .icon {
                    svg {
                        vertical-align: top;
                    }
                }
            }

            .infoTxt {
                margin-top: rem(15.56);
                text-align: center;
                font-size: rem(14);
                font-weight: 400;
                line-height: rem(20);
                white-space: pre-line;
                opacity: 0.8;
            }
        }

        .roundDetail {
            margin-top: auto;
            padding-top: 0.75rem;

            dl {
                display: flex;
                margin-top: 0.25rem;
                font-size: 0.75rem;
                line-height: 0.875rem;

                &:first-child {
                    margin-top: 0;
                }

                dt {
                    width: 70px;
                    color: $c-gray;
                    font-weight: 400;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 2px;
                        height: 2px;
                        margin-top: -2px;
                        margin-right: 3px;
                        background: $c-gray;
                        border-radius: 100%;
                        vertical-align: middle;
                    }
                }
            }
        }

        &#partner {
            justify-content: space-between;
        }

        .partnerInfoWrap {
            .listCon {
                display: block;

                li {
                    display: flex;
                    align-items: center;
                    margin-left: 0;
                    margin-top: rem(20);

                    &:first-child {
                        margin-top: 0;
                    }

                    .Avatar {
                        width: 3.625rem;
                        height: 3.625rem;
                        margin-right: rem(24);

                        .name {
                            display: none !important;
                            font-size: 0;
                            text-indent: -9999px;
                            line-height: 0;
                        }

                        .number {
                            display: none;
                        }

                        .btnAdd {
                            width: 3.625rem;
                            height: 3.625rem;
                        }
                    }

                    .userInfo {
                        width: calc(100% - 4.625rem);

                        .name {
                            display: block;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.1875rem;
                            @include textEllipsis;

                            .memberName {
                                display: inline-block;
                                font-weight: 600;
                                line-height: rem(19);
                                vertical-align: middle;
                            }

                            .lastFourDigits {
                                color: $c-gray;
                                font-size: 0.875rem;
                            }
                        }

                        .state {
                            margin-top: rem(6);
                            @include font-light02;
                            color: $c-gray;

                            span:not(:first-child) {
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 1px;
                                    height: 0.625rem;
                                    margin: 0 0.375rem;
                                    background: #d3d3d3;
                                }
                            }

                            .complete {
                                color: #3906c3;
                            }
                        }

                        &.invite {
                            .name {
                                font-weight: 600;
                            }
                        }

                        .icon {
                            width: 1.25rem;
                            height: 1.25rem;
                            margin: 0 0.25rem;
                        }
                    }
                }
            }
        }

        .etiquetteWrap {
            display: flex;
            align-items: center;
            height: 100%;

            .etiquetteList {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-self: center;
                height: 100%;
                max-height: 28.125rem;
                margin-top: -0.75rem;

                li {
                    width: 50%;
                    //margin-top: 0.9375rem;
                    text-align: center;

                    &:nth-child(-n + 2) {
                        margin-top: 0;
                    }

                    i[class*='icon'] {
                        display: inline-block;
                        width: 3rem;
                        height: 3rem;
                        border: 1px solid $c-gray-200;
                        border-radius: 100%;
                        background-size: 1.5rem;
                        background-repeat: no-repeat;
                        background-position: center center;
                        vertical-align: top;

                        &.iconEtq01 {
                            background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12.5 7H11V13L16.2 16.2L17 14.9L12.5 12.2V7Z" fill="%23212121"/%3E%3C/svg%3E%0A');
                        }

                        &.iconEtq02 {
                            background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M21 3H3C1.9 3 1 3.9 1 5V13H3V5H21V21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3Z" fill="%2320283A"/%3E%3Cpath d="M13 10C13 7.79 11.21 6 9 6C6.79 6 5 7.79 5 10C5 12.21 6.79 14 9 14C11.21 14 13 12.21 13 10ZM7 10C7 8.9 7.9 8 9 8C10.1 8 11 8.9 11 10C11 11.1 10.1 12 9 12C7.9 12 7 11.1 7 10Z" fill="%2320283A"/%3E%3Cpath d="M15.39 16.56C13.71 15.7 11.53 15 9 15C6.47 15 4.29 15.7 2.61 16.56C1.61 17.07 1 18.1 1 19.22V22H17V19.22C17 18.1 16.39 17.07 15.39 16.56ZM15 20H3C3 19.28 2.9 18.66 3.52 18.34C4.71 17.73 6.63 17 9 17C11.37 17 13.29 17.73 14.48 18.34C15.11 18.66 15 19.29 15 20Z" fill="%2320283A"/%3E%3C/svg%3E%0A');
                        }

                        &.iconEtq03 {
                            background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M22.8692 19.5703L12.8359 12.0453V10.9836C14.7609 10.4119 16.1026 8.45193 15.6709 6.2586C15.3676 4.73027 14.1542 3.4586 12.6259 3.1086C9.96591 2.50194 7.58592 4.52027 7.58592 7.08694H9.91925C9.91925 6.1186 10.7009 5.33694 11.6692 5.33694C12.6376 5.33694 13.4192 6.1186 13.4192 7.08694C13.4192 8.06694 12.6142 8.86027 11.6342 8.83694C11.0042 8.82527 10.5026 9.36194 10.5026 9.99193V12.0453L0.469248 19.5703C-0.429085 20.2469 0.049248 21.6703 1.16925 21.6703H22.1692C23.2892 21.6703 23.7676 20.2469 22.8692 19.5703ZM4.66925 19.3369L11.6692 14.0869L18.6692 19.3369H4.66925Z" fill="%2320283A"/%3E%3C/svg%3E%0A');
                        }

                        &.iconEtq04 {
                            background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1.30667 20.1667H15.6912V21.1058C15.6912 21.6425 15.26 22.0738 14.7233 22.0738H2.27458C1.73792 22.0738 1.30667 21.6425 1.30667 21.1058V20.1667ZM19.9846 22.3804L15.6817 18.0871V18.25H1.30667V16.3333H13.9279L12.0112 14.4167H1.30667C1.30667 11.3117 3.66417 9.46208 6.4625 8.86792L1 3.40542L2.36083 2.05417L21.3358 21.0292L19.9846 22.3804ZM10.0946 12.5L8.17792 10.5833C6.81708 10.6408 4.80458 11.12 3.8175 12.5H10.0946ZM22.39 4.83333H17.5983V1H15.6817V4.83333H10.89L11.1104 6.75H20.2721L19.3137 16.3046L21.0675 18.0583L22.39 4.83333Z" fill="%2320283A"/%3E%3C/svg%3E%0A');
                        }

                        &.iconEtq05 {
                            background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM13 20.01L4 11V4H11V3.99L20 12.99L13 20.01Z" fill="%2320283A"/%3E%3Cpath d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" fill="%2320283A"/%3E%3C/svg%3E%0A');
                        }

                        &.iconEtq06 {
                            background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3H5L2 9L12 21L22 9L19 3ZM9.62 8L11.12 5H12.88L14.38 8H9.62ZM11 10V16.68L5.44 10H11ZM13 10H18.56L13 16.68V10ZM19.26 8H16.61L15.11 5H17.76L19.26 8ZM6.24 5H8.89L7.39 8H4.74L6.24 5Z" fill="%2320283A"/%3E%3C/svg%3E%0A');
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 2.5rem;
                        margin-top: 0.5rem;
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                    }
                }
            }
        }

        &.inBg {
            border: none;

            .ttArea {
                position: absolute;
            }

            .selfCheckInArea {
                display: block;
                width: 100%;
                margin: 0;

                .flexCon {
                    margin-top: rem(46);
                }

                .roundInfo {
                    .num {
                        padding: 0;
                        font-size: rem(16);
                        line-height: rem(19);
                        color: white;
                        margin-bottom: rem(14);
                    }

                    h2 {
                        font-size: rem(32);
                        line-height: rem(38);
                        color: white;
                        margin-bottom: rem(14);
                    }

                    .location {
                        @include font-light02;
                        color: rgba(255, 255, 255, 0.7);
                        margin-bottom: rem(6);
                        //&:first-child {
                        //}
                    }
                }

                .infoTxt {
                    color: #fff;
                }
            }

            .roundDetail {
                border-color: rgba(255, 255, 255, 0.1);

                dl {
                    dt {
                        color: #fff;
                        opacity: 0.6;

                        &::before {
                            background: #fff;
                        }
                    }

                    dd {
                        color: #fff;
                    }
                }
            }
        }
    }

    .courseGuideArea {
        position: absolute;
        height: rem(66);
        bottom: 0;
        left: 0;
        display: flex;

        width: 100%;
        z-index: 5;

        .btnCourseGuide {
            flex: 1;
            @include font-light02;
            font-weight: 700;
            bottom: rem(26);
            color: white;
        }

        &.black {
            button {
                color: black;
            }
        }
    }

    .flipArea {
        position: relative;
        width: 100%;
        height: 100%;
        //min-height: 400px;
        transform: translate3d(0, 0, 0);
        border-radius: 1rem;

        .btnSelfCheckIn {
            position: absolute;
            margin-top: rem(60);
            //transform: rotateY(180deg);
        }

        .card {
            position: relative;
            width: 100%;
            height: 100%;
            transition: 0.4s;
            transform-style: preserve-3d;

            .front,
            .back {
                position: absolute;
                width: 100%;
                height: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                transition: opacity 0.3s ease;
            }

            .back {
                opacity: 0;
                transform: rotateY(180deg);
                border: transparent;
                background: transparent;
            }
        }

        &.qrOn {
            .card {
                transform: rotateY(180deg);

                .front {
                    opacity: 0;
                }

                .back {
                    opacity: 1;
                }
            }
        }
    }

    .cardArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 80%;
        max-width: 200px;
        max-height: 300px;
        border: 1px solid #d3d3d3;
        border-radius: 0.75rem;
        text-align: center;

        .btnAdd {
            margin-bottom: 1.25rem;
        }

        .tt {
            display: block;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1875rem;
        }

        p {
            padding-top: 0.5rem;
            font-size: 0.8125rem;
            color: $c-gray;
            line-height: 1.0625rem;
        }

        &.cardOn {
            border: none;
        }
    }

    .readyCheckInArea {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 0.625rem;
        margin-top: -0.5rem;
        color: #000;

        .title {
            display: block;
            margin-bottom: 0.625rem;
            font-size: 0.875rem;
            text-align: center;
            font-weight: 400;
            color: #000;
            line-height: 1rem;
            opacity: 0.7;
        }

        .profileArea {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .Avatar {
                .name {
                    display: none;
                }
            }

            .txtArea {
                padding-left: 0.5rem;
                text-align: left;

                .name {
                    display: block;
                    margin-top: 0px !important;
                    font-weight: 700;
                    font-size: rem(22);
                    line-height: 1.0625rem;
                }

                span {
                    display: block;
                    padding-top: rem(9);
                    font-size: rem(14);
                    line-height: 1rem;
                }
            }
        }

        .roundInfo {
            padding-left: 0.5rem;
            color: #777f8a;
            @include font-light02;
        }

        .infoWrap {
            margin-top: rem(30);
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            min-height: rem(200);
            border-radius: 1rem;
            background: #ebe6f973;

            .lockerInfo {
                text-align: center;

                .title {
                    margin-bottom: 0.0625rem;
                }

                .infoArea {
                    @include font-light02;
                    padding-bottom: rem(22);

                    .num {
                        margin-top: rem(4);
                        font-size: rem(56);
                        line-height: rem(67);
                        font-weight: 700;
                    }

                    span {
                        position: relative;
                        top: -0.125rem;
                        padding-left: 0.125rem;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: rem(24);
                    right: rem(24);
                    width: auto;
                    height: 1px;
                    background: #ebe6f9;
                }
            }

            .helperInfo {
                .infoArea {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.375rem;
                    font-weight: 400;

                    dl {
                        position: relative;
                        display: flex;
                        font-size: rem(16);

                        dt {
                            color: #3906c3;
                            margin-right: 0.25rem;
                        }

                        &:not(:first-child) {
                            margin-left: 1rem;
                            padding-left: 1rem;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 0;
                                display: inline-block;
                                width: 1px;
                                transform: translateY(-50%);
                                height: calc(100% - 0.5rem);
                                background: #fff;
                                opacity: 0.1;
                            }
                        }

                        dd {
                            //line-height: 1.75rem;

                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 지도보기 플로팅 버튼
.btnMapFloatingWrap {
    position: absolute;
    width: 44px;
    height: 44px;
    right: rem(12);
    bottom: rem(12);
    //transform: 'translateZ(0px)';
    flex-grow: 1;

    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
    }

    #SpeedDialtooltipexample-actions {
        margin-bottom: -44px !important;
    }

    [class*='staticTooltipLabel'] {
        margin-right: rem(8);
        background: none;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        font-size: rem(16);
        line-height: rem(19);
        font-weight: 700;
        color: $c-f;
    }

    [class*='staticTooltip'] {
        margin: rem(6) 0;
    }

    [class*='MuiSpeedDial-root'] {
        bottom: 0;
        right: 0;
    }

    [class*='MuiSpeedDial-fab'] {
        height: 44px;
        width: 44px;
        background: none;

        &:hover {
            background: none;
        }
    }

    [class*='MuiSpeedDialIcon-root'] {
        height: 44px;
        width: 44px !important;
        background: none;
        border-radius: 100%;
    }

    [class*='MuiSpeedDialAction-fab'] {
        width: 44px;
        height: 44px;
        margin: 0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        background: none;

        svg {
            width: 100%;
            height: 100%;
            flex-shrink: 0;
            vertical-align: top;
        }
    }
}

// 셀프체크인 안내 가이드
.selfCheckInGuideLayer {
    text-align: center;

    .layerContents3 {
        padding-bottom: 0 !important;
    }

    h2 {
        margin-bottom: rem(75);
        font-size: rem(24);
        font-weight: 700;
        line-height: rem(28);

        span {
            font-weight: 400;
        }
    }

    h3 {
        margin-bottom: rem(30);
        font-size: rem(20);
        font-weight: 400;
        line-height: rem(25);

        & + img {
            margin-top: 0 !important;
        }
    }

    section {
        padding: rem(80) $InnerPadding;
        overflow: hidden;

        &:first-child {
            padding-top: rem(40);
        }

        &:last-child {
            padding-bottom: rem(40);
        }

        &.bgSection {
            background: #f0f1f4;
        }

        img {
            display: block;
            width: 76%;
            max-width: 230px;
            margin: 0 auto;

            filter: drop-shadow(11px 6px 43px rgba(0, 0, 0, 0.1));

            &.deepShadow {
                filter: drop-shadow(11px 6px 43px rgba(0, 0, 0, 0.3));
            }

            &.flagShadow {
                filter: drop-shadow(11px 6px 43px rgba(0, 0, 0, 0.16));
            }
        }

        .innerSection {
            margin-top: rem(100);

            &:first-of-type {
                margin-top: 0;
            }
        }

        .flagCon {
            position: relative;
            display: inline-block;
            width: 76%;
            max-width: 230px;
            vertical-align: top;

            img {
                width: 100%;
                max-width: 100%;
            }

            .flagBalloon {
                position: absolute;
                right: -26%;
                top: -10%;
                width: 90%;
                filter: none;
            }
        }
    }
}
