@import '../../assets/styles/scss/help';

// Header
.bookingHeaderWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(56);
    background: $c-primary;

    .logo {
        width: 5.625rem;
    }

    .txt {
        margin-left: rem(10);
        font-size: rem(18);
        font-weight: 700;
        line-height: rem(21);
    }

    .btnLocation {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
}

#header {
    &.outdoorDetailHeader {
        background: #fff;

        .headerWrap {
            .pageTitle {
                max-width: calc(100vw - 10rem);
            }

            .rightCon {
                margin-right: -0.9375rem !important;

                button {
                    width: 2.8125rem !important;

                    svg {
                        width: 1.625rem;
                    }
                }
            }
        }
    }
}

@include mobileSmall {
    .bookingHeaderWrap {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}

@keyframes hideTooltip {
    from {
        opacity: 1;
    }

    to {
        top: 100%;
        opacity: 0;
    }
}

// 필터
.filterWrap {
    flex-shrink: 0;
    padding: rem(12) 0 rem(16);

    .filterList {
        height: rem(34);

        ul {
            width: 100%;
            display: flex;
            padding: 0 $InnerPadding;
            overflow-x: auto;
            padding-bottom: 6px;

            li {
                margin-left: 0.5rem;
                white-space: nowrap;

                &:first-child {
                    margin-left: 0;
                }

                button {
                    height: rem(34);
                    padding: 0 rem(14);
                    border: $border-gray;
                    border-radius: 2.5rem;
                    font-size: 0.875rem;
                    color: $c-21;
                    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
                    cursor: pointer;

                    span {
                        display: inline-block;
                        margin-top: -0.25rem;
                        padding-right: 0.875rem;
                        background: url('data:image/svg+xml,%3Csvg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 2.5L4 5.5L1 2.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A')
                            no-repeat right center;
                        // vertical-align: middle;
                    }

                    &.filterRefresh {
                        border-color: $c-primary;
                        color: $c-primary;

                        span {
                            padding-right: 0;
                            margin-top: -0.125rem;
                            background: none;
                        }

                        .btnRefresh {
                            position: relative;
                            margin-top: -0.25rem;
                            margin-right: rem(4);
                            @include iconRefresh02($color: '3906C3');
                            vertical-align: middle;
                        }
                    }

                    &.on {
                        background: $c-primary;
                        color: $c-f;
                        border-color: $c-primary;

                        span {
                            background-image: url('data:image/svg+xml,%3Csvg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 2.5L4 5.5L1 2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
                        }
                    }
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .hashFilterWrap {
        display: flex;
        /* align-items: center;
    justify-content: space-between;*/
        /*margin-top: 0.75rem;*/

        width: 100%;

        .hashInputArea {
            position: relative;
            width: calc(100% - 3rem);
            padding-right: 30px;
            padding-bottom: rem(5);
            margin: rem(8) rem(24) 0;
            border-bottom: 3px solid $c-0;

            .hashInputBox {
                height: 30px;
                line-height: 30px;
                font-weight: 700;
                font-size: rem(16);
                overflow-x: auto;
                width: 100%;

                .hash {
                    position: relative;
                    top: rem(2);
                    margin-right: rem(4);
                }

                .hashFilter-swiper {
                    height: 30px;

                    white-space: nowrap;
                    overflow-x: scroll;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }

                svg {
                    position: absolute;
                    right: rem(24);
                }

                span {
                    text-align: left;
                    margin-right: 6px;
                    cursor: pointer;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 40px;
                height: 30px;
                background: linear-gradient(270deg, #fff 83.82%, rgba(255, 255, 255, 0) 100%);
                z-index: 10;
            }

            .searchBtnArea {
                position: absolute;
                top: 0;
                right: 0;
                height: 30px;
                z-index: 11;
            }
        }

        .hashInputList {
            white-space: nowrap;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .hashTagList {
            margin-top: rem(12);

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .hashTagBox {
                width: 100%;
                padding: 0 $InnerPadding;
                white-space: nowrap;
                overflow-x: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }

                span {
                    margin-left: rem(6);
                    font-weight: 500;
                    font-size: 12px;
                    line-height: rem(14);

                    border-radius: rem(40);

                    &:first-child {
                        margin-left: 0;
                    }

                    button {
                        height: 2.125rem;
                        padding: 6px 10px;
                        border-radius: rem(40);
                        font-size: rem(12);
                        color: $c-0;

                        cursor: pointer;
                        background: $c-gray-200;
                    }
                }
            }
        }
    }
}

.filterInfoArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    /*border-top: 1px solid $c-gray-200;*/
    padding: 0 $InnerPadding;
    // background: $c-f;
    background: rgba(215, 217, 226, 0.38);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03) inset;
    font-size: 0.8125rem;
    line-height: 1rem;
    height: rem(43);

    .tt {
        font-weight: 400;
        font-size: 14px;

        .num {
            color: $c-primary;
        }
    }

    .leftContent {
        flex-shrink: 0;
        /*margin-left: -20px;*/
    }

    .rightContent {
        flex-shrink: 0;
        justify-content: flex-end;
        cursor: pointer;

        .sortIcon {
            svg {
                vertical-align: top;
            }
        }
    }

    .filterBtnArea {
        display: flex;
        align-items: center;

        .btnLocation {
            display: flex;
            align-items: center;
            font-size: rem(13);

            svg {
                margin-right: 0.1875rem;
            }
        }

        .btnListStyle {
            font-size: 0;
            line-height: 0;
            margin-left: 0.75rem;
        }
    }
}

// 필드골프 메인
#fieldMain,
#searchResult {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px !important;
    padding-top: 0px !important;

    .golfClubList {
        overflow-y: auto;
        /*padding: 0 rem(24) !important;*/

        ul {
            .golfClubList-nodataList {
                padding: 20vh 0;
            }
        }

        .nodata {
            font-size: rem(18);
        }
    }
}

// 골프장 리스트
.golfClubList {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*padding: 0 $InnerPadding;*/

    .golfCLubList-noticeLabel {
        display: flex;
        width: 100%;

        .golfCLubList-noticeLabelSpan {
            width: calc(100% - 3rem);
            background: rgba(240, 241, 244, 0.6);
            border-radius: 8px;
            margin: 0 auto;
            padding: rem(14);

            i {
                display: inline-flex;
                font-weight: 600;
                font-size: rem(12);
                align-items: center;
                color: $c-red;

                svg {
                    margin-right: rem(2);
                }
            }

            .golf-club-list-notice-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .golf-club-list-notice-text {
                margin-top: rem(7);
                color: #777f8a;
                font-size: rem(12);
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    ul {
        position: relative;
        height: fit-content;
        &.loading {
            height: 100%;
        }
    }

    .golfClubBox {
        display: flex;
        justify-content: space-between;
        padding: 1rem 1.5rem;
        /*border-bottom: $border-gray-opacity;*/

        .infoArea {
            width: 100%;
            cursor: pointer;

            .place {
                display: flex;
                font-size: rem(16);
                line-height: rem(19);
                font-weight: 700;

                .statusIcon {
                    margin-left: rem(4);
                }
            }

            .location {
                display: block;
                padding-top: rem(3);
                @include font-light02;
                color: $c-gray;
            }

            .price {
                display: block;
                padding-top: rem(8);
                font-size: rem(16);
                min-height: rem(27);
                line-height: rem(19);
                color: $c-primary;
                font-weight: 700;
            }
        }

        .sideArea {
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            margin-left: 0.5rem;

            .btnInterest {
                svg {
                    margin-left: rem(4);
                    width: rem(18);
                    height: rem(18);
                }
            }

            .btnTeaTime {
                display: flex;
                align-items: center;
            }

            .count,
            p {
                margin-right: rem(4);
                font-size: rem(16);
                line-height: rem(19);
                font-weight: 700;
                color: $c-primary;
            }
        }
    }

    .teeTimeBox {
        padding: 0 1.5rem;

        overflow-x: scroll;
        white-space: nowrap;

        .teeTimeBoxSpan-select {
            background: rgba(235, 230, 249, 0.4) !important;
            border: 1px solid $c-primary !important;
        }

        .teeTimeBoxSpan {
            margin-right: rem(8);
            margin-bottom: rem(16);
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 14px;
            /*gap: 12px;*/

            width: 110px;
            height: 90px;

            background: rgba(240, 241, 244, 0.4);
            border: 1px solid rgba(215, 217, 226, 0.5);
            border-radius: 8px;

            b {
                font-weight: 500;
                font-size: $fz-mid;
            }

            strong {
                margin-top: rem(4);
                font-size: $fz-mid;
                font-weight: 500;
                color: $c-primary;
                /*margin-bottom: rem(12);*/
            }

            i {
                margin-top: rem(12);

                display: inline-flex;

                font-weight: 600;
                font-size: 10px;
                align-items: center;

                svg {
                    margin-right: rem(2);
                }
            }

            .teeTimeBoxIcon-golf-course {
                color: $c-red;
            }

            .teeTimeBoxIcon-advance-payment {
                color: #000000;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .teeTimeLoadingBox {
        position: relative !important;
        width: 100%;
        display: inline-flex !important;
        justify-content: center !important;
        /*padding: 1rem 1.5rem;*/
    }

    .golfListGap {
        display: block;
        height: rem(8);
        background: $c-gray-200;
    }

    .nodata {
        padding: 0 $InnerPadding;
    }
}

// 골프장 지도
.golClubMapSection {
    position: relative;
    height: 100%;

    .mapArea {
        width: 100%;
        height: 100%;
    }

    .golfClubList {
        overflow: hidden !important;
    }

    .golfClubSliderWrap {
        position: absolute;
        left: 0;
        bottom: 1rem;
        width: 100%;
        z-index: 10;

        .swiper-slide {
            width: calc(100% - 3rem) !important;
        }

        .golfClubBox {
            border: none;
            background: $c-f;
            border-radius: 0.5rem;
            overflow: hidden;
        }
    }
}

// 골프장 소개
#golfZoneDetail {
    padding-bottom: 0px !important;
    margin-top: rem(4);

    .fieldQuickInfo {
        .ttArea {
            margin-top: 0.875rem;

            & > .statusIcon {
                margin-bottom: rem(6);
            }

            .tt {
                display: block;
                font-size: 1.375rem;
                line-height: 1.625rem;
            }

            .description {
                padding-top: rem(3);
                font-size: rem(16);
                line-height: rem(19);
            }

            .location {
                display: block;
                padding-top: rem(10);
                font-size: rem(12);
                line-height: rem(14);
                color: $c-gray;
            }
        }

        .fieldVisualSlider {
            .visualArea {
                position: relative;
                height: 200px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                border-radius: 0;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    opacity: 0;
                }

                &.noImg {
                    &::after {
                        display: none;
                    }
                }
            }

            .swiper-scrollbar {
                left: 0;
                width: 88%;
                height: 1px;
                margin: 0 $InnerPadding;
                bottom: 1.25rem;
                background: rgba(255, 255, 255, 0.1);

                .swiper-scrollbar-drag {
                    background: #fff;
                }
            }

            .skeleton {
                &.visualSlide {
                    height: 200px;
                }
            }
        }

        .section {
            padding-bottom: rem(10);
        }

        .hashList {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;

            span {
                display: flex;
                align-items: center;
                height: rem(30);
                padding: 0 rem(15);
                margin-right: rem(4);
                margin-top: rem(4);
                background: rgba(235, 230, 249, 0.5);
                font-size: rem(12);
                line-height: rem(14);
                color: $c-gray-02;
                border-radius: 1.25rem;
            }
        }

        .btnArea {
            display: flex;
            margin-top: rem(20);

            button {
                display: flex;
                flex: 1 1 auto;
                align-items: center;
                justify-content: center;
                height: 2.375rem;
                border: $border-gray;
                margin-left: rem(6);
                font-size: rem(14);
                font-weight: 600;
                border-radius: rem(4);

                &:first-child {
                    margin-left: 0;
                }

                svg {
                    margin-right: 0.25rem;
                    vertical-align: top;
                }
            }
        }

        .courseGuideBtn {
            margin-top: rem(30);
        }
    }

    .titleArea03 {
        & + .infoType05 {
            margin-top: rem(30);
        }
    }

    .loadingArea {
        padding: 10vh 0;
    }

    #foodOrder {
        #foodCategory {
            .noDataList {
                /* padding: rem(70) 0;*/
            }
        }
    }
}

// 주변 인근 골프장
.golfClubSlider {
    margin-top: 0.875rem;

    .swiper-slide {
        width: 9.5rem;

        .nearbyInfoArea {
            margin-bottom: rem(6);
        }

        &:first-child {
            margin-left: $InnerPadding;
        }

        &:last-child {
            margin-right: $InnerPadding;
        }

        a {
            display: block;
        }

        .thumbArea {
            position: relative;
            width: 100%;
            padding-bottom: 100%;

            img,
            .noImgLogo {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .prdInfo {
            margin-top: 0.625rem;

            .tit {
                display: block;
                font-weight: 700;
                @include font-light02;
                @include textEllipsis;
            }

            .location {
                display: block;
                padding-top: 0.1875rem;
                font-size: 0.75rem;
                line-height: 0.875rem;
                color: $c-gray;
                @include textEllipsis;
            }
        }
    }
}

// 티타임
#golfClubTeaTime {
    display: flex;
    flex-direction: column;
}

.teaTimeFilterArea {
    z-index: 98;
    position: sticky;
    background-color: white;
    padding: rem(10) 0;
    top: 11.23rem;

    &.teaTimeFilterAreaDrawer {
        top: rem(0);
    }

    .teaTimeFilter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $InnerPadding;
        @include font-light02;

        .num {
            color: $c-gray;

            &.on {
                color: $c-primary;
            }
        }

        .anchorList {
            display: flex;
            align-items: center;
            background-color: #f0f1f4;
            border-radius: rem(30);

            li {
                color: $c-gray;
                padding: rem(8) rem(11);
                font-size: rem(14);
                font-weight: 400;

                &.active {
                    color: $c-primary;
                    font-weight: 600;
                    border: 1px solid $c-primary;
                    border-radius: rem(30);
                    background-color: white;
                    padding: rem(8) rem(11);
                }
            }
        }
    }
}

.teaTimeWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .teaTimeListWrap {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0.25rem $InnerPadding 1.25rem;
        overflow-y: auto;

        .teaTimeList {
            padding-top: rem(22);

            &:first-child {
                padding-top: rem(8);
            }

            .tt {
                display: block;
                padding-bottom: 0.375rem;
                border-bottom: rem(1) solid black;
                font-size: rem(16);
                font-weight: 700;
                color: black;
            }

            ul {
                li {
                    padding: 1rem 0;
                    border-bottom: 1px solid rgba(215, 217, 226, 0.5);
                    cursor: pointer;

                    .teaTimeDetail {
                        display: flex;
                        /*align-items: center;*/
                        justify-content: space-between;

                        .time {
                            display: block;
                            font-size: 1rem;
                            line-height: 1.1875rem;
                            font-weight: 700;
                        }

                        .courseInfo {
                            display: block;
                            padding-top: rem(4);
                            @include font-light02;
                            color: $c-gray;
                        }

                        .price {
                            font-size: rem(16);
                            font-weight: 700;
                            line-height: rem(19);
                            color: $c-primary;
                        }

                        .isHomepageOpen {
                            float: right;
                            font-weight: 600;
                            font-size: 10px;
                            padding-top: rem(6);
                            display: inline-flex;
                            align-items: center;

                            i {
                                display: inline-flex;
                                align-items: center;

                                svg {
                                    margin-right: rem(2);
                                }
                            }
                        }
                    }

                    .condition {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: rem(12);

                        span {
                            margin-right: rem(4);
                            margin-top: rem(4);
                            padding: rem(2) rem(4);
                            border: 1px solid $c-gray-05;
                            font-size: rem(10);
                            font-weight: 600;
                            line-height: 0.875rem;
                            color: $c-gray;
                            border-radius: rem(2);

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .advSection {
            //width: calc(100% + 3rem);
            //margin: -0.25rem -1.5rem 0;
        }

        .nodata {
            @include posCenter;
        }

        .noPartner {
            position: unset !important;
            transform: translate(0, 0) !important;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: rem(50) 0 rem(30);

            .clubNameTitleNotice {
                font-size: rem(18);
                font-weight: 400;
                color: $c-gray-900;
                line-height: rem(22);
            }

            .partnerApplyBtn {
                border-radius: rem(8);
                color: black;
                background-color: white !important;
                border: $border-gray;
                width: rem(100);
                height: rem(38);
                font-size: rem(14);
                font-weight: 400;
                align-items: center;
                justify-content: center;
                display: flex;
                margin-top: rem(16);
            }

            .isAlreadyBtn {
                color: white;
                background-color: $c-primary;
                width: rem(100);

                &:disabled {
                    background-color: $c-primary !important;
                }
            }

            .subTitleNotice {
                margin-top: rem(6);
                color: #666666;
                font-weight: 400;
                font-size: rem(14);
            }
        }
    }
}

// Filter Drawer (공통 레이아웃은 _modal.scss)
.basicDrawer {
    .drawerContents {
        overflow-y: auto;

        .filterWrap {
            padding: 0;
            margin-top: rem(30);

            &:first-child {
                margin-top: 0;
            }

            .title {
                font-size: rem(16);
                font-weight: 600;
                color: #212121;
                margin-bottom: 0.125rem;
            }

            .btnWrap {
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                width: calc(100% + 1rem);

                li {
                    margin-top: rem(8);
                    margin-right: rem(8);
                }

                .btnList {
                    padding: 0.656rem 0.938rem;
                    color: #848588;
                    font-size: 0.875rem;
                    border: $border-gray-opacity;
                    border-radius: 2.5rem;

                    &.on {
                        color: #3906c3;
                        font-weight: 500;
                        border: 1px solid #3906c3;
                    }
                }
            }
        }
    }
}

// Location Drawer
.locationDrawer {
    overflow: hidden;

    [class*='MuiDrawer-paper'] {
        border-radius: 1rem 1rem 0px 0px;
    }

    // 내부 레이아웃 (타이틀 / 버튼 / 그라데이션)
    .drawer-content {
        $drawerHeight: calc(100vh - 3.75rem);

        display: flex;
        flex-direction: column;
        min-height: $drawerHeight;
        max-height: $drawerHeight;
        overflow: hidden;

        .drawerTitle {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: rem(30) $InnerPadding rem(10);
            margin-bottom: 0;

            .tt {
                font-size: rem(18);
                font-weight: 700;
                line-height: rem(19);
            }

            &.scroll {
                border-bottom: 1px solid #ebebeb;
            }

            .btnClose {
                width: rem(24);
                height: rem(24);
            }
        }

        .drawerContents {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-left: 0;
            width: 100%;
            flex: 1;
            overflow: hidden;

            & > .inner {
                flex-shrink: 0;
            }

            .locationSetting {
                border-bottom: rem(1) solid black;
                margin-top: rem(20);

                .locationIcon {
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(25);
                    padding-left: 0.375rem;
                    font-size: 0.875rem;
                    line-height: 1.063rem;

                    &:last-child {
                        margin-bottom: 1.469rem;
                    }

                    svg {
                        margin-right: 0.563rem;
                    }

                    &.addClose {
                        justify-content: space-between;
                    }

                    div {
                        display: flex;
                        align-items: center;
                    }
                }

                .isNotSelected {
                    color: #c4c4c4;
                }
            }

            .contentsArea {
                display: flex;
                flex-direction: column;
                overflow: hidden;

                .contentsTitle {
                    flex-shrink: 0;
                    margin-top: rem(25);
                    margin-bottom: rem(30);

                    .inner {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .title {
                        font-weight: 600;
                        font-size: rem(16);
                    }

                    .reset {
                        font-size: 0.875rem;
                        color: #fe6215;
                    }
                }

                .filterScrollArea {
                    overflow-y: auto;

                    // 검색 가이드
                    .searchGuide {
                        padding-bottom: rem(20);

                        .tit {
                            font-size: rem(14);
                            line-height: rem(17);
                            margin-bottom: rem(6);
                        }

                        .ex {
                            color: $c-gray;
                            font-size: rem(12);
                            line-height: rem(14);
                        }
                    }
                }

                // 검색 결과
                .searchResult {
                    padding-top: rem(25);

                    .resultList {
                        margin-bottom: rem(20);

                        .txt {
                            margin-bottom: rem(20);

                            strong {
                                display: block;
                                font-size: rem(14);
                                line-height: rem(17);
                                font-weight: 500;
                                margin-bottom: rem(6);
                            }

                            span {
                                font-size: rem(12);
                                line-height: rem(14);
                                color: $c-gray;
                            }
                        }

                        .btn {
                            display: flex;
                            border: $border-gray;
                            border-radius: 4px;

                            button {
                                flex: 1;
                                font-size: rem(12);
                                line-height: rem(34);
                                text-align: center;
                                border-right: $border-gray;

                                &:last-child {
                                    border: none;
                                }

                                .active-btn {
                                    font-weight: 600;
                                    color: #3906c3;
                                }
                            }
                        }
                    }
                }

                // 최근 검색 결과
                .recentSearch {
                    .searchList {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1.375rem;

                        .txt {
                            strong {
                                display: block;
                                font-size: 1rem;
                                line-height: 1.188rem;
                                font-weight: 400;
                                color: #212121;
                                margin-bottom: 0.5rem;
                            }

                            span {
                                font-size: 0.875rem;
                                line-height: 1.063rem;
                                color: #848588;
                            }
                        }
                    }

                    .searchGuide {
                        padding-bottom: 22px;

                        .tit {
                            color: #212121;
                            font-size: 16px;
                            line-height: 19px;
                            margin-bottom: 8px;
                        }

                        .ex {
                            color: #848588;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }
        }
    }
}

// TeaTime Drawer
.teaTimeDrawer {
    overflow: hidden;

    [class*='MuiDrawer-paper'] {
        border-radius: 1rem 1rem 0px 0px;
    }

    // 내부 레이아웃 (타이틀 / 버튼 / 그라데이션)
    .drawer-content {
        $drawerHeight: calc(100vh - 3.75rem);

        display: flex;
        flex-direction: column;
        min-height: $drawerHeight;
        max-height: $drawerHeight;
        overflow: hidden;

        .drawerTitle {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: rem(30) rem(24) rem(10);
            margin-bottom: 0;

            .ttArea {
                display: flex;
                cursor: pointer;
                padding-right: rem(10);
                align-items: center;

                .tt {
                    display: block;
                    font-size: rem(18);
                    font-weight: 700;
                    color: #212121;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .date {
                    flex-shrink: 0;
                    position: relative;
                    margin-left: rem(7);
                    font-size: rem(14);
                    font-weight: 400;
                    color: $c-gray;
                    display: flex;
                    align-items: flex-end;
                }
            }

            .btnClose {
                position: relative;
                width: rem(24);
                height: rem(24);
            }
        }

        .drawerContents {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            .teaTimeWrap {
                /*flex: 1;*/

                .teaTimeListWrap {
                    /*flex: 1;*/
                    justify-content: space-between;

                    .teaTimeNoDataArea {
                        flex: 1;
                        padding: 0;
                        justify-content: center;
                        margin-top: rem(-35);
                    }

                    .noPartner {
                        flex: 1;
                        height: 100%;
                        padding: rem(40) 0;

                        .subTitleNotice {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.teaTimeReservationDrawer {
    overflow: hidden;

    [class*='MuiDrawer-paper'] {
        border-radius: 1rem 1rem 0px 0px;
    }

    // 내부 레이아웃 (타이틀 / 버튼 / 그라데이션)
    .drawer-content {
        $drawerHeight: calc(100vh - 3.75rem);

        display: flex;
        flex-direction: column;
        max-height: $drawerHeight;
        overflow: hidden;

        .drawerTitle {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: rem(30) rem(24) rem(24);
            margin-bottom: 0;

            .ttArea {
                display: flex;
                cursor: pointer;
                padding-right: rem(10);
                -webkit-tap-highlight-color: transparent !important;
                align-items: center;

                .tt {
                    display: block;
                    font-size: rem(18);
                    font-weight: 700;
                    color: #212121;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    line-height: rem(21.48);
                }

                .date {
                    flex-shrink: 0;
                    position: relative;
                    margin-left: rem(7);
                    font-size: rem(14);
                    font-weight: 400;
                    color: $c-gray;
                    display: flex;
                    align-items: flex-end;
                }
            }

            .btnClose {
                position: relative;
                width: rem(24);
                height: rem(24);
            }
        }

        .drawerDate {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: rem(0) rem(24) rem(14);
            /*margin-bottom: 0;*/

            dl {
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 100%;
                padding: 0 0 rem(14);
                border-bottom: 1px solid rgba(215, 217, 226, 0.5);

                dt {
                    span {
                        color: #3906c3;
                    }
                }

                dd {
                    b {
                        font-weight: 400;
                        font-size: rem(14);
                    }
                }
            }
        }

        .drawerPrice {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: rem(0) rem(0) rem(20);

            span {
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 100%;
                padding: 0 rem(24);

                color: $c-primary;
                font-size: rem(20);
                font-weight: 600;
            }
        }

        .drawerTagList {
            width: 100%;
            /*height: rem(72);*/

            // background: rgba(235, 230, 249, 0.5);
            // padding: rem(24) rem(0) rem(24) rem(24);
            /* 가로 스크롤 */
            // overflow: auto;
            // white-space: nowrap;
            padding: rem(0) rem(24) rem(0) rem(24);

            &::-webkit-scrollbar {
                display: none;
            }

            span {
                margin-right: rem(6);
                // margin-bottom: rem(6);
                align-items: center;
                padding: rem(5) rem(10);

                // background: rgba(176, 155, 231, 0.3);
                background: rgba(176, 155, 231, 0.1);

                border-radius: rem(4);

                font-size: rem(12);

                color: $c-primary;
                white-space: nowrap;
                line-height: rem(30);
                height: rem(24);
            }
        }

        .drawerBtnArea {
            flex-shrink: 0;

            // padding: 1.25rem 1.5rem;
            padding: rem(30) rem(24) rem(20) rem(24);
        }

        .drawerContents {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            .teaTimeWrap {
                /*flex: 1;*/

                .teaTimeListWrap {
                    /*flex: 1;*/
                    justify-content: space-between;

                    /* .teaTimeNoDataArea {
                        flex: 1;
                        padding: 0;
                        justify-content: center;
                        margin-top: rem(-35);
                    }

                    .noPartner {
                        flex: 1;
                        height: 100%;
                        padding: rem(40) 0;

                        .subTitleNotice {
                            margin-bottom: 0;
                        }
                    }*/
                }
            }
        }

        // 모달 예약하기
        .drawer-booking-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-shrink: 0;
            padding: rem(0) rem(24) rem(14);
            font-weight: 400;

            &::after {
                content: '';
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 100%;
                padding: 0 0 rem(14);
                border-bottom: 1px solid rgba(215, 217, 226, 0.5);
            }

            .drawer-booking-datetime {
                font-size: rem(16);
                line-height: rem(19.09);
            }

            .drawer-booking-content {
                display: flex;
                margin-top: rem(6);
                width: 100%;
                justify-content: space-between;

                .drawer-booking-course-info {
                    font-size: rem(14);
                    line-height: rem(16.71);
                    padding-top: rem(2);
                }

                .drawer-booking-amount {
                    color: $c-primary;
                    font-size: rem(16);
                    font-weight: 700;
                    line-height: rem(19.09);
                }
            }
        }

        .drawer-tag-title {
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(16.71);
            margin-bottom: rem(10);
        }
    }
}

.teaTimeNoDataArea {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: rem(74) 0 rem(74) !important;
    /*padding: 0;
    margin-top: rem(74);*/

    .teaTimeNoDataImg {
        width: rem(60);
        height: rem(60);
        align-items: center;
        justify-content: center;
        margin-bottom: rem(10);
    }

    .teaTimeNoData {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fe6215;
        font-size: rem(14);
        font-weight: 400;
    }
}

// Calendar Drawer
.calendarDrawer {
    .mobile-datepicker {
        margin-top: -0.75rem;
    }
}

// Main - Get MyReservation Drawer
.mainAdDrawer {
    [class*='MuiDrawer-paper'] {
        flex-direction: column-reverse;
        background: none;
        box-shadow: none;
    }

    .drawerContents {
        border-radius: 1rem 1rem 0 0;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .btnArea {
        display: flex;
        justify-content: space-between;
        margin: 0 rem(24) rem(10);

        button {
            font-size: rem(14);
            margin-right: rem(5);
            color: #fff;
        }
    }
}

// 텍스트까지 퍼블리싱할 경우
// .mainDrawer {
//     position: relative;
//     padding-top: rem(44);
//     height: 360px;

//     .drawer-content {
//         height: rem(260);
//         max-height: calc(100vh - 3.75rem);
//         background: #ebecf1;

//         .drawer-wrapper {
//             height: rem(300);

//             .btnArea {
//                 border: 1px solid red;
//                 position: absolute;
//                 top: -10px;
//                 display: flex;
//                 justify-content: space-between;
//                 width: 100%;
//                 padding: 0 rem(24);

//                 .btn {
//                     font-size: rem(14);
//                     line-height: rem(17);
//                     color: #fff;

//                     .icon {
//                         width: 10px;
//                         height: 10px;
//                     }

//                     .close {
//                         color: #fff;
//                         margin-right: rem(5);
//                     }
//                 }
//             }

//             .innerArea {
//                 .txt-wrapper {
//                     position: absolute;
//                     left: rem(24);
//                     bottom: rem(73);

//                     .tit-exp {
//                         font-size: rem(18);
//                         line-height: 132%;
//                     }

//                     .tit {
//                         padding: rem(9) 0 rem(16);
//                         font-size: rem(28);
//                         font-weight: 700;
//                         line-height: 132%;
//                     }

//                     .btn {
//                         font-size: rem(14);
//                         line-height: rem(17);
//                         color: #3906C3;
//                     }
//                 }

//                 .img-wrapper {
//                     position: absolute;
//                     right: 0;
//                     bottom: 0;

//                     img {
//                         height: rem(243);
//                         max-height: 248px;

//                     }
//                 }

//             }
//         }
//     }
// }

// 광고 영역
.advertiseImage {
    margin: rem(10) $InnerPadding;

    img {
        width: 100%;
    }

    & + div {
        .golfZoneList {
            .golfZoneListItem {
                &:first-child {
                    padding-top: rem(10) !important;
                }
            }
        }
    }
}

.gap_thin {
    display: block;
    height: rem(1);
    background: rgba(215, 217, 226, 0.5);
    margin: rem(20) 0;
}

.detailNotieSection {
    background-color: #f0f1f4;
}

.dateTimePickerArea {
    position: sticky;
    margin-bottom: rem(6);
    z-index: 98;
    top: 6.56rem;
}

// 공유하기 Drawer
.shareDrawer {
    .drawer-content {
        display: flex;

        .drawerContents {
            &.and {
                align-self: center;
                margin: 0 0 0 0;
                width: rem(280);
            }

            display: flex;
            justify-content: space-between;
            padding: rem(0) rem(43) rem(24) rem(43);

            button {
                img {
                    width: rem(35);
                    height: rem(35);
                    margin-bottom: rem(10);
                }

                p {
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 600;
                }
            }
        }
    }
}
